import ApplicationController from './application_controller'
import * as Ladda from 'ladda';
import Rails from '@rails/ujs';

export default class extends ApplicationController {

  static targets = [ 'acceptPaymentButton' ]

  connect () {
    super.connect()
    this.stripe = Stripe(this.data.get('stripe-key'));
    this.workspaceId = this.data.get('workspace-id')
  }

  confirmPayment(event) {
    Ladda.create(this.acceptPaymentButtonTarget).start()
    this.stripe.confirmCardPayment(
      event.detail[0].client_secret,
      {
        payment_method: event.detail[0].payment_method_id
      }
    ).then(
      (response) => { this.handleConfirmPaymentResponse(response) }
    )
  }

  handleConfirmPaymentResponse(response) {
    Ladda.create(this.acceptPaymentButtonTarget).stop()
    if (response.error) {
      this.showError(response.error.message)
    } else {
      var workspaceId = this.workspaceId
      Rails.ajax({
        url: `/workspaces/${this.workspaceId}/billing/confirm`,
        type: 'put',
        success: () => {
          this.stimulate('Subscription#refresh')
        }
      })
    }
  }

  afterCancel() {
    this.stimulate('Subscription#refresh')
    this.hideModal('#subscription-modal')
  }

  showError(error) {
    this.stimulate('Subscription#stripe_error', error)
  }
}
