import I18n from 'i18n-js';
I18n.translations || (I18n.translations = {});
I18n.translations["bg"] = I18n.extend((I18n.translations["bg"] || {}), {
  "activerecord": {
    "attributes": {
      "room_member": {
        "entry_code": "Password",
        "name": "Name"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    },
    "models": {
      "user": {
        "one": "User",
        "other": "Users"
      }
    }
  },
  "common": {
    "are_you_sure": "Are you sure?",
    "cancel": "Cancel",
    "nothing_selected": "Nothing selected",
    "reset": "Reset password for",
    "submit": "Submit"
  },
  "controllers": {
    "admin": {
      "settings": {
        "create": {
          "notice": "Setting was successfully updated."
        }
      },
      "workspaces": {
        "update": {
          "saved": "Saved"
        }
      }
    },
    "workspaces": {
      "update": {
        "saved": "Settings saved."
      }
    }
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Second",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": "Not saved",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007fdecd8ecbd8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/rails-i18n-6.0.0/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    }
  },
  "javascript": {
    "controllers": {
      "subscriptions": {
        "calls_per_month": "%{count} calls",
        "subscribe": "Subscribe: %{price} / month"
      }
    }
  },
  "layouts": {
    "application": {
      "_app_header_right": {
        "change_password": "Change password",
        "profile": "Profile"
      }
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "#<Proc:0x00007fdecd8652c8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:26 (lambda)>",
      "ordinals": "#<Proc:0x00007fdecd8652f0@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:7 (lambda)>"
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "recaptcha": {
    "errors": {
      "recaptcha_unreachable": "Oops, we failed to validate your reCAPTCHA response. Please try again.",
      "verification_failed": "reCAPTCHA verification failed, please try again."
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "views": {
    "admin": {
      "settings": {
        "show": {
          "details": "Details",
          "fields": {
            "billing_section_on": {
              "label": "Billing section on"
            },
            "billings_off": {
              "label": "Billings off"
            },
            "bottom_back_button_on": "Show back button on bottom",
            "bottom_back_button_text": "Text",
            "bottom_back_button_url": "URL",
            "brand": {
              "label": "Brand name"
            },
            "brand_on": {
              "label": "Brand on"
            },
            "call_price": {
              "label": "Call price"
            },
            "calls_max_quantity": {
              "label": "Max calls quantity"
            },
            "calls_min_quantity": {
              "label": "Min calls quantity"
            },
            "free_calls_limit": {
              "label": "Free calls limit"
            },
            "intercom_app_id": {
              "label": "App ID"
            },
            "invite_button_on": {
              "label": "Invitation button on"
            },
            "login_on": {
              "label": "Login on"
            },
            "ocl_api_key": {
              "label": "API key"
            },
            "privacy_policy_url": {
              "label": "Privacy policy URL"
            },
            "registration_on": {
              "label": "Registration on"
            },
            "stripe_publishable_key": {
              "label": "Publishable key"
            },
            "stripe_secret_key": {
              "label": "Secret key"
            },
            "terms_url": {
              "label": "Terms URL"
            },
            "top_back_button_on": "Show back button on top",
            "top_back_button_text": "Text",
            "top_back_button_url": "URL",
            "trial_calls_limit": {
              "label": "Call limit"
            },
            "users_section_on": {
              "label": "Users section on"
            },
            "wiki_url": {
              "label": "Wiki URL"
            }
          },
          "pricing": "Pricing",
          "tabs": {
            "blocked_subdomains": "Blocked subdomains",
            "external_services": "External services",
            "general": "General",
            "interface": "Interface settings",
            "subscription": "Subscription"
          },
          "trial": "Trial",
          "update_settings": "Update settings"
        }
      },
      "users": {
        "_user": {
          "workspaces": "Workspaces"
        }
      },
      "workspaces": {
        "_workspace": {
          "edit": "Edit"
        }
      }
    },
    "apis": {
      "show": {
        "add_call": "Add call",
        "auth": "Authentication header: Api-Key",
        "available_at": "The API is available at %{url}",
        "introduction": "Introduction"
      }
    },
    "calls": {
      "_form": {
        "description": {
          "label": "Description (optional)",
          "placeholder": "Describe the reason of call"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name of your call"
        },
        "password": {
          "label": "Password (optional)",
          "placeholder": "If you want to protect entrance with password enter your pass here"
        },
        "submit": "Start new call"
      },
      "index": {
        "_active": {
          "created_at": "%{date} ago",
          "created_by": "by %{name}",
          "edit": "Edit",
          "end": "End",
          "ended": "Ended",
          "go_to_chat_room": "Go to chat room",
          "live": "Live"
        },
        "_inner_bar": {
          "active": "Active",
          "ended": "Ended"
        },
        "_inner_layout": {
          "_no_active_calls": {
            "no_active_calls": "No active calls",
            "schedule": "Schedule",
            "start_now": "Start now"
          },
          "_tab_active_calls": {
            "actions": "Actions",
            "created_at": "Created date",
            "members": "Members",
            "name": "Name",
            "status": "Status"
          },
          "_tab_ended_calls": {
            "members": "Members",
            "name": "Name",
            "no_ended_calls": "No ended calls"
          }
        },
        "_page_title": {
          "current_plan": "Your current plan: %{count}/%{limit} free calls.",
          "schedule": "Schedule",
          "start_now": "Start now",
          "start_video_chat": "Start video chat"
        }
      },
      "new": {
        "start_new_call": "Start a new call"
      },
      "schedule": {
        "schedule_new_call": "Schedule new call"
      }
    },
    "devise": {
      "passwords": {
        "new": {
          "reset_password": "Reset your password",
          "send_instructions": "Send reset password instructions",
          "sign_in": "Cancel"
        }
      },
      "registrations": {
        "new": {
          "accept_terms": "Accept terms and conditions",
          "already_have_account": "Already have an account?",
          "create": "Create account",
          "create_account": "Create new account",
          "create_schedule_talk": "Create, Schedule, Talk",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "few_seconds": "It only takes a few seconds to create your account",
          "login_off": "Signing in is temporarily unavailable",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "private_video": "We offer a full private video-calls with use of Peer-to-peer technology",
          "registration_off": "Registration is temporarily unavailable",
          "sign_in": "Sign in"
        }
      },
      "sessions": {
        "new": {
          "build_room": "Build your video room",
          "create_account": "Create account",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "login_to_dashboard": "Login to dashboard",
          "no_account": "No account?",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "please_sign_in": "Please sign in to your account.",
          "recover_password": "Recover password",
          "send_instructions": "Send instructions",
          "sign_in": "Sign in",
          "sign_up": "Sign up now",
          "up_to": "Talk up to 10 people in a private Peer-to-peer connection"
        }
      }
    },
    "layouts": {
      "_app_header_right": {
        "logout": "Logout"
      },
      "admin": {
        "application": {
          "admins": "Admins",
          "api": "API",
          "calls": "Calls",
          "dashboard": "Dashboard",
          "settings": "Settings",
          "sign_out": "Sign out",
          "users": "Users",
          "workspaces": "Workspaces"
        }
      },
      "application": {
        "_app_header_right": {
          "add_workspace": "Add workspace",
          "calls_limit": "Calls: %{count}/%{limit}",
          "change_password": "Change password",
          "logout": "Logout",
          "profile": "Profile"
        },
        "_footer": {
          "copyright": "Copyright %{year} by Getreve",
          "policy": "Privacy policy",
          "terms": "Terms & Conditions"
        },
        "_horizontal_nav_menu": {
          "api": "API",
          "billing": "Billing",
          "calls": "Calls",
          "settings": "Settings",
          "users": "Users",
          "wiki": "Wiki"
        }
      },
      "wizard": {
        "logout": "Logout"
      }
    },
    "profiles": {
      "_password_form": {
        "password": {
          "label": "New password"
        }
      }
    },
    "rooms": {
      "_message_form": {
        "write_here_and_hit_enter": "Write here and hit enter to send..."
      },
      "_messages": {
        "history_not_displayed": "Due to security the history of this conversation will not be dislayed."
      },
      "_pending_room_member": {
        "accept": "Accept",
        "reject": "Reject"
      },
      "_video_room": {
        "reconnect": "Reconnect",
        "waiting_for_acceptance": "Waiting for acceptance"
      },
      "enter": {
        "chat_blocked": "This chat has been blocked",
        "chat_unavailable": "Chat unavailable",
        "enter_room": "Enter room",
        "welcome_to_room": "Welcome to room"
      },
      "show": {
        "powered_by": "Powered by Vli"
      }
    },
    "subscriptions": {
      "_form": {
        "calls_per_month": "%{count} calls"
      },
      "_incomplete": {
        "status": {
          "incomplete": "Incomplete"
        }
      },
      "show": {
        "_active": {
          "active_until": "<h5 class=\"mb-0\">Subscription will remain active until <strong>%{date}</strong>. After that date your limits will be downgraded to <strong>%{free_limit}</strong> calls per month. Are you sure?</h5>",
          "cancel": "Cancel subscription",
          "status": "Active"
        },
        "_canceled": {
          "status": "Canceled "
        },
        "_free": {
          "limit": "%{limit} calls <br> <small class='text-secondary'>per month</small>",
          "status": {
            "free": "Free"
          },
          "upgrade": "Upgrade limit"
        },
        "_incomplete": {
          "accept": "Click to accept payment",
          "complete_payment": "Complete your payment to activate subscription",
          "status": "Payment incomplete"
        },
        "status": {
          "cancel": "Cancel",
          "reactivate": "Reactivate",
          "upgrade": "Upgrade"
        }
      }
    },
    "users": {
      "_user": {
        "start_chat": "Start chat"
      },
      "index": {
        "_add": {
          "add": "Add"
        }
      }
    },
    "wizard": {
      "new": {
        "country": {
          "label": "Country",
          "placeholder": "Choose country..."
        },
        "currency": {
          "label": "Your currency",
          "placeholder": "Choose currency..."
        },
        "system_name": {
          "label": "Your business name",
          "placeholder": "Enter your business name..."
        },
        "time_zone": {
          "label": "Time zone",
          "placeholder": "Choose time zone..."
        },
        "your_business": "Tell us something about your business"
      },
      "show": {
        "choose_subdomain": "Choose your platform subdomain.",
        "own_address": "You will have your own dedicated address where people login to your video rooms.",
        "subdomain": {
          "label": "Your subdomain",
          "placeholder": "Enter subdomain..."
        }
      }
    }
  }
});
I18n.translations["cs"] = I18n.extend((I18n.translations["cs"] || {}), {
  "activerecord": {
    "attributes": {
      "room_member": {
        "entry_code": "Password",
        "name": "Name"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    },
    "models": {
      "user": {
        "one": "User",
        "other": "Users"
      }
    }
  },
  "common": {
    "are_you_sure": "Are you sure?",
    "cancel": "Cancel",
    "nothing_selected": "Nothing selected",
    "reset": "Reset password for",
    "submit": "Submit"
  },
  "controllers": {
    "admin": {
      "settings": {
        "create": {
          "notice": "Setting was successfully updated."
        }
      },
      "workspaces": {
        "update": {
          "saved": "Saved"
        }
      }
    },
    "workspaces": {
      "update": {
        "saved": "Settings saved."
      }
    }
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Second",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": "Not saved",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007fdecd8ecbd8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/rails-i18n-6.0.0/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    }
  },
  "javascript": {
    "controllers": {
      "subscriptions": {
        "calls_per_month": "%{count} calls",
        "subscribe": "Subscribe: %{price} / month"
      }
    }
  },
  "layouts": {
    "application": {
      "_app_header_right": {
        "change_password": "Change password",
        "profile": "Profile"
      }
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "#<Proc:0x00007fdecd8652c8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:26 (lambda)>",
      "ordinals": "#<Proc:0x00007fdecd8652f0@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:7 (lambda)>"
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "recaptcha": {
    "errors": {
      "recaptcha_unreachable": "Oops, we failed to validate your reCAPTCHA response. Please try again.",
      "verification_failed": "reCAPTCHA verification failed, please try again."
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "views": {
    "admin": {
      "settings": {
        "show": {
          "details": "Details",
          "fields": {
            "billing_section_on": {
              "label": "Billing section on"
            },
            "billings_off": {
              "label": "Billings off"
            },
            "bottom_back_button_on": "Show back button on bottom",
            "bottom_back_button_text": "Text",
            "bottom_back_button_url": "URL",
            "brand": {
              "label": "Brand name"
            },
            "brand_on": {
              "label": "Brand on"
            },
            "call_price": {
              "label": "Call price"
            },
            "calls_max_quantity": {
              "label": "Max calls quantity"
            },
            "calls_min_quantity": {
              "label": "Min calls quantity"
            },
            "free_calls_limit": {
              "label": "Free calls limit"
            },
            "intercom_app_id": {
              "label": "App ID"
            },
            "invite_button_on": {
              "label": "Invitation button on"
            },
            "login_on": {
              "label": "Login on"
            },
            "ocl_api_key": {
              "label": "API key"
            },
            "privacy_policy_url": {
              "label": "Privacy policy URL"
            },
            "registration_on": {
              "label": "Registration on"
            },
            "stripe_publishable_key": {
              "label": "Publishable key"
            },
            "stripe_secret_key": {
              "label": "Secret key"
            },
            "terms_url": {
              "label": "Terms URL"
            },
            "top_back_button_on": "Show back button on top",
            "top_back_button_text": "Text",
            "top_back_button_url": "URL",
            "trial_calls_limit": {
              "label": "Call limit"
            },
            "users_section_on": {
              "label": "Users section on"
            },
            "wiki_url": {
              "label": "Wiki URL"
            }
          },
          "pricing": "Pricing",
          "tabs": {
            "blocked_subdomains": "Blocked subdomains",
            "external_services": "External services",
            "general": "General",
            "interface": "Interface settings",
            "subscription": "Subscription"
          },
          "trial": "Trial",
          "update_settings": "Update settings"
        }
      },
      "users": {
        "_user": {
          "workspaces": "Workspaces"
        }
      },
      "workspaces": {
        "_workspace": {
          "edit": "Edit"
        }
      }
    },
    "apis": {
      "show": {
        "add_call": "Add call",
        "auth": "Authentication header: Api-Key",
        "available_at": "The API is available at %{url}",
        "introduction": "Introduction"
      }
    },
    "calls": {
      "_form": {
        "description": {
          "label": "Description (optional)",
          "placeholder": "Describe the reason of call"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name of your call"
        },
        "password": {
          "label": "Password (optional)",
          "placeholder": "If you want to protect entrance with password enter your pass here"
        },
        "submit": "Start new call"
      },
      "index": {
        "_active": {
          "created_at": "%{date} ago",
          "created_by": "by %{name}",
          "edit": "Edit",
          "end": "End",
          "ended": "Ended",
          "go_to_chat_room": "Go to chat room",
          "live": "Live"
        },
        "_inner_bar": {
          "active": "Active",
          "ended": "Ended"
        },
        "_inner_layout": {
          "_no_active_calls": {
            "no_active_calls": "No active calls",
            "schedule": "Schedule",
            "start_now": "Start now"
          },
          "_tab_active_calls": {
            "actions": "Actions",
            "created_at": "Created date",
            "members": "Members",
            "name": "Name",
            "status": "Status"
          },
          "_tab_ended_calls": {
            "members": "Members",
            "name": "Name",
            "no_ended_calls": "No ended calls"
          }
        },
        "_page_title": {
          "current_plan": "Your current plan: %{count}/%{limit} free calls.",
          "schedule": "Schedule",
          "start_now": "Start now",
          "start_video_chat": "Start video chat"
        }
      },
      "new": {
        "start_new_call": "Start a new call"
      },
      "schedule": {
        "schedule_new_call": "Schedule new call"
      }
    },
    "devise": {
      "passwords": {
        "new": {
          "reset_password": "Reset your password",
          "send_instructions": "Send reset password instructions",
          "sign_in": "Cancel"
        }
      },
      "registrations": {
        "new": {
          "accept_terms": "Accept terms and conditions",
          "already_have_account": "Already have an account?",
          "create": "Create account",
          "create_account": "Create new account",
          "create_schedule_talk": "Create, Schedule, Talk",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "few_seconds": "It only takes a few seconds to create your account",
          "login_off": "Signing in is temporarily unavailable",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "private_video": "We offer a full private video-calls with use of Peer-to-peer technology",
          "registration_off": "Registration is temporarily unavailable",
          "sign_in": "Sign in"
        }
      },
      "sessions": {
        "new": {
          "build_room": "Build your video room",
          "create_account": "Create account",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "login_to_dashboard": "Login to dashboard",
          "no_account": "No account?",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "please_sign_in": "Please sign in to your account.",
          "recover_password": "Recover password",
          "send_instructions": "Send instructions",
          "sign_in": "Sign in",
          "sign_up": "Sign up now",
          "up_to": "Talk up to 10 people in a private Peer-to-peer connection"
        }
      }
    },
    "layouts": {
      "_app_header_right": {
        "logout": "Logout"
      },
      "admin": {
        "application": {
          "admins": "Admins",
          "api": "API",
          "calls": "Calls",
          "dashboard": "Dashboard",
          "settings": "Settings",
          "sign_out": "Sign out",
          "users": "Users",
          "workspaces": "Workspaces"
        }
      },
      "application": {
        "_app_header_right": {
          "add_workspace": "Add workspace",
          "calls_limit": "Calls: %{count}/%{limit}",
          "change_password": "Change password",
          "logout": "Logout",
          "profile": "Profile"
        },
        "_footer": {
          "copyright": "Copyright %{year} by Getreve",
          "policy": "Privacy policy",
          "terms": "Terms & Conditions"
        },
        "_horizontal_nav_menu": {
          "api": "API",
          "billing": "Billing",
          "calls": "Calls",
          "settings": "Settings",
          "users": "Users",
          "wiki": "Wiki"
        }
      },
      "wizard": {
        "logout": "Logout"
      }
    },
    "profiles": {
      "_password_form": {
        "password": {
          "label": "New password"
        }
      }
    },
    "rooms": {
      "_message_form": {
        "write_here_and_hit_enter": "Write here and hit enter to send..."
      },
      "_messages": {
        "history_not_displayed": "Due to security the history of this conversation will not be dislayed."
      },
      "_pending_room_member": {
        "accept": "Accept",
        "reject": "Reject"
      },
      "_video_room": {
        "reconnect": "Reconnect",
        "waiting_for_acceptance": "Waiting for acceptance"
      },
      "enter": {
        "chat_blocked": "This chat has been blocked",
        "chat_unavailable": "Chat unavailable",
        "enter_room": "Enter room",
        "welcome_to_room": "Welcome to room"
      },
      "show": {
        "powered_by": "Powered by Vli"
      }
    },
    "subscriptions": {
      "_form": {
        "calls_per_month": "%{count} calls"
      },
      "_incomplete": {
        "status": {
          "incomplete": "Incomplete"
        }
      },
      "show": {
        "_active": {
          "active_until": "<h5 class=\"mb-0\">Subscription will remain active until <strong>%{date}</strong>. After that date your limits will be downgraded to <strong>%{free_limit}</strong> calls per month. Are you sure?</h5>",
          "cancel": "Cancel subscription",
          "status": "Active"
        },
        "_canceled": {
          "status": "Canceled "
        },
        "_free": {
          "limit": "%{limit} calls <br> <small class='text-secondary'>per month</small>",
          "status": {
            "free": "Free"
          },
          "upgrade": "Upgrade limit"
        },
        "_incomplete": {
          "accept": "Click to accept payment",
          "complete_payment": "Complete your payment to activate subscription",
          "status": "Payment incomplete"
        },
        "status": {
          "cancel": "Cancel",
          "reactivate": "Reactivate",
          "upgrade": "Upgrade"
        }
      }
    },
    "users": {
      "_user": {
        "start_chat": "Start chat"
      },
      "index": {
        "_add": {
          "add": "Add"
        }
      }
    },
    "wizard": {
      "new": {
        "country": {
          "label": "Country",
          "placeholder": "Choose country..."
        },
        "currency": {
          "label": "Your currency",
          "placeholder": "Choose currency..."
        },
        "system_name": {
          "label": "Your business name",
          "placeholder": "Enter your business name..."
        },
        "time_zone": {
          "label": "Time zone",
          "placeholder": "Choose time zone..."
        },
        "your_business": "Tell us something about your business"
      },
      "show": {
        "choose_subdomain": "Choose your platform subdomain.",
        "own_address": "You will have your own dedicated address where people login to your video rooms.",
        "subdomain": {
          "label": "Your subdomain",
          "placeholder": "Enter subdomain..."
        }
      }
    }
  }
});
I18n.translations["de"] = I18n.extend((I18n.translations["de"] || {}), {
  "activerecord": {
    "attributes": {
      "room_member": {
        "entry_code": "Password",
        "name": "Name"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    },
    "models": {
      "user": {
        "one": "User",
        "other": "Users"
      }
    }
  },
  "common": {
    "are_you_sure": "Are you sure?",
    "cancel": "Cancel",
    "nothing_selected": "Nothing selected",
    "reset": "Reset password for",
    "submit": "Submit"
  },
  "controllers": {
    "admin": {
      "settings": {
        "create": {
          "notice": "Setting was successfully updated."
        }
      },
      "workspaces": {
        "update": {
          "saved": "Saved"
        }
      }
    },
    "workspaces": {
      "update": {
        "saved": "Settings saved."
      }
    }
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Second",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": "Not saved",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007fdecd8ecbd8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/rails-i18n-6.0.0/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    }
  },
  "javascript": {
    "controllers": {
      "subscriptions": {
        "calls_per_month": "%{count} calls",
        "subscribe": "Subscribe: %{price} / month"
      }
    }
  },
  "layouts": {
    "application": {
      "_app_header_right": {
        "change_password": "Change password",
        "profile": "Profile"
      }
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "#<Proc:0x00007fdecd8652c8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:26 (lambda)>",
      "ordinals": "#<Proc:0x00007fdecd8652f0@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:7 (lambda)>"
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "recaptcha": {
    "errors": {
      "recaptcha_unreachable": "Oops, we failed to validate your reCAPTCHA response. Please try again.",
      "verification_failed": "reCAPTCHA verification failed, please try again."
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "views": {
    "admin": {
      "settings": {
        "show": {
          "details": "Details",
          "fields": {
            "billing_section_on": {
              "label": "Billing section on"
            },
            "billings_off": {
              "label": "Billings off"
            },
            "bottom_back_button_on": "Show back button on bottom",
            "bottom_back_button_text": "Text",
            "bottom_back_button_url": "URL",
            "brand": {
              "label": "Brand name"
            },
            "brand_on": {
              "label": "Brand on"
            },
            "call_price": {
              "label": "Call price"
            },
            "calls_max_quantity": {
              "label": "Max calls quantity"
            },
            "calls_min_quantity": {
              "label": "Min calls quantity"
            },
            "free_calls_limit": {
              "label": "Free calls limit"
            },
            "intercom_app_id": {
              "label": "App ID"
            },
            "invite_button_on": {
              "label": "Invitation button on"
            },
            "login_on": {
              "label": "Login on"
            },
            "ocl_api_key": {
              "label": "API key"
            },
            "privacy_policy_url": {
              "label": "Privacy policy URL"
            },
            "registration_on": {
              "label": "Registration on"
            },
            "stripe_publishable_key": {
              "label": "Publishable key"
            },
            "stripe_secret_key": {
              "label": "Secret key"
            },
            "terms_url": {
              "label": "Terms URL"
            },
            "top_back_button_on": "Show back button on top",
            "top_back_button_text": "Text",
            "top_back_button_url": "URL",
            "trial_calls_limit": {
              "label": "Call limit"
            },
            "users_section_on": {
              "label": "Users section on"
            },
            "wiki_url": {
              "label": "Wiki URL"
            }
          },
          "pricing": "Pricing",
          "tabs": {
            "blocked_subdomains": "Blocked subdomains",
            "external_services": "External services",
            "general": "General",
            "interface": "Interface settings",
            "subscription": "Subscription"
          },
          "trial": "Trial",
          "update_settings": "Update settings"
        }
      },
      "users": {
        "_user": {
          "workspaces": "Workspaces"
        }
      },
      "workspaces": {
        "_workspace": {
          "edit": "Edit"
        }
      }
    },
    "apis": {
      "show": {
        "add_call": "Add call",
        "auth": "Authentication header: Api-Key",
        "available_at": "The API is available at %{url}",
        "introduction": "Introduction"
      }
    },
    "calls": {
      "_form": {
        "description": {
          "label": "Description (optional)",
          "placeholder": "Describe the reason of call"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name of your call"
        },
        "password": {
          "label": "Password (optional)",
          "placeholder": "If you want to protect entrance with password enter your pass here"
        },
        "submit": "Start new call"
      },
      "index": {
        "_active": {
          "created_at": "%{date} ago",
          "created_by": "by %{name}",
          "edit": "Edit",
          "end": "End",
          "ended": "Ended",
          "go_to_chat_room": "Go to chat room",
          "live": "Live"
        },
        "_inner_bar": {
          "active": "Active",
          "ended": "Ended"
        },
        "_inner_layout": {
          "_no_active_calls": {
            "no_active_calls": "No active calls",
            "schedule": "Schedule",
            "start_now": "Start now"
          },
          "_tab_active_calls": {
            "actions": "Actions",
            "created_at": "Created date",
            "members": "Members",
            "name": "Name",
            "status": "Status"
          },
          "_tab_ended_calls": {
            "members": "Members",
            "name": "Name",
            "no_ended_calls": "No ended calls"
          }
        },
        "_page_title": {
          "current_plan": "Your current plan: %{count}/%{limit} free calls.",
          "schedule": "Schedule",
          "start_now": "Start now",
          "start_video_chat": "Start video chat"
        }
      },
      "new": {
        "start_new_call": "Start a new call"
      },
      "schedule": {
        "schedule_new_call": "Schedule new call"
      }
    },
    "devise": {
      "passwords": {
        "new": {
          "reset_password": "Reset your password",
          "send_instructions": "Send reset password instructions",
          "sign_in": "Cancel"
        }
      },
      "registrations": {
        "new": {
          "accept_terms": "Accept terms and conditions",
          "already_have_account": "Already have an account?",
          "create": "Create account",
          "create_account": "Create new account",
          "create_schedule_talk": "Create, Schedule, Talk",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "few_seconds": "It only takes a few seconds to create your account",
          "login_off": "Signing in is temporarily unavailable",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "private_video": "We offer a full private video-calls with use of Peer-to-peer technology",
          "registration_off": "Registration is temporarily unavailable",
          "sign_in": "Sign in"
        }
      },
      "sessions": {
        "new": {
          "build_room": "Build your video room",
          "create_account": "Create account",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "login_to_dashboard": "Login to dashboard",
          "no_account": "No account?",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "please_sign_in": "Please sign in to your account.",
          "recover_password": "Recover password",
          "send_instructions": "Send instructions",
          "sign_in": "Sign in",
          "sign_up": "Sign up now",
          "up_to": "Talk up to 10 people in a private Peer-to-peer connection"
        }
      }
    },
    "layouts": {
      "_app_header_right": {
        "logout": "Logout"
      },
      "admin": {
        "application": {
          "admins": "Admins",
          "api": "API",
          "calls": "Calls",
          "dashboard": "Dashboard",
          "settings": "Settings",
          "sign_out": "Sign out",
          "users": "Users",
          "workspaces": "Workspaces"
        }
      },
      "application": {
        "_app_header_right": {
          "add_workspace": "Add workspace",
          "calls_limit": "Calls: %{count}/%{limit}",
          "change_password": "Change password",
          "logout": "Logout",
          "profile": "Profile"
        },
        "_footer": {
          "copyright": "Copyright %{year} by Getreve",
          "policy": "Privacy policy",
          "terms": "Terms & Conditions"
        },
        "_horizontal_nav_menu": {
          "api": "API",
          "billing": "Billing",
          "calls": "Calls",
          "settings": "Settings",
          "users": "Users",
          "wiki": "Wiki"
        }
      },
      "wizard": {
        "logout": "Logout"
      }
    },
    "profiles": {
      "_password_form": {
        "password": {
          "label": "New password"
        }
      }
    },
    "rooms": {
      "_message_form": {
        "write_here_and_hit_enter": "Write here and hit enter to send..."
      },
      "_messages": {
        "history_not_displayed": "Due to security the history of this conversation will not be dislayed."
      },
      "_pending_room_member": {
        "accept": "Accept",
        "reject": "Reject"
      },
      "_video_room": {
        "reconnect": "Reconnect",
        "waiting_for_acceptance": "Waiting for acceptance"
      },
      "enter": {
        "chat_blocked": "This chat has been blocked",
        "chat_unavailable": "Chat unavailable",
        "enter_room": "Enter room",
        "welcome_to_room": "Welcome to room"
      },
      "show": {
        "powered_by": "Powered by Vli"
      }
    },
    "subscriptions": {
      "_form": {
        "calls_per_month": "%{count} calls"
      },
      "_incomplete": {
        "status": {
          "incomplete": "Incomplete"
        }
      },
      "show": {
        "_active": {
          "active_until": "<h5 class=\"mb-0\">Subscription will remain active until <strong>%{date}</strong>. After that date your limits will be downgraded to <strong>%{free_limit}</strong> calls per month. Are you sure?</h5>",
          "cancel": "Cancel subscription",
          "status": "Active"
        },
        "_canceled": {
          "status": "Canceled "
        },
        "_free": {
          "limit": "%{limit} calls <br> <small class='text-secondary'>per month</small>",
          "status": {
            "free": "Free"
          },
          "upgrade": "Upgrade limit"
        },
        "_incomplete": {
          "accept": "Click to accept payment",
          "complete_payment": "Complete your payment to activate subscription",
          "status": "Payment incomplete"
        },
        "status": {
          "cancel": "Cancel",
          "reactivate": "Reactivate",
          "upgrade": "Upgrade"
        }
      }
    },
    "users": {
      "_user": {
        "start_chat": "Start chat"
      },
      "index": {
        "_add": {
          "add": "Add"
        }
      }
    },
    "wizard": {
      "new": {
        "country": {
          "label": "Country",
          "placeholder": "Choose country..."
        },
        "currency": {
          "label": "Your currency",
          "placeholder": "Choose currency..."
        },
        "system_name": {
          "label": "Your business name",
          "placeholder": "Enter your business name..."
        },
        "time_zone": {
          "label": "Time zone",
          "placeholder": "Choose time zone..."
        },
        "your_business": "Tell us something about your business"
      },
      "show": {
        "choose_subdomain": "Choose your platform subdomain.",
        "own_address": "You will have your own dedicated address where people login to your video rooms.",
        "subdomain": {
          "label": "Your subdomain",
          "placeholder": "Enter subdomain..."
        }
      }
    }
  }
});
I18n.translations["en"] = I18n.extend((I18n.translations["en"] || {}), {
  "activerecord": {
    "attributes": {
      "room_member": {
        "entry_code": "Password",
        "name": "Name"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    },
    "models": {
      "user": {
        "one": "User",
        "other": "Users"
      }
    }
  },
  "common": {
    "are_you_sure": "Are you sure?",
    "cancel": "Cancel",
    "nothing_selected": "Nothing selected",
    "reset": "Reset password for",
    "submit": "Submit"
  },
  "controllers": {
    "admin": {
      "settings": {
        "create": {
          "notice": "Setting was successfully updated."
        }
      },
      "workspaces": {
        "update": {
          "saved": "Saved"
        }
      }
    },
    "workspaces": {
      "update": {
        "saved": "Settings saved."
      }
    }
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Second",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": "Not saved",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007fdecd8ecbd8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/rails-i18n-6.0.0/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    }
  },
  "javascript": {
    "controllers": {
      "subscriptions": {
        "calls_per_month": "%{count} calls",
        "subscribe": "Subscribe: %{price} / month"
      }
    }
  },
  "layouts": {
    "application": {
      "_app_header_right": {
        "change_password": "Change password",
        "profile": "Profile"
      }
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "#<Proc:0x00007fdecd8652c8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:26 (lambda)>",
      "ordinals": "#<Proc:0x00007fdecd8652f0@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:7 (lambda)>"
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "recaptcha": {
    "errors": {
      "recaptcha_unreachable": "Oops, we failed to validate your reCAPTCHA response. Please try again.",
      "verification_failed": "reCAPTCHA verification failed, please try again."
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "views": {
    "admin": {
      "settings": {
        "show": {
          "details": "Details",
          "fields": {
            "billing_section_on": {
              "label": "Billing section on"
            },
            "billings_off": {
              "label": "Billings off"
            },
            "bottom_back_button_on": "Show back button on bottom",
            "bottom_back_button_text": "Text",
            "bottom_back_button_url": "URL",
            "brand": {
              "label": "Brand name"
            },
            "brand_on": {
              "label": "Brand on"
            },
            "call_price": {
              "label": "Call price"
            },
            "calls_max_quantity": {
              "label": "Max calls quantity"
            },
            "calls_min_quantity": {
              "label": "Min calls quantity"
            },
            "free_calls_limit": {
              "label": "Free calls limit"
            },
            "intercom_app_id": {
              "label": "App ID"
            },
            "invite_button_on": {
              "label": "Invitation button on"
            },
            "login_on": {
              "label": "Login on"
            },
            "ocl_api_key": {
              "label": "API key"
            },
            "privacy_policy_url": {
              "label": "Privacy policy URL"
            },
            "registration_on": {
              "label": "Registration on"
            },
            "stripe_publishable_key": {
              "label": "Publishable key"
            },
            "stripe_secret_key": {
              "label": "Secret key"
            },
            "terms_url": {
              "label": "Terms URL"
            },
            "top_back_button_on": "Show back button on top",
            "top_back_button_text": "Text",
            "top_back_button_url": "URL",
            "trial_calls_limit": {
              "label": "Call limit"
            },
            "users_section_on": {
              "label": "Users section on"
            },
            "wiki_url": {
              "label": "Wiki URL"
            }
          },
          "pricing": "Pricing",
          "tabs": {
            "blocked_subdomains": "Blocked subdomains",
            "external_services": "External services",
            "general": "General",
            "interface": "Interface settings",
            "subscription": "Subscription"
          },
          "trial": "Trial",
          "update_settings": "Update settings"
        }
      },
      "users": {
        "_user": {
          "workspaces": "Workspaces"
        }
      },
      "workspaces": {
        "_workspace": {
          "edit": "Edit"
        }
      }
    },
    "apis": {
      "show": {
        "add_call": "Add call",
        "auth": "Authentication header: Api-Key",
        "available_at": "The API is available at %{url}",
        "introduction": "Introduction"
      }
    },
    "calls": {
      "_form": {
        "description": {
          "label": "Description (optional)",
          "placeholder": "Describe the reason of call"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name of your call"
        },
        "password": {
          "label": "Password (optional)",
          "placeholder": "If you want to protect entrance with password enter your pass here"
        },
        "submit": "Start new call"
      },
      "index": {
        "_active": {
          "created_at": "%{date} ago",
          "created_by": "by %{name}",
          "edit": "Edit",
          "end": "End",
          "ended": "Ended",
          "go_to_chat_room": "Go to chat room",
          "live": "Live"
        },
        "_inner_bar": {
          "active": "Active",
          "ended": "Ended"
        },
        "_inner_layout": {
          "_no_active_calls": {
            "no_active_calls": "No active calls",
            "schedule": "Schedule",
            "start_now": "Start now"
          },
          "_tab_active_calls": {
            "actions": "Actions",
            "created_at": "Created date",
            "members": "Members",
            "name": "Name",
            "status": "Status"
          },
          "_tab_ended_calls": {
            "members": "Members",
            "name": "Name",
            "no_ended_calls": "No ended calls"
          }
        },
        "_page_title": {
          "current_plan": "Your current plan: %{count}/%{limit} free calls.",
          "schedule": "Schedule",
          "start_now": "Start now",
          "start_video_chat": "Start video chat"
        }
      },
      "new": {
        "start_new_call": "Start a new call"
      },
      "schedule": {
        "schedule_new_call": "Schedule new call"
      }
    },
    "devise": {
      "passwords": {
        "new": {
          "reset_password": "Reset your password",
          "send_instructions": "Send reset password instructions",
          "sign_in": "Cancel"
        }
      },
      "registrations": {
        "new": {
          "accept_terms": "Accept terms and conditions",
          "already_have_account": "Already have an account?",
          "create": "Create account",
          "create_account": "Create new account",
          "create_schedule_talk": "Create, Schedule, Talk",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "few_seconds": "It only takes a few seconds to create your account",
          "login_off": "Signing in is temporarily unavailable",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "private_video": "We offer a full private video-calls with use of Peer-to-peer technology",
          "registration_off": "Registration is temporarily unavailable",
          "sign_in": "Sign in"
        }
      },
      "sessions": {
        "new": {
          "build_room": "Build your video room",
          "create_account": "Create account",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "login_to_dashboard": "Login to dashboard",
          "no_account": "No account?",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "please_sign_in": "Please sign in to your account.",
          "recover_password": "Recover password",
          "send_instructions": "Send instructions",
          "sign_in": "Sign in",
          "sign_up": "Sign up now",
          "up_to": "Talk up to 10 people in a private Peer-to-peer connection"
        }
      }
    },
    "layouts": {
      "_app_header_right": {
        "logout": "Logout"
      },
      "admin": {
        "application": {
          "admins": "Admins",
          "api": "API",
          "calls": "Calls",
          "dashboard": "Dashboard",
          "settings": "Settings",
          "sign_out": "Sign out",
          "users": "Users",
          "workspaces": "Workspaces"
        }
      },
      "application": {
        "_app_header_right": {
          "add_workspace": "Add workspace",
          "calls_limit": "Calls: %{count}/%{limit}",
          "change_password": "Change password",
          "logout": "Logout",
          "profile": "Profile"
        },
        "_footer": {
          "copyright": "Copyright %{year} by Getreve",
          "policy": "Privacy policy",
          "terms": "Terms & Conditions"
        },
        "_horizontal_nav_menu": {
          "api": "API",
          "billing": "Billing",
          "calls": "Calls",
          "settings": "Settings",
          "users": "Users",
          "wiki": "Wiki"
        }
      },
      "wizard": {
        "logout": "Logout"
      }
    },
    "profiles": {
      "_password_form": {
        "password": {
          "label": "New password"
        }
      }
    },
    "rooms": {
      "_message_form": {
        "write_here_and_hit_enter": "Write here and hit enter to send..."
      },
      "_messages": {
        "history_not_displayed": "Due to security the history of this conversation will not be dislayed."
      },
      "_pending_room_member": {
        "accept": "Accept",
        "reject": "Reject"
      },
      "_video_room": {
        "reconnect": "Reconnect",
        "waiting_for_acceptance": "Waiting for acceptance"
      },
      "enter": {
        "chat_blocked": "This chat has been blocked",
        "chat_unavailable": "Chat unavailable",
        "enter_room": "Enter room",
        "welcome_to_room": "Welcome to room"
      },
      "show": {
        "powered_by": "Powered by Vli"
      }
    },
    "subscriptions": {
      "_form": {
        "calls_per_month": "%{count} calls"
      },
      "_incomplete": {
        "status": {
          "incomplete": "Incomplete"
        }
      },
      "show": {
        "_active": {
          "active_until": "<h5 class=\"mb-0\">Subscription will remain active until <strong>%{date}</strong>. After that date your limits will be downgraded to <strong>%{free_limit}</strong> calls per month. Are you sure?</h5>",
          "cancel": "Cancel subscription",
          "status": "Active"
        },
        "_canceled": {
          "status": "Canceled "
        },
        "_free": {
          "limit": "%{limit} calls <br> <small class='text-secondary'>per month</small>",
          "status": {
            "free": "Free"
          },
          "upgrade": "Upgrade limit"
        },
        "_incomplete": {
          "accept": "Click to accept payment",
          "complete_payment": "Complete your payment to activate subscription",
          "status": "Payment incomplete"
        },
        "status": {
          "cancel": "Cancel",
          "reactivate": "Reactivate",
          "upgrade": "Upgrade"
        }
      }
    },
    "users": {
      "_user": {
        "start_chat": "Start chat"
      },
      "index": {
        "_add": {
          "add": "Add"
        }
      }
    },
    "wizard": {
      "new": {
        "country": {
          "label": "Country",
          "placeholder": "Choose country..."
        },
        "currency": {
          "label": "Your currency",
          "placeholder": "Choose currency..."
        },
        "system_name": {
          "label": "Your business name",
          "placeholder": "Enter your business name..."
        },
        "time_zone": {
          "label": "Time zone",
          "placeholder": "Choose time zone..."
        },
        "your_business": "Tell us something about your business"
      },
      "show": {
        "choose_subdomain": "Choose your platform subdomain.",
        "own_address": "You will have your own dedicated address where people login to your video rooms.",
        "subdomain": {
          "label": "Your subdomain",
          "placeholder": "Enter subdomain..."
        }
      }
    }
  }
});
I18n.translations["es"] = I18n.extend((I18n.translations["es"] || {}), {
  "activerecord": {
    "attributes": {
      "room_member": {
        "entry_code": "Password",
        "name": "Name"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    },
    "models": {
      "user": {
        "one": "User",
        "other": "Users"
      }
    }
  },
  "common": {
    "are_you_sure": "Are you sure?",
    "cancel": "Cancel",
    "nothing_selected": "Nothing selected",
    "reset": "Reset password for",
    "submit": "Submit"
  },
  "controllers": {
    "admin": {
      "settings": {
        "create": {
          "notice": "Setting was successfully updated."
        }
      },
      "workspaces": {
        "update": {
          "saved": "Saved"
        }
      }
    },
    "workspaces": {
      "update": {
        "saved": "Settings saved."
      }
    }
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Second",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": "Not saved",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007fdecd8ecbd8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/rails-i18n-6.0.0/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    }
  },
  "javascript": {
    "controllers": {
      "subscriptions": {
        "calls_per_month": "%{count} calls",
        "subscribe": "Subscribe: %{price} / month"
      }
    }
  },
  "layouts": {
    "application": {
      "_app_header_right": {
        "change_password": "Change password",
        "profile": "Profile"
      }
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "#<Proc:0x00007fdecd8652c8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:26 (lambda)>",
      "ordinals": "#<Proc:0x00007fdecd8652f0@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:7 (lambda)>"
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "recaptcha": {
    "errors": {
      "recaptcha_unreachable": "Oops, we failed to validate your reCAPTCHA response. Please try again.",
      "verification_failed": "reCAPTCHA verification failed, please try again."
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "views": {
    "admin": {
      "settings": {
        "show": {
          "details": "Details",
          "fields": {
            "billing_section_on": {
              "label": "Billing section on"
            },
            "billings_off": {
              "label": "Billings off"
            },
            "bottom_back_button_on": "Show back button on bottom",
            "bottom_back_button_text": "Text",
            "bottom_back_button_url": "URL",
            "brand": {
              "label": "Brand name"
            },
            "brand_on": {
              "label": "Brand on"
            },
            "call_price": {
              "label": "Call price"
            },
            "calls_max_quantity": {
              "label": "Max calls quantity"
            },
            "calls_min_quantity": {
              "label": "Min calls quantity"
            },
            "free_calls_limit": {
              "label": "Free calls limit"
            },
            "intercom_app_id": {
              "label": "App ID"
            },
            "invite_button_on": {
              "label": "Invitation button on"
            },
            "login_on": {
              "label": "Login on"
            },
            "ocl_api_key": {
              "label": "API key"
            },
            "privacy_policy_url": {
              "label": "Privacy policy URL"
            },
            "registration_on": {
              "label": "Registration on"
            },
            "stripe_publishable_key": {
              "label": "Publishable key"
            },
            "stripe_secret_key": {
              "label": "Secret key"
            },
            "terms_url": {
              "label": "Terms URL"
            },
            "top_back_button_on": "Show back button on top",
            "top_back_button_text": "Text",
            "top_back_button_url": "URL",
            "trial_calls_limit": {
              "label": "Call limit"
            },
            "users_section_on": {
              "label": "Users section on"
            },
            "wiki_url": {
              "label": "Wiki URL"
            }
          },
          "pricing": "Pricing",
          "tabs": {
            "blocked_subdomains": "Blocked subdomains",
            "external_services": "External services",
            "general": "General",
            "interface": "Interface settings",
            "subscription": "Subscription"
          },
          "trial": "Trial",
          "update_settings": "Update settings"
        }
      },
      "users": {
        "_user": {
          "workspaces": "Workspaces"
        }
      },
      "workspaces": {
        "_workspace": {
          "edit": "Edit"
        }
      }
    },
    "apis": {
      "show": {
        "add_call": "Add call",
        "auth": "Authentication header: Api-Key",
        "available_at": "The API is available at %{url}",
        "introduction": "Introduction"
      }
    },
    "calls": {
      "_form": {
        "description": {
          "label": "Description (optional)",
          "placeholder": "Describe the reason of call"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name of your call"
        },
        "password": {
          "label": "Password (optional)",
          "placeholder": "If you want to protect entrance with password enter your pass here"
        },
        "submit": "Start new call"
      },
      "index": {
        "_active": {
          "created_at": "%{date} ago",
          "created_by": "by %{name}",
          "edit": "Edit",
          "end": "End",
          "ended": "Ended",
          "go_to_chat_room": "Go to chat room",
          "live": "Live"
        },
        "_inner_bar": {
          "active": "Active",
          "ended": "Ended"
        },
        "_inner_layout": {
          "_no_active_calls": {
            "no_active_calls": "No active calls",
            "schedule": "Schedule",
            "start_now": "Start now"
          },
          "_tab_active_calls": {
            "actions": "Actions",
            "created_at": "Created date",
            "members": "Members",
            "name": "Name",
            "status": "Status"
          },
          "_tab_ended_calls": {
            "members": "Members",
            "name": "Name",
            "no_ended_calls": "No ended calls"
          }
        },
        "_page_title": {
          "current_plan": "Your current plan: %{count}/%{limit} free calls.",
          "schedule": "Schedule",
          "start_now": "Start now",
          "start_video_chat": "Start video chat"
        }
      },
      "new": {
        "start_new_call": "Start a new call"
      },
      "schedule": {
        "schedule_new_call": "Schedule new call"
      }
    },
    "devise": {
      "passwords": {
        "new": {
          "reset_password": "Reset your password",
          "send_instructions": "Send reset password instructions",
          "sign_in": "Cancel"
        }
      },
      "registrations": {
        "new": {
          "accept_terms": "Accept terms and conditions",
          "already_have_account": "Already have an account?",
          "create": "Create account",
          "create_account": "Create new account",
          "create_schedule_talk": "Create, Schedule, Talk",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "few_seconds": "It only takes a few seconds to create your account",
          "login_off": "Signing in is temporarily unavailable",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "private_video": "We offer a full private video-calls with use of Peer-to-peer technology",
          "registration_off": "Registration is temporarily unavailable",
          "sign_in": "Sign in"
        }
      },
      "sessions": {
        "new": {
          "build_room": "Build your video room",
          "create_account": "Create account",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "login_to_dashboard": "Login to dashboard",
          "no_account": "No account?",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "please_sign_in": "Please sign in to your account.",
          "recover_password": "Recover password",
          "send_instructions": "Send instructions",
          "sign_in": "Sign in",
          "sign_up": "Sign up now",
          "up_to": "Talk up to 10 people in a private Peer-to-peer connection"
        }
      }
    },
    "layouts": {
      "_app_header_right": {
        "logout": "Logout"
      },
      "admin": {
        "application": {
          "admins": "Admins",
          "api": "API",
          "calls": "Calls",
          "dashboard": "Dashboard",
          "settings": "Settings",
          "sign_out": "Sign out",
          "users": "Users",
          "workspaces": "Workspaces"
        }
      },
      "application": {
        "_app_header_right": {
          "add_workspace": "Add workspace",
          "calls_limit": "Calls: %{count}/%{limit}",
          "change_password": "Change password",
          "logout": "Logout",
          "profile": "Profile"
        },
        "_footer": {
          "copyright": "Copyright %{year} by Getreve",
          "policy": "Privacy policy",
          "terms": "Terms & Conditions"
        },
        "_horizontal_nav_menu": {
          "api": "API",
          "billing": "Billing",
          "calls": "Calls",
          "settings": "Settings",
          "users": "Users",
          "wiki": "Wiki"
        }
      },
      "wizard": {
        "logout": "Logout"
      }
    },
    "profiles": {
      "_password_form": {
        "password": {
          "label": "New password"
        }
      }
    },
    "rooms": {
      "_message_form": {
        "write_here_and_hit_enter": "Write here and hit enter to send..."
      },
      "_messages": {
        "history_not_displayed": "Due to security the history of this conversation will not be dislayed."
      },
      "_pending_room_member": {
        "accept": "Accept",
        "reject": "Reject"
      },
      "_video_room": {
        "reconnect": "Reconnect",
        "waiting_for_acceptance": "Waiting for acceptance"
      },
      "enter": {
        "chat_blocked": "This chat has been blocked",
        "chat_unavailable": "Chat unavailable",
        "enter_room": "Enter room",
        "welcome_to_room": "Welcome to room"
      },
      "show": {
        "powered_by": "Powered by Vli"
      }
    },
    "subscriptions": {
      "_form": {
        "calls_per_month": "%{count} calls"
      },
      "_incomplete": {
        "status": {
          "incomplete": "Incomplete"
        }
      },
      "show": {
        "_active": {
          "active_until": "<h5 class=\"mb-0\">Subscription will remain active until <strong>%{date}</strong>. After that date your limits will be downgraded to <strong>%{free_limit}</strong> calls per month. Are you sure?</h5>",
          "cancel": "Cancel subscription",
          "status": "Active"
        },
        "_canceled": {
          "status": "Canceled "
        },
        "_free": {
          "limit": "%{limit} calls <br> <small class='text-secondary'>per month</small>",
          "status": {
            "free": "Free"
          },
          "upgrade": "Upgrade limit"
        },
        "_incomplete": {
          "accept": "Click to accept payment",
          "complete_payment": "Complete your payment to activate subscription",
          "status": "Payment incomplete"
        },
        "status": {
          "cancel": "Cancel",
          "reactivate": "Reactivate",
          "upgrade": "Upgrade"
        }
      }
    },
    "users": {
      "_user": {
        "start_chat": "Start chat"
      },
      "index": {
        "_add": {
          "add": "Add"
        }
      }
    },
    "wizard": {
      "new": {
        "country": {
          "label": "Country",
          "placeholder": "Choose country..."
        },
        "currency": {
          "label": "Your currency",
          "placeholder": "Choose currency..."
        },
        "system_name": {
          "label": "Your business name",
          "placeholder": "Enter your business name..."
        },
        "time_zone": {
          "label": "Time zone",
          "placeholder": "Choose time zone..."
        },
        "your_business": "Tell us something about your business"
      },
      "show": {
        "choose_subdomain": "Choose your platform subdomain.",
        "own_address": "You will have your own dedicated address where people login to your video rooms.",
        "subdomain": {
          "label": "Your subdomain",
          "placeholder": "Enter subdomain..."
        }
      }
    }
  }
});
I18n.translations["fr"] = I18n.extend((I18n.translations["fr"] || {}), {
  "activerecord": {
    "attributes": {
      "room_member": {
        "entry_code": "Password",
        "name": "Name"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    },
    "models": {
      "user": {
        "one": "User",
        "other": "Users"
      }
    }
  },
  "common": {
    "are_you_sure": "Are you sure?",
    "cancel": "Cancel",
    "nothing_selected": "Nothing selected",
    "reset": "Reset password for",
    "submit": "Submit"
  },
  "controllers": {
    "admin": {
      "settings": {
        "create": {
          "notice": "Setting was successfully updated."
        }
      },
      "workspaces": {
        "update": {
          "saved": "Saved"
        }
      }
    },
    "workspaces": {
      "update": {
        "saved": "Settings saved."
      }
    }
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Second",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": "Not saved",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007fdecd8ecbd8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/rails-i18n-6.0.0/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    }
  },
  "javascript": {
    "controllers": {
      "subscriptions": {
        "calls_per_month": "%{count} calls",
        "subscribe": "Subscribe: %{price} / month"
      }
    }
  },
  "layouts": {
    "application": {
      "_app_header_right": {
        "change_password": "Change password",
        "profile": "Profile"
      }
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "#<Proc:0x00007fdecd8652c8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:26 (lambda)>",
      "ordinals": "#<Proc:0x00007fdecd8652f0@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:7 (lambda)>"
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "recaptcha": {
    "errors": {
      "recaptcha_unreachable": "Oops, we failed to validate your reCAPTCHA response. Please try again.",
      "verification_failed": "reCAPTCHA verification failed, please try again."
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "views": {
    "admin": {
      "settings": {
        "show": {
          "details": "Details",
          "fields": {
            "billing_section_on": {
              "label": "Billing section on"
            },
            "billings_off": {
              "label": "Billings off"
            },
            "bottom_back_button_on": "Show back button on bottom",
            "bottom_back_button_text": "Text",
            "bottom_back_button_url": "URL",
            "brand": {
              "label": "Brand name"
            },
            "brand_on": {
              "label": "Brand on"
            },
            "call_price": {
              "label": "Call price"
            },
            "calls_max_quantity": {
              "label": "Max calls quantity"
            },
            "calls_min_quantity": {
              "label": "Min calls quantity"
            },
            "free_calls_limit": {
              "label": "Free calls limit"
            },
            "intercom_app_id": {
              "label": "App ID"
            },
            "invite_button_on": {
              "label": "Invitation button on"
            },
            "login_on": {
              "label": "Login on"
            },
            "ocl_api_key": {
              "label": "API key"
            },
            "privacy_policy_url": {
              "label": "Privacy policy URL"
            },
            "registration_on": {
              "label": "Registration on"
            },
            "stripe_publishable_key": {
              "label": "Publishable key"
            },
            "stripe_secret_key": {
              "label": "Secret key"
            },
            "terms_url": {
              "label": "Terms URL"
            },
            "top_back_button_on": "Show back button on top",
            "top_back_button_text": "Text",
            "top_back_button_url": "URL",
            "trial_calls_limit": {
              "label": "Call limit"
            },
            "users_section_on": {
              "label": "Users section on"
            },
            "wiki_url": {
              "label": "Wiki URL"
            }
          },
          "pricing": "Pricing",
          "tabs": {
            "blocked_subdomains": "Blocked subdomains",
            "external_services": "External services",
            "general": "General",
            "interface": "Interface settings",
            "subscription": "Subscription"
          },
          "trial": "Trial",
          "update_settings": "Update settings"
        }
      },
      "users": {
        "_user": {
          "workspaces": "Workspaces"
        }
      },
      "workspaces": {
        "_workspace": {
          "edit": "Edit"
        }
      }
    },
    "apis": {
      "show": {
        "add_call": "Add call",
        "auth": "Authentication header: Api-Key",
        "available_at": "The API is available at %{url}",
        "introduction": "Introduction"
      }
    },
    "calls": {
      "_form": {
        "description": {
          "label": "Description (optional)",
          "placeholder": "Describe the reason of call"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name of your call"
        },
        "password": {
          "label": "Password (optional)",
          "placeholder": "If you want to protect entrance with password enter your pass here"
        },
        "submit": "Start new call"
      },
      "index": {
        "_active": {
          "created_at": "%{date} ago",
          "created_by": "by %{name}",
          "edit": "Edit",
          "end": "End",
          "ended": "Ended",
          "go_to_chat_room": "Go to chat room",
          "live": "Live"
        },
        "_inner_bar": {
          "active": "Active",
          "ended": "Ended"
        },
        "_inner_layout": {
          "_no_active_calls": {
            "no_active_calls": "No active calls",
            "schedule": "Schedule",
            "start_now": "Start now"
          },
          "_tab_active_calls": {
            "actions": "Actions",
            "created_at": "Created date",
            "members": "Members",
            "name": "Name",
            "status": "Status"
          },
          "_tab_ended_calls": {
            "members": "Members",
            "name": "Name",
            "no_ended_calls": "No ended calls"
          }
        },
        "_page_title": {
          "current_plan": "Your current plan: %{count}/%{limit} free calls.",
          "schedule": "Schedule",
          "start_now": "Start now",
          "start_video_chat": "Start video chat"
        }
      },
      "new": {
        "start_new_call": "Start a new call"
      },
      "schedule": {
        "schedule_new_call": "Schedule new call"
      }
    },
    "devise": {
      "passwords": {
        "new": {
          "reset_password": "Reset your password",
          "send_instructions": "Send reset password instructions",
          "sign_in": "Cancel"
        }
      },
      "registrations": {
        "new": {
          "accept_terms": "Accept terms and conditions",
          "already_have_account": "Already have an account?",
          "create": "Create account",
          "create_account": "Create new account",
          "create_schedule_talk": "Create, Schedule, Talk",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "few_seconds": "It only takes a few seconds to create your account",
          "login_off": "Signing in is temporarily unavailable",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "private_video": "We offer a full private video-calls with use of Peer-to-peer technology",
          "registration_off": "Registration is temporarily unavailable",
          "sign_in": "Sign in"
        }
      },
      "sessions": {
        "new": {
          "build_room": "Build your video room",
          "create_account": "Create account",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "login_to_dashboard": "Login to dashboard",
          "no_account": "No account?",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "please_sign_in": "Please sign in to your account.",
          "recover_password": "Recover password",
          "send_instructions": "Send instructions",
          "sign_in": "Sign in",
          "sign_up": "Sign up now",
          "up_to": "Talk up to 10 people in a private Peer-to-peer connection"
        }
      }
    },
    "layouts": {
      "_app_header_right": {
        "logout": "Logout"
      },
      "admin": {
        "application": {
          "admins": "Admins",
          "api": "API",
          "calls": "Calls",
          "dashboard": "Dashboard",
          "settings": "Settings",
          "sign_out": "Sign out",
          "users": "Users",
          "workspaces": "Workspaces"
        }
      },
      "application": {
        "_app_header_right": {
          "add_workspace": "Add workspace",
          "calls_limit": "Calls: %{count}/%{limit}",
          "change_password": "Change password",
          "logout": "Logout",
          "profile": "Profile"
        },
        "_footer": {
          "copyright": "Copyright %{year} by Getreve",
          "policy": "Privacy policy",
          "terms": "Terms & Conditions"
        },
        "_horizontal_nav_menu": {
          "api": "API",
          "billing": "Billing",
          "calls": "Calls",
          "settings": "Settings",
          "users": "Users",
          "wiki": "Wiki"
        }
      },
      "wizard": {
        "logout": "Logout"
      }
    },
    "profiles": {
      "_password_form": {
        "password": {
          "label": "New password"
        }
      }
    },
    "rooms": {
      "_message_form": {
        "write_here_and_hit_enter": "Write here and hit enter to send..."
      },
      "_messages": {
        "history_not_displayed": "Due to security the history of this conversation will not be dislayed."
      },
      "_pending_room_member": {
        "accept": "Accept",
        "reject": "Reject"
      },
      "_video_room": {
        "reconnect": "Reconnect",
        "waiting_for_acceptance": "Waiting for acceptance"
      },
      "enter": {
        "chat_blocked": "This chat has been blocked",
        "chat_unavailable": "Chat unavailable",
        "enter_room": "Enter room",
        "welcome_to_room": "Welcome to room"
      },
      "show": {
        "powered_by": "Powered by Vli"
      }
    },
    "subscriptions": {
      "_form": {
        "calls_per_month": "%{count} calls"
      },
      "_incomplete": {
        "status": {
          "incomplete": "Incomplete"
        }
      },
      "show": {
        "_active": {
          "active_until": "<h5 class=\"mb-0\">Subscription will remain active until <strong>%{date}</strong>. After that date your limits will be downgraded to <strong>%{free_limit}</strong> calls per month. Are you sure?</h5>",
          "cancel": "Cancel subscription",
          "status": "Active"
        },
        "_canceled": {
          "status": "Canceled "
        },
        "_free": {
          "limit": "%{limit} calls <br> <small class='text-secondary'>per month</small>",
          "status": {
            "free": "Free"
          },
          "upgrade": "Upgrade limit"
        },
        "_incomplete": {
          "accept": "Click to accept payment",
          "complete_payment": "Complete your payment to activate subscription",
          "status": "Payment incomplete"
        },
        "status": {
          "cancel": "Cancel",
          "reactivate": "Reactivate",
          "upgrade": "Upgrade"
        }
      }
    },
    "users": {
      "_user": {
        "start_chat": "Start chat"
      },
      "index": {
        "_add": {
          "add": "Add"
        }
      }
    },
    "wizard": {
      "new": {
        "country": {
          "label": "Country",
          "placeholder": "Choose country..."
        },
        "currency": {
          "label": "Your currency",
          "placeholder": "Choose currency..."
        },
        "system_name": {
          "label": "Your business name",
          "placeholder": "Enter your business name..."
        },
        "time_zone": {
          "label": "Time zone",
          "placeholder": "Choose time zone..."
        },
        "your_business": "Tell us something about your business"
      },
      "show": {
        "choose_subdomain": "Choose your platform subdomain.",
        "own_address": "You will have your own dedicated address where people login to your video rooms.",
        "subdomain": {
          "label": "Your subdomain",
          "placeholder": "Enter subdomain..."
        }
      }
    }
  }
});
I18n.translations["hu"] = I18n.extend((I18n.translations["hu"] || {}), {
  "activerecord": {
    "attributes": {
      "room_member": {
        "entry_code": "Password",
        "name": "Name"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    },
    "models": {
      "user": {
        "one": "User",
        "other": "Users"
      }
    }
  },
  "common": {
    "are_you_sure": "Are you sure?",
    "cancel": "Cancel",
    "nothing_selected": "Nothing selected",
    "reset": "Reset password for",
    "submit": "Submit"
  },
  "controllers": {
    "admin": {
      "settings": {
        "create": {
          "notice": "Setting was successfully updated."
        }
      },
      "workspaces": {
        "update": {
          "saved": "Saved"
        }
      }
    },
    "workspaces": {
      "update": {
        "saved": "Settings saved."
      }
    }
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Second",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": "Not saved",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007fdecd8ecbd8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/rails-i18n-6.0.0/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    }
  },
  "javascript": {
    "controllers": {
      "subscriptions": {
        "calls_per_month": "%{count} calls",
        "subscribe": "Subscribe: %{price} / month"
      }
    }
  },
  "layouts": {
    "application": {
      "_app_header_right": {
        "change_password": "Change password",
        "profile": "Profile"
      }
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "#<Proc:0x00007fdecd8652c8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:26 (lambda)>",
      "ordinals": "#<Proc:0x00007fdecd8652f0@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:7 (lambda)>"
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "recaptcha": {
    "errors": {
      "recaptcha_unreachable": "Oops, we failed to validate your reCAPTCHA response. Please try again.",
      "verification_failed": "reCAPTCHA verification failed, please try again."
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "views": {
    "admin": {
      "settings": {
        "show": {
          "details": "Details",
          "fields": {
            "billing_section_on": {
              "label": "Billing section on"
            },
            "billings_off": {
              "label": "Billings off"
            },
            "bottom_back_button_on": "Show back button on bottom",
            "bottom_back_button_text": "Text",
            "bottom_back_button_url": "URL",
            "brand": {
              "label": "Brand name"
            },
            "brand_on": {
              "label": "Brand on"
            },
            "call_price": {
              "label": "Call price"
            },
            "calls_max_quantity": {
              "label": "Max calls quantity"
            },
            "calls_min_quantity": {
              "label": "Min calls quantity"
            },
            "free_calls_limit": {
              "label": "Free calls limit"
            },
            "intercom_app_id": {
              "label": "App ID"
            },
            "invite_button_on": {
              "label": "Invitation button on"
            },
            "login_on": {
              "label": "Login on"
            },
            "ocl_api_key": {
              "label": "API key"
            },
            "privacy_policy_url": {
              "label": "Privacy policy URL"
            },
            "registration_on": {
              "label": "Registration on"
            },
            "stripe_publishable_key": {
              "label": "Publishable key"
            },
            "stripe_secret_key": {
              "label": "Secret key"
            },
            "terms_url": {
              "label": "Terms URL"
            },
            "top_back_button_on": "Show back button on top",
            "top_back_button_text": "Text",
            "top_back_button_url": "URL",
            "trial_calls_limit": {
              "label": "Call limit"
            },
            "users_section_on": {
              "label": "Users section on"
            },
            "wiki_url": {
              "label": "Wiki URL"
            }
          },
          "pricing": "Pricing",
          "tabs": {
            "blocked_subdomains": "Blocked subdomains",
            "external_services": "External services",
            "general": "General",
            "interface": "Interface settings",
            "subscription": "Subscription"
          },
          "trial": "Trial",
          "update_settings": "Update settings"
        }
      },
      "users": {
        "_user": {
          "workspaces": "Workspaces"
        }
      },
      "workspaces": {
        "_workspace": {
          "edit": "Edit"
        }
      }
    },
    "apis": {
      "show": {
        "add_call": "Add call",
        "auth": "Authentication header: Api-Key",
        "available_at": "The API is available at %{url}",
        "introduction": "Introduction"
      }
    },
    "calls": {
      "_form": {
        "description": {
          "label": "Description (optional)",
          "placeholder": "Describe the reason of call"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name of your call"
        },
        "password": {
          "label": "Password (optional)",
          "placeholder": "If you want to protect entrance with password enter your pass here"
        },
        "submit": "Start new call"
      },
      "index": {
        "_active": {
          "created_at": "%{date} ago",
          "created_by": "by %{name}",
          "edit": "Edit",
          "end": "End",
          "ended": "Ended",
          "go_to_chat_room": "Go to chat room",
          "live": "Live"
        },
        "_inner_bar": {
          "active": "Active",
          "ended": "Ended"
        },
        "_inner_layout": {
          "_no_active_calls": {
            "no_active_calls": "No active calls",
            "schedule": "Schedule",
            "start_now": "Start now"
          },
          "_tab_active_calls": {
            "actions": "Actions",
            "created_at": "Created date",
            "members": "Members",
            "name": "Name",
            "status": "Status"
          },
          "_tab_ended_calls": {
            "members": "Members",
            "name": "Name",
            "no_ended_calls": "No ended calls"
          }
        },
        "_page_title": {
          "current_plan": "Your current plan: %{count}/%{limit} free calls.",
          "schedule": "Schedule",
          "start_now": "Start now",
          "start_video_chat": "Start video chat"
        }
      },
      "new": {
        "start_new_call": "Start a new call"
      },
      "schedule": {
        "schedule_new_call": "Schedule new call"
      }
    },
    "devise": {
      "passwords": {
        "new": {
          "reset_password": "Reset your password",
          "send_instructions": "Send reset password instructions",
          "sign_in": "Cancel"
        }
      },
      "registrations": {
        "new": {
          "accept_terms": "Accept terms and conditions",
          "already_have_account": "Already have an account?",
          "create": "Create account",
          "create_account": "Create new account",
          "create_schedule_talk": "Create, Schedule, Talk",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "few_seconds": "It only takes a few seconds to create your account",
          "login_off": "Signing in is temporarily unavailable",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "private_video": "We offer a full private video-calls with use of Peer-to-peer technology",
          "registration_off": "Registration is temporarily unavailable",
          "sign_in": "Sign in"
        }
      },
      "sessions": {
        "new": {
          "build_room": "Build your video room",
          "create_account": "Create account",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "login_to_dashboard": "Login to dashboard",
          "no_account": "No account?",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "please_sign_in": "Please sign in to your account.",
          "recover_password": "Recover password",
          "send_instructions": "Send instructions",
          "sign_in": "Sign in",
          "sign_up": "Sign up now",
          "up_to": "Talk up to 10 people in a private Peer-to-peer connection"
        }
      }
    },
    "layouts": {
      "_app_header_right": {
        "logout": "Logout"
      },
      "admin": {
        "application": {
          "admins": "Admins",
          "api": "API",
          "calls": "Calls",
          "dashboard": "Dashboard",
          "settings": "Settings",
          "sign_out": "Sign out",
          "users": "Users",
          "workspaces": "Workspaces"
        }
      },
      "application": {
        "_app_header_right": {
          "add_workspace": "Add workspace",
          "calls_limit": "Calls: %{count}/%{limit}",
          "change_password": "Change password",
          "logout": "Logout",
          "profile": "Profile"
        },
        "_footer": {
          "copyright": "Copyright %{year} by Getreve",
          "policy": "Privacy policy",
          "terms": "Terms & Conditions"
        },
        "_horizontal_nav_menu": {
          "api": "API",
          "billing": "Billing",
          "calls": "Calls",
          "settings": "Settings",
          "users": "Users",
          "wiki": "Wiki"
        }
      },
      "wizard": {
        "logout": "Logout"
      }
    },
    "profiles": {
      "_password_form": {
        "password": {
          "label": "New password"
        }
      }
    },
    "rooms": {
      "_message_form": {
        "write_here_and_hit_enter": "Write here and hit enter to send..."
      },
      "_messages": {
        "history_not_displayed": "Due to security the history of this conversation will not be dislayed."
      },
      "_pending_room_member": {
        "accept": "Accept",
        "reject": "Reject"
      },
      "_video_room": {
        "reconnect": "Reconnect",
        "waiting_for_acceptance": "Waiting for acceptance"
      },
      "enter": {
        "chat_blocked": "This chat has been blocked",
        "chat_unavailable": "Chat unavailable",
        "enter_room": "Enter room",
        "welcome_to_room": "Welcome to room"
      },
      "show": {
        "powered_by": "Powered by Vli"
      }
    },
    "subscriptions": {
      "_form": {
        "calls_per_month": "%{count} calls"
      },
      "_incomplete": {
        "status": {
          "incomplete": "Incomplete"
        }
      },
      "show": {
        "_active": {
          "active_until": "<h5 class=\"mb-0\">Subscription will remain active until <strong>%{date}</strong>. After that date your limits will be downgraded to <strong>%{free_limit}</strong> calls per month. Are you sure?</h5>",
          "cancel": "Cancel subscription",
          "status": "Active"
        },
        "_canceled": {
          "status": "Canceled "
        },
        "_free": {
          "limit": "%{limit} calls <br> <small class='text-secondary'>per month</small>",
          "status": {
            "free": "Free"
          },
          "upgrade": "Upgrade limit"
        },
        "_incomplete": {
          "accept": "Click to accept payment",
          "complete_payment": "Complete your payment to activate subscription",
          "status": "Payment incomplete"
        },
        "status": {
          "cancel": "Cancel",
          "reactivate": "Reactivate",
          "upgrade": "Upgrade"
        }
      }
    },
    "users": {
      "_user": {
        "start_chat": "Start chat"
      },
      "index": {
        "_add": {
          "add": "Add"
        }
      }
    },
    "wizard": {
      "new": {
        "country": {
          "label": "Country",
          "placeholder": "Choose country..."
        },
        "currency": {
          "label": "Your currency",
          "placeholder": "Choose currency..."
        },
        "system_name": {
          "label": "Your business name",
          "placeholder": "Enter your business name..."
        },
        "time_zone": {
          "label": "Time zone",
          "placeholder": "Choose time zone..."
        },
        "your_business": "Tell us something about your business"
      },
      "show": {
        "choose_subdomain": "Choose your platform subdomain.",
        "own_address": "You will have your own dedicated address where people login to your video rooms.",
        "subdomain": {
          "label": "Your subdomain",
          "placeholder": "Enter subdomain..."
        }
      }
    }
  }
});
I18n.translations["id"] = I18n.extend((I18n.translations["id"] || {}), {
  "activerecord": {
    "attributes": {
      "room_member": {
        "entry_code": "Password",
        "name": "Name"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    },
    "models": {
      "user": {
        "one": "User",
        "other": "Users"
      }
    }
  },
  "common": {
    "are_you_sure": "Are you sure?",
    "cancel": "Cancel",
    "nothing_selected": "Nothing selected",
    "reset": "Reset password for",
    "submit": "Submit"
  },
  "controllers": {
    "admin": {
      "settings": {
        "create": {
          "notice": "Setting was successfully updated."
        }
      },
      "workspaces": {
        "update": {
          "saved": "Saved"
        }
      }
    },
    "workspaces": {
      "update": {
        "saved": "Settings saved."
      }
    }
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Second",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": "Not saved",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007fdecd8ecbd8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/rails-i18n-6.0.0/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    }
  },
  "javascript": {
    "controllers": {
      "subscriptions": {
        "calls_per_month": "%{count} calls",
        "subscribe": "Subscribe: %{price} / month"
      }
    }
  },
  "layouts": {
    "application": {
      "_app_header_right": {
        "change_password": "Change password",
        "profile": "Profile"
      }
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "#<Proc:0x00007fdecd8652c8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:26 (lambda)>",
      "ordinals": "#<Proc:0x00007fdecd8652f0@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:7 (lambda)>"
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "recaptcha": {
    "errors": {
      "recaptcha_unreachable": "Oops, we failed to validate your reCAPTCHA response. Please try again.",
      "verification_failed": "reCAPTCHA verification failed, please try again."
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "views": {
    "admin": {
      "settings": {
        "show": {
          "details": "Details",
          "fields": {
            "billing_section_on": {
              "label": "Billing section on"
            },
            "billings_off": {
              "label": "Billings off"
            },
            "bottom_back_button_on": "Show back button on bottom",
            "bottom_back_button_text": "Text",
            "bottom_back_button_url": "URL",
            "brand": {
              "label": "Brand name"
            },
            "brand_on": {
              "label": "Brand on"
            },
            "call_price": {
              "label": "Call price"
            },
            "calls_max_quantity": {
              "label": "Max calls quantity"
            },
            "calls_min_quantity": {
              "label": "Min calls quantity"
            },
            "free_calls_limit": {
              "label": "Free calls limit"
            },
            "intercom_app_id": {
              "label": "App ID"
            },
            "invite_button_on": {
              "label": "Invitation button on"
            },
            "login_on": {
              "label": "Login on"
            },
            "ocl_api_key": {
              "label": "API key"
            },
            "privacy_policy_url": {
              "label": "Privacy policy URL"
            },
            "registration_on": {
              "label": "Registration on"
            },
            "stripe_publishable_key": {
              "label": "Publishable key"
            },
            "stripe_secret_key": {
              "label": "Secret key"
            },
            "terms_url": {
              "label": "Terms URL"
            },
            "top_back_button_on": "Show back button on top",
            "top_back_button_text": "Text",
            "top_back_button_url": "URL",
            "trial_calls_limit": {
              "label": "Call limit"
            },
            "users_section_on": {
              "label": "Users section on"
            },
            "wiki_url": {
              "label": "Wiki URL"
            }
          },
          "pricing": "Pricing",
          "tabs": {
            "blocked_subdomains": "Blocked subdomains",
            "external_services": "External services",
            "general": "General",
            "interface": "Interface settings",
            "subscription": "Subscription"
          },
          "trial": "Trial",
          "update_settings": "Update settings"
        }
      },
      "users": {
        "_user": {
          "workspaces": "Workspaces"
        }
      },
      "workspaces": {
        "_workspace": {
          "edit": "Edit"
        }
      }
    },
    "apis": {
      "show": {
        "add_call": "Add call",
        "auth": "Authentication header: Api-Key",
        "available_at": "The API is available at %{url}",
        "introduction": "Introduction"
      }
    },
    "calls": {
      "_form": {
        "description": {
          "label": "Description (optional)",
          "placeholder": "Describe the reason of call"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name of your call"
        },
        "password": {
          "label": "Password (optional)",
          "placeholder": "If you want to protect entrance with password enter your pass here"
        },
        "submit": "Start new call"
      },
      "index": {
        "_active": {
          "created_at": "%{date} ago",
          "created_by": "by %{name}",
          "edit": "Edit",
          "end": "End",
          "ended": "Ended",
          "go_to_chat_room": "Go to chat room",
          "live": "Live"
        },
        "_inner_bar": {
          "active": "Active",
          "ended": "Ended"
        },
        "_inner_layout": {
          "_no_active_calls": {
            "no_active_calls": "No active calls",
            "schedule": "Schedule",
            "start_now": "Start now"
          },
          "_tab_active_calls": {
            "actions": "Actions",
            "created_at": "Created date",
            "members": "Members",
            "name": "Name",
            "status": "Status"
          },
          "_tab_ended_calls": {
            "members": "Members",
            "name": "Name",
            "no_ended_calls": "No ended calls"
          }
        },
        "_page_title": {
          "current_plan": "Your current plan: %{count}/%{limit} free calls.",
          "schedule": "Schedule",
          "start_now": "Start now",
          "start_video_chat": "Start video chat"
        }
      },
      "new": {
        "start_new_call": "Start a new call"
      },
      "schedule": {
        "schedule_new_call": "Schedule new call"
      }
    },
    "devise": {
      "passwords": {
        "new": {
          "reset_password": "Reset your password",
          "send_instructions": "Send reset password instructions",
          "sign_in": "Cancel"
        }
      },
      "registrations": {
        "new": {
          "accept_terms": "Accept terms and conditions",
          "already_have_account": "Already have an account?",
          "create": "Create account",
          "create_account": "Create new account",
          "create_schedule_talk": "Create, Schedule, Talk",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "few_seconds": "It only takes a few seconds to create your account",
          "login_off": "Signing in is temporarily unavailable",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "private_video": "We offer a full private video-calls with use of Peer-to-peer technology",
          "registration_off": "Registration is temporarily unavailable",
          "sign_in": "Sign in"
        }
      },
      "sessions": {
        "new": {
          "build_room": "Build your video room",
          "create_account": "Create account",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "login_to_dashboard": "Login to dashboard",
          "no_account": "No account?",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "please_sign_in": "Please sign in to your account.",
          "recover_password": "Recover password",
          "send_instructions": "Send instructions",
          "sign_in": "Sign in",
          "sign_up": "Sign up now",
          "up_to": "Talk up to 10 people in a private Peer-to-peer connection"
        }
      }
    },
    "layouts": {
      "_app_header_right": {
        "logout": "Logout"
      },
      "admin": {
        "application": {
          "admins": "Admins",
          "api": "API",
          "calls": "Calls",
          "dashboard": "Dashboard",
          "settings": "Settings",
          "sign_out": "Sign out",
          "users": "Users",
          "workspaces": "Workspaces"
        }
      },
      "application": {
        "_app_header_right": {
          "add_workspace": "Add workspace",
          "calls_limit": "Calls: %{count}/%{limit}",
          "change_password": "Change password",
          "logout": "Logout",
          "profile": "Profile"
        },
        "_footer": {
          "copyright": "Copyright %{year} by Getreve",
          "policy": "Privacy policy",
          "terms": "Terms & Conditions"
        },
        "_horizontal_nav_menu": {
          "api": "API",
          "billing": "Billing",
          "calls": "Calls",
          "settings": "Settings",
          "users": "Users",
          "wiki": "Wiki"
        }
      },
      "wizard": {
        "logout": "Logout"
      }
    },
    "profiles": {
      "_password_form": {
        "password": {
          "label": "New password"
        }
      }
    },
    "rooms": {
      "_message_form": {
        "write_here_and_hit_enter": "Write here and hit enter to send..."
      },
      "_messages": {
        "history_not_displayed": "Due to security the history of this conversation will not be dislayed."
      },
      "_pending_room_member": {
        "accept": "Accept",
        "reject": "Reject"
      },
      "_video_room": {
        "reconnect": "Reconnect",
        "waiting_for_acceptance": "Waiting for acceptance"
      },
      "enter": {
        "chat_blocked": "This chat has been blocked",
        "chat_unavailable": "Chat unavailable",
        "enter_room": "Enter room",
        "welcome_to_room": "Welcome to room"
      },
      "show": {
        "powered_by": "Powered by Vli"
      }
    },
    "subscriptions": {
      "_form": {
        "calls_per_month": "%{count} calls"
      },
      "_incomplete": {
        "status": {
          "incomplete": "Incomplete"
        }
      },
      "show": {
        "_active": {
          "active_until": "<h5 class=\"mb-0\">Subscription will remain active until <strong>%{date}</strong>. After that date your limits will be downgraded to <strong>%{free_limit}</strong> calls per month. Are you sure?</h5>",
          "cancel": "Cancel subscription",
          "status": "Active"
        },
        "_canceled": {
          "status": "Canceled "
        },
        "_free": {
          "limit": "%{limit} calls <br> <small class='text-secondary'>per month</small>",
          "status": {
            "free": "Free"
          },
          "upgrade": "Upgrade limit"
        },
        "_incomplete": {
          "accept": "Click to accept payment",
          "complete_payment": "Complete your payment to activate subscription",
          "status": "Payment incomplete"
        },
        "status": {
          "cancel": "Cancel",
          "reactivate": "Reactivate",
          "upgrade": "Upgrade"
        }
      }
    },
    "users": {
      "_user": {
        "start_chat": "Start chat"
      },
      "index": {
        "_add": {
          "add": "Add"
        }
      }
    },
    "wizard": {
      "new": {
        "country": {
          "label": "Country",
          "placeholder": "Choose country..."
        },
        "currency": {
          "label": "Your currency",
          "placeholder": "Choose currency..."
        },
        "system_name": {
          "label": "Your business name",
          "placeholder": "Enter your business name..."
        },
        "time_zone": {
          "label": "Time zone",
          "placeholder": "Choose time zone..."
        },
        "your_business": "Tell us something about your business"
      },
      "show": {
        "choose_subdomain": "Choose your platform subdomain.",
        "own_address": "You will have your own dedicated address where people login to your video rooms.",
        "subdomain": {
          "label": "Your subdomain",
          "placeholder": "Enter subdomain..."
        }
      }
    }
  }
});
I18n.translations["it"] = I18n.extend((I18n.translations["it"] || {}), {
  "activerecord": {
    "attributes": {
      "room_member": {
        "entry_code": "Password",
        "name": "Name"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    },
    "models": {
      "user": {
        "one": "User",
        "other": "Users"
      }
    }
  },
  "common": {
    "are_you_sure": "Are you sure?",
    "cancel": "Cancel",
    "nothing_selected": "Nothing selected",
    "reset": "Reset password for",
    "submit": "Submit"
  },
  "controllers": {
    "admin": {
      "settings": {
        "create": {
          "notice": "Setting was successfully updated."
        }
      },
      "workspaces": {
        "update": {
          "saved": "Saved"
        }
      }
    },
    "workspaces": {
      "update": {
        "saved": "Settings saved."
      }
    }
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Second",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": "Not saved",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007fdecd8ecbd8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/rails-i18n-6.0.0/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    }
  },
  "javascript": {
    "controllers": {
      "subscriptions": {
        "calls_per_month": "%{count} calls",
        "subscribe": "Subscribe: %{price} / month"
      }
    }
  },
  "layouts": {
    "application": {
      "_app_header_right": {
        "change_password": "Change password",
        "profile": "Profile"
      }
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "#<Proc:0x00007fdecd8652c8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:26 (lambda)>",
      "ordinals": "#<Proc:0x00007fdecd8652f0@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:7 (lambda)>"
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "recaptcha": {
    "errors": {
      "recaptcha_unreachable": "Oops, we failed to validate your reCAPTCHA response. Please try again.",
      "verification_failed": "reCAPTCHA verification failed, please try again."
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "views": {
    "admin": {
      "settings": {
        "show": {
          "details": "Details",
          "fields": {
            "billing_section_on": {
              "label": "Billing section on"
            },
            "billings_off": {
              "label": "Billings off"
            },
            "bottom_back_button_on": "Show back button on bottom",
            "bottom_back_button_text": "Text",
            "bottom_back_button_url": "URL",
            "brand": {
              "label": "Brand name"
            },
            "brand_on": {
              "label": "Brand on"
            },
            "call_price": {
              "label": "Call price"
            },
            "calls_max_quantity": {
              "label": "Max calls quantity"
            },
            "calls_min_quantity": {
              "label": "Min calls quantity"
            },
            "free_calls_limit": {
              "label": "Free calls limit"
            },
            "intercom_app_id": {
              "label": "App ID"
            },
            "invite_button_on": {
              "label": "Invitation button on"
            },
            "login_on": {
              "label": "Login on"
            },
            "ocl_api_key": {
              "label": "API key"
            },
            "privacy_policy_url": {
              "label": "Privacy policy URL"
            },
            "registration_on": {
              "label": "Registration on"
            },
            "stripe_publishable_key": {
              "label": "Publishable key"
            },
            "stripe_secret_key": {
              "label": "Secret key"
            },
            "terms_url": {
              "label": "Terms URL"
            },
            "top_back_button_on": "Show back button on top",
            "top_back_button_text": "Text",
            "top_back_button_url": "URL",
            "trial_calls_limit": {
              "label": "Call limit"
            },
            "users_section_on": {
              "label": "Users section on"
            },
            "wiki_url": {
              "label": "Wiki URL"
            }
          },
          "pricing": "Pricing",
          "tabs": {
            "blocked_subdomains": "Blocked subdomains",
            "external_services": "External services",
            "general": "General",
            "interface": "Interface settings",
            "subscription": "Subscription"
          },
          "trial": "Trial",
          "update_settings": "Update settings"
        }
      },
      "users": {
        "_user": {
          "workspaces": "Workspaces"
        }
      },
      "workspaces": {
        "_workspace": {
          "edit": "Edit"
        }
      }
    },
    "apis": {
      "show": {
        "add_call": "Add call",
        "auth": "Authentication header: Api-Key",
        "available_at": "The API is available at %{url}",
        "introduction": "Introduction"
      }
    },
    "calls": {
      "_form": {
        "description": {
          "label": "Description (optional)",
          "placeholder": "Describe the reason of call"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name of your call"
        },
        "password": {
          "label": "Password (optional)",
          "placeholder": "If you want to protect entrance with password enter your pass here"
        },
        "submit": "Start new call"
      },
      "index": {
        "_active": {
          "created_at": "%{date} ago",
          "created_by": "by %{name}",
          "edit": "Edit",
          "end": "End",
          "ended": "Ended",
          "go_to_chat_room": "Go to chat room",
          "live": "Live"
        },
        "_inner_bar": {
          "active": "Active",
          "ended": "Ended"
        },
        "_inner_layout": {
          "_no_active_calls": {
            "no_active_calls": "No active calls",
            "schedule": "Schedule",
            "start_now": "Start now"
          },
          "_tab_active_calls": {
            "actions": "Actions",
            "created_at": "Created date",
            "members": "Members",
            "name": "Name",
            "status": "Status"
          },
          "_tab_ended_calls": {
            "members": "Members",
            "name": "Name",
            "no_ended_calls": "No ended calls"
          }
        },
        "_page_title": {
          "current_plan": "Your current plan: %{count}/%{limit} free calls.",
          "schedule": "Schedule",
          "start_now": "Start now",
          "start_video_chat": "Start video chat"
        }
      },
      "new": {
        "start_new_call": "Start a new call"
      },
      "schedule": {
        "schedule_new_call": "Schedule new call"
      }
    },
    "devise": {
      "passwords": {
        "new": {
          "reset_password": "Reset your password",
          "send_instructions": "Send reset password instructions",
          "sign_in": "Cancel"
        }
      },
      "registrations": {
        "new": {
          "accept_terms": "Accept terms and conditions",
          "already_have_account": "Already have an account?",
          "create": "Create account",
          "create_account": "Create new account",
          "create_schedule_talk": "Create, Schedule, Talk",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "few_seconds": "It only takes a few seconds to create your account",
          "login_off": "Signing in is temporarily unavailable",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "private_video": "We offer a full private video-calls with use of Peer-to-peer technology",
          "registration_off": "Registration is temporarily unavailable",
          "sign_in": "Sign in"
        }
      },
      "sessions": {
        "new": {
          "build_room": "Build your video room",
          "create_account": "Create account",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "login_to_dashboard": "Login to dashboard",
          "no_account": "No account?",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "please_sign_in": "Please sign in to your account.",
          "recover_password": "Recover password",
          "send_instructions": "Send instructions",
          "sign_in": "Sign in",
          "sign_up": "Sign up now",
          "up_to": "Talk up to 10 people in a private Peer-to-peer connection"
        }
      }
    },
    "layouts": {
      "_app_header_right": {
        "logout": "Logout"
      },
      "admin": {
        "application": {
          "admins": "Admins",
          "api": "API",
          "calls": "Calls",
          "dashboard": "Dashboard",
          "settings": "Settings",
          "sign_out": "Sign out",
          "users": "Users",
          "workspaces": "Workspaces"
        }
      },
      "application": {
        "_app_header_right": {
          "add_workspace": "Add workspace",
          "calls_limit": "Calls: %{count}/%{limit}",
          "change_password": "Change password",
          "logout": "Logout",
          "profile": "Profile"
        },
        "_footer": {
          "copyright": "Copyright %{year} by Getreve",
          "policy": "Privacy policy",
          "terms": "Terms & Conditions"
        },
        "_horizontal_nav_menu": {
          "api": "API",
          "billing": "Billing",
          "calls": "Calls",
          "settings": "Settings",
          "users": "Users",
          "wiki": "Wiki"
        }
      },
      "wizard": {
        "logout": "Logout"
      }
    },
    "profiles": {
      "_password_form": {
        "password": {
          "label": "New password"
        }
      }
    },
    "rooms": {
      "_message_form": {
        "write_here_and_hit_enter": "Write here and hit enter to send..."
      },
      "_messages": {
        "history_not_displayed": "Due to security the history of this conversation will not be dislayed."
      },
      "_pending_room_member": {
        "accept": "Accept",
        "reject": "Reject"
      },
      "_video_room": {
        "reconnect": "Reconnect",
        "waiting_for_acceptance": "Waiting for acceptance"
      },
      "enter": {
        "chat_blocked": "This chat has been blocked",
        "chat_unavailable": "Chat unavailable",
        "enter_room": "Enter room",
        "welcome_to_room": "Welcome to room"
      },
      "show": {
        "powered_by": "Powered by Vli"
      }
    },
    "subscriptions": {
      "_form": {
        "calls_per_month": "%{count} calls"
      },
      "_incomplete": {
        "status": {
          "incomplete": "Incomplete"
        }
      },
      "show": {
        "_active": {
          "active_until": "<h5 class=\"mb-0\">Subscription will remain active until <strong>%{date}</strong>. After that date your limits will be downgraded to <strong>%{free_limit}</strong> calls per month. Are you sure?</h5>",
          "cancel": "Cancel subscription",
          "status": "Active"
        },
        "_canceled": {
          "status": "Canceled "
        },
        "_free": {
          "limit": "%{limit} calls <br> <small class='text-secondary'>per month</small>",
          "status": {
            "free": "Free"
          },
          "upgrade": "Upgrade limit"
        },
        "_incomplete": {
          "accept": "Click to accept payment",
          "complete_payment": "Complete your payment to activate subscription",
          "status": "Payment incomplete"
        },
        "status": {
          "cancel": "Cancel",
          "reactivate": "Reactivate",
          "upgrade": "Upgrade"
        }
      }
    },
    "users": {
      "_user": {
        "start_chat": "Start chat"
      },
      "index": {
        "_add": {
          "add": "Add"
        }
      }
    },
    "wizard": {
      "new": {
        "country": {
          "label": "Country",
          "placeholder": "Choose country..."
        },
        "currency": {
          "label": "Your currency",
          "placeholder": "Choose currency..."
        },
        "system_name": {
          "label": "Your business name",
          "placeholder": "Enter your business name..."
        },
        "time_zone": {
          "label": "Time zone",
          "placeholder": "Choose time zone..."
        },
        "your_business": "Tell us something about your business"
      },
      "show": {
        "choose_subdomain": "Choose your platform subdomain.",
        "own_address": "You will have your own dedicated address where people login to your video rooms.",
        "subdomain": {
          "label": "Your subdomain",
          "placeholder": "Enter subdomain..."
        }
      }
    }
  }
});
I18n.translations["ja"] = I18n.extend((I18n.translations["ja"] || {}), {
  "activerecord": {
    "attributes": {
      "room_member": {
        "entry_code": "Password",
        "name": "Name"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    },
    "models": {
      "user": {
        "one": "User",
        "other": "Users"
      }
    }
  },
  "common": {
    "are_you_sure": "Are you sure?",
    "cancel": "Cancel",
    "nothing_selected": "Nothing selected",
    "reset": "Reset password for",
    "submit": "Submit"
  },
  "controllers": {
    "admin": {
      "settings": {
        "create": {
          "notice": "Setting was successfully updated."
        }
      },
      "workspaces": {
        "update": {
          "saved": "Saved"
        }
      }
    },
    "workspaces": {
      "update": {
        "saved": "Settings saved."
      }
    }
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Second",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": "Not saved",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007fdecd8ecbd8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/rails-i18n-6.0.0/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    }
  },
  "javascript": {
    "controllers": {
      "subscriptions": {
        "calls_per_month": "%{count} calls",
        "subscribe": "Subscribe: %{price} / month"
      }
    }
  },
  "layouts": {
    "application": {
      "_app_header_right": {
        "change_password": "Change password",
        "profile": "Profile"
      }
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "#<Proc:0x00007fdecd8652c8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:26 (lambda)>",
      "ordinals": "#<Proc:0x00007fdecd8652f0@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:7 (lambda)>"
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "recaptcha": {
    "errors": {
      "recaptcha_unreachable": "Oops, we failed to validate your reCAPTCHA response. Please try again.",
      "verification_failed": "reCAPTCHA verification failed, please try again."
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "views": {
    "admin": {
      "settings": {
        "show": {
          "details": "Details",
          "fields": {
            "billing_section_on": {
              "label": "Billing section on"
            },
            "billings_off": {
              "label": "Billings off"
            },
            "bottom_back_button_on": "Show back button on bottom",
            "bottom_back_button_text": "Text",
            "bottom_back_button_url": "URL",
            "brand": {
              "label": "Brand name"
            },
            "brand_on": {
              "label": "Brand on"
            },
            "call_price": {
              "label": "Call price"
            },
            "calls_max_quantity": {
              "label": "Max calls quantity"
            },
            "calls_min_quantity": {
              "label": "Min calls quantity"
            },
            "free_calls_limit": {
              "label": "Free calls limit"
            },
            "intercom_app_id": {
              "label": "App ID"
            },
            "invite_button_on": {
              "label": "Invitation button on"
            },
            "login_on": {
              "label": "Login on"
            },
            "ocl_api_key": {
              "label": "API key"
            },
            "privacy_policy_url": {
              "label": "Privacy policy URL"
            },
            "registration_on": {
              "label": "Registration on"
            },
            "stripe_publishable_key": {
              "label": "Publishable key"
            },
            "stripe_secret_key": {
              "label": "Secret key"
            },
            "terms_url": {
              "label": "Terms URL"
            },
            "top_back_button_on": "Show back button on top",
            "top_back_button_text": "Text",
            "top_back_button_url": "URL",
            "trial_calls_limit": {
              "label": "Call limit"
            },
            "users_section_on": {
              "label": "Users section on"
            },
            "wiki_url": {
              "label": "Wiki URL"
            }
          },
          "pricing": "Pricing",
          "tabs": {
            "blocked_subdomains": "Blocked subdomains",
            "external_services": "External services",
            "general": "General",
            "interface": "Interface settings",
            "subscription": "Subscription"
          },
          "trial": "Trial",
          "update_settings": "Update settings"
        }
      },
      "users": {
        "_user": {
          "workspaces": "Workspaces"
        }
      },
      "workspaces": {
        "_workspace": {
          "edit": "Edit"
        }
      }
    },
    "apis": {
      "show": {
        "add_call": "Add call",
        "auth": "Authentication header: Api-Key",
        "available_at": "The API is available at %{url}",
        "introduction": "Introduction"
      }
    },
    "calls": {
      "_form": {
        "description": {
          "label": "Description (optional)",
          "placeholder": "Describe the reason of call"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name of your call"
        },
        "password": {
          "label": "Password (optional)",
          "placeholder": "If you want to protect entrance with password enter your pass here"
        },
        "submit": "Start new call"
      },
      "index": {
        "_active": {
          "created_at": "%{date} ago",
          "created_by": "by %{name}",
          "edit": "Edit",
          "end": "End",
          "ended": "Ended",
          "go_to_chat_room": "Go to chat room",
          "live": "Live"
        },
        "_inner_bar": {
          "active": "Active",
          "ended": "Ended"
        },
        "_inner_layout": {
          "_no_active_calls": {
            "no_active_calls": "No active calls",
            "schedule": "Schedule",
            "start_now": "Start now"
          },
          "_tab_active_calls": {
            "actions": "Actions",
            "created_at": "Created date",
            "members": "Members",
            "name": "Name",
            "status": "Status"
          },
          "_tab_ended_calls": {
            "members": "Members",
            "name": "Name",
            "no_ended_calls": "No ended calls"
          }
        },
        "_page_title": {
          "current_plan": "Your current plan: %{count}/%{limit} free calls.",
          "schedule": "Schedule",
          "start_now": "Start now",
          "start_video_chat": "Start video chat"
        }
      },
      "new": {
        "start_new_call": "Start a new call"
      },
      "schedule": {
        "schedule_new_call": "Schedule new call"
      }
    },
    "devise": {
      "passwords": {
        "new": {
          "reset_password": "Reset your password",
          "send_instructions": "Send reset password instructions",
          "sign_in": "Cancel"
        }
      },
      "registrations": {
        "new": {
          "accept_terms": "Accept terms and conditions",
          "already_have_account": "Already have an account?",
          "create": "Create account",
          "create_account": "Create new account",
          "create_schedule_talk": "Create, Schedule, Talk",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "few_seconds": "It only takes a few seconds to create your account",
          "login_off": "Signing in is temporarily unavailable",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "private_video": "We offer a full private video-calls with use of Peer-to-peer technology",
          "registration_off": "Registration is temporarily unavailable",
          "sign_in": "Sign in"
        }
      },
      "sessions": {
        "new": {
          "build_room": "Build your video room",
          "create_account": "Create account",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "login_to_dashboard": "Login to dashboard",
          "no_account": "No account?",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "please_sign_in": "Please sign in to your account.",
          "recover_password": "Recover password",
          "send_instructions": "Send instructions",
          "sign_in": "Sign in",
          "sign_up": "Sign up now",
          "up_to": "Talk up to 10 people in a private Peer-to-peer connection"
        }
      }
    },
    "layouts": {
      "_app_header_right": {
        "logout": "Logout"
      },
      "admin": {
        "application": {
          "admins": "Admins",
          "api": "API",
          "calls": "Calls",
          "dashboard": "Dashboard",
          "settings": "Settings",
          "sign_out": "Sign out",
          "users": "Users",
          "workspaces": "Workspaces"
        }
      },
      "application": {
        "_app_header_right": {
          "add_workspace": "Add workspace",
          "calls_limit": "Calls: %{count}/%{limit}",
          "change_password": "Change password",
          "logout": "Logout",
          "profile": "Profile"
        },
        "_footer": {
          "copyright": "Copyright %{year} by Getreve",
          "policy": "Privacy policy",
          "terms": "Terms & Conditions"
        },
        "_horizontal_nav_menu": {
          "api": "API",
          "billing": "Billing",
          "calls": "Calls",
          "settings": "Settings",
          "users": "Users",
          "wiki": "Wiki"
        }
      },
      "wizard": {
        "logout": "Logout"
      }
    },
    "profiles": {
      "_password_form": {
        "password": {
          "label": "New password"
        }
      }
    },
    "rooms": {
      "_message_form": {
        "write_here_and_hit_enter": "Write here and hit enter to send..."
      },
      "_messages": {
        "history_not_displayed": "Due to security the history of this conversation will not be dislayed."
      },
      "_pending_room_member": {
        "accept": "Accept",
        "reject": "Reject"
      },
      "_video_room": {
        "reconnect": "Reconnect",
        "waiting_for_acceptance": "Waiting for acceptance"
      },
      "enter": {
        "chat_blocked": "This chat has been blocked",
        "chat_unavailable": "Chat unavailable",
        "enter_room": "Enter room",
        "welcome_to_room": "Welcome to room"
      },
      "show": {
        "powered_by": "Powered by Vli"
      }
    },
    "subscriptions": {
      "_form": {
        "calls_per_month": "%{count} calls"
      },
      "_incomplete": {
        "status": {
          "incomplete": "Incomplete"
        }
      },
      "show": {
        "_active": {
          "active_until": "<h5 class=\"mb-0\">Subscription will remain active until <strong>%{date}</strong>. After that date your limits will be downgraded to <strong>%{free_limit}</strong> calls per month. Are you sure?</h5>",
          "cancel": "Cancel subscription",
          "status": "Active"
        },
        "_canceled": {
          "status": "Canceled "
        },
        "_free": {
          "limit": "%{limit} calls <br> <small class='text-secondary'>per month</small>",
          "status": {
            "free": "Free"
          },
          "upgrade": "Upgrade limit"
        },
        "_incomplete": {
          "accept": "Click to accept payment",
          "complete_payment": "Complete your payment to activate subscription",
          "status": "Payment incomplete"
        },
        "status": {
          "cancel": "Cancel",
          "reactivate": "Reactivate",
          "upgrade": "Upgrade"
        }
      }
    },
    "users": {
      "_user": {
        "start_chat": "Start chat"
      },
      "index": {
        "_add": {
          "add": "Add"
        }
      }
    },
    "wizard": {
      "new": {
        "country": {
          "label": "Country",
          "placeholder": "Choose country..."
        },
        "currency": {
          "label": "Your currency",
          "placeholder": "Choose currency..."
        },
        "system_name": {
          "label": "Your business name",
          "placeholder": "Enter your business name..."
        },
        "time_zone": {
          "label": "Time zone",
          "placeholder": "Choose time zone..."
        },
        "your_business": "Tell us something about your business"
      },
      "show": {
        "choose_subdomain": "Choose your platform subdomain.",
        "own_address": "You will have your own dedicated address where people login to your video rooms.",
        "subdomain": {
          "label": "Your subdomain",
          "placeholder": "Enter subdomain..."
        }
      }
    }
  }
});
I18n.translations["ko"] = I18n.extend((I18n.translations["ko"] || {}), {
  "activerecord": {
    "attributes": {
      "room_member": {
        "entry_code": "Password",
        "name": "Name"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    },
    "models": {
      "user": {
        "one": "User",
        "other": "Users"
      }
    }
  },
  "common": {
    "are_you_sure": "Are you sure?",
    "cancel": "Cancel",
    "nothing_selected": "Nothing selected",
    "reset": "Reset password for",
    "submit": "Submit"
  },
  "controllers": {
    "admin": {
      "settings": {
        "create": {
          "notice": "Setting was successfully updated."
        }
      },
      "workspaces": {
        "update": {
          "saved": "Saved"
        }
      }
    },
    "workspaces": {
      "update": {
        "saved": "Settings saved."
      }
    }
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Second",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": "Not saved",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007fdecd8ecbd8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/rails-i18n-6.0.0/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    }
  },
  "javascript": {
    "controllers": {
      "subscriptions": {
        "calls_per_month": "%{count} calls",
        "subscribe": "Subscribe: %{price} / month"
      }
    }
  },
  "layouts": {
    "application": {
      "_app_header_right": {
        "change_password": "Change password",
        "profile": "Profile"
      }
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "#<Proc:0x00007fdecd8652c8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:26 (lambda)>",
      "ordinals": "#<Proc:0x00007fdecd8652f0@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:7 (lambda)>"
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "recaptcha": {
    "errors": {
      "recaptcha_unreachable": "Oops, we failed to validate your reCAPTCHA response. Please try again.",
      "verification_failed": "reCAPTCHA verification failed, please try again."
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "views": {
    "admin": {
      "settings": {
        "show": {
          "details": "Details",
          "fields": {
            "billing_section_on": {
              "label": "Billing section on"
            },
            "billings_off": {
              "label": "Billings off"
            },
            "bottom_back_button_on": "Show back button on bottom",
            "bottom_back_button_text": "Text",
            "bottom_back_button_url": "URL",
            "brand": {
              "label": "Brand name"
            },
            "brand_on": {
              "label": "Brand on"
            },
            "call_price": {
              "label": "Call price"
            },
            "calls_max_quantity": {
              "label": "Max calls quantity"
            },
            "calls_min_quantity": {
              "label": "Min calls quantity"
            },
            "free_calls_limit": {
              "label": "Free calls limit"
            },
            "intercom_app_id": {
              "label": "App ID"
            },
            "invite_button_on": {
              "label": "Invitation button on"
            },
            "login_on": {
              "label": "Login on"
            },
            "ocl_api_key": {
              "label": "API key"
            },
            "privacy_policy_url": {
              "label": "Privacy policy URL"
            },
            "registration_on": {
              "label": "Registration on"
            },
            "stripe_publishable_key": {
              "label": "Publishable key"
            },
            "stripe_secret_key": {
              "label": "Secret key"
            },
            "terms_url": {
              "label": "Terms URL"
            },
            "top_back_button_on": "Show back button on top",
            "top_back_button_text": "Text",
            "top_back_button_url": "URL",
            "trial_calls_limit": {
              "label": "Call limit"
            },
            "users_section_on": {
              "label": "Users section on"
            },
            "wiki_url": {
              "label": "Wiki URL"
            }
          },
          "pricing": "Pricing",
          "tabs": {
            "blocked_subdomains": "Blocked subdomains",
            "external_services": "External services",
            "general": "General",
            "interface": "Interface settings",
            "subscription": "Subscription"
          },
          "trial": "Trial",
          "update_settings": "Update settings"
        }
      },
      "users": {
        "_user": {
          "workspaces": "Workspaces"
        }
      },
      "workspaces": {
        "_workspace": {
          "edit": "Edit"
        }
      }
    },
    "apis": {
      "show": {
        "add_call": "Add call",
        "auth": "Authentication header: Api-Key",
        "available_at": "The API is available at %{url}",
        "introduction": "Introduction"
      }
    },
    "calls": {
      "_form": {
        "description": {
          "label": "Description (optional)",
          "placeholder": "Describe the reason of call"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name of your call"
        },
        "password": {
          "label": "Password (optional)",
          "placeholder": "If you want to protect entrance with password enter your pass here"
        },
        "submit": "Start new call"
      },
      "index": {
        "_active": {
          "created_at": "%{date} ago",
          "created_by": "by %{name}",
          "edit": "Edit",
          "end": "End",
          "ended": "Ended",
          "go_to_chat_room": "Go to chat room",
          "live": "Live"
        },
        "_inner_bar": {
          "active": "Active",
          "ended": "Ended"
        },
        "_inner_layout": {
          "_no_active_calls": {
            "no_active_calls": "No active calls",
            "schedule": "Schedule",
            "start_now": "Start now"
          },
          "_tab_active_calls": {
            "actions": "Actions",
            "created_at": "Created date",
            "members": "Members",
            "name": "Name",
            "status": "Status"
          },
          "_tab_ended_calls": {
            "members": "Members",
            "name": "Name",
            "no_ended_calls": "No ended calls"
          }
        },
        "_page_title": {
          "current_plan": "Your current plan: %{count}/%{limit} free calls.",
          "schedule": "Schedule",
          "start_now": "Start now",
          "start_video_chat": "Start video chat"
        }
      },
      "new": {
        "start_new_call": "Start a new call"
      },
      "schedule": {
        "schedule_new_call": "Schedule new call"
      }
    },
    "devise": {
      "passwords": {
        "new": {
          "reset_password": "Reset your password",
          "send_instructions": "Send reset password instructions",
          "sign_in": "Cancel"
        }
      },
      "registrations": {
        "new": {
          "accept_terms": "Accept terms and conditions",
          "already_have_account": "Already have an account?",
          "create": "Create account",
          "create_account": "Create new account",
          "create_schedule_talk": "Create, Schedule, Talk",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "few_seconds": "It only takes a few seconds to create your account",
          "login_off": "Signing in is temporarily unavailable",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "private_video": "We offer a full private video-calls with use of Peer-to-peer technology",
          "registration_off": "Registration is temporarily unavailable",
          "sign_in": "Sign in"
        }
      },
      "sessions": {
        "new": {
          "build_room": "Build your video room",
          "create_account": "Create account",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "login_to_dashboard": "Login to dashboard",
          "no_account": "No account?",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "please_sign_in": "Please sign in to your account.",
          "recover_password": "Recover password",
          "send_instructions": "Send instructions",
          "sign_in": "Sign in",
          "sign_up": "Sign up now",
          "up_to": "Talk up to 10 people in a private Peer-to-peer connection"
        }
      }
    },
    "layouts": {
      "_app_header_right": {
        "logout": "Logout"
      },
      "admin": {
        "application": {
          "admins": "Admins",
          "api": "API",
          "calls": "Calls",
          "dashboard": "Dashboard",
          "settings": "Settings",
          "sign_out": "Sign out",
          "users": "Users",
          "workspaces": "Workspaces"
        }
      },
      "application": {
        "_app_header_right": {
          "add_workspace": "Add workspace",
          "calls_limit": "Calls: %{count}/%{limit}",
          "change_password": "Change password",
          "logout": "Logout",
          "profile": "Profile"
        },
        "_footer": {
          "copyright": "Copyright %{year} by Getreve",
          "policy": "Privacy policy",
          "terms": "Terms & Conditions"
        },
        "_horizontal_nav_menu": {
          "api": "API",
          "billing": "Billing",
          "calls": "Calls",
          "settings": "Settings",
          "users": "Users",
          "wiki": "Wiki"
        }
      },
      "wizard": {
        "logout": "Logout"
      }
    },
    "profiles": {
      "_password_form": {
        "password": {
          "label": "New password"
        }
      }
    },
    "rooms": {
      "_message_form": {
        "write_here_and_hit_enter": "Write here and hit enter to send..."
      },
      "_messages": {
        "history_not_displayed": "Due to security the history of this conversation will not be dislayed."
      },
      "_pending_room_member": {
        "accept": "Accept",
        "reject": "Reject"
      },
      "_video_room": {
        "reconnect": "Reconnect",
        "waiting_for_acceptance": "Waiting for acceptance"
      },
      "enter": {
        "chat_blocked": "This chat has been blocked",
        "chat_unavailable": "Chat unavailable",
        "enter_room": "Enter room",
        "welcome_to_room": "Welcome to room"
      },
      "show": {
        "powered_by": "Powered by Vli"
      }
    },
    "subscriptions": {
      "_form": {
        "calls_per_month": "%{count} calls"
      },
      "_incomplete": {
        "status": {
          "incomplete": "Incomplete"
        }
      },
      "show": {
        "_active": {
          "active_until": "<h5 class=\"mb-0\">Subscription will remain active until <strong>%{date}</strong>. After that date your limits will be downgraded to <strong>%{free_limit}</strong> calls per month. Are you sure?</h5>",
          "cancel": "Cancel subscription",
          "status": "Active"
        },
        "_canceled": {
          "status": "Canceled "
        },
        "_free": {
          "limit": "%{limit} calls <br> <small class='text-secondary'>per month</small>",
          "status": {
            "free": "Free"
          },
          "upgrade": "Upgrade limit"
        },
        "_incomplete": {
          "accept": "Click to accept payment",
          "complete_payment": "Complete your payment to activate subscription",
          "status": "Payment incomplete"
        },
        "status": {
          "cancel": "Cancel",
          "reactivate": "Reactivate",
          "upgrade": "Upgrade"
        }
      }
    },
    "users": {
      "_user": {
        "start_chat": "Start chat"
      },
      "index": {
        "_add": {
          "add": "Add"
        }
      }
    },
    "wizard": {
      "new": {
        "country": {
          "label": "Country",
          "placeholder": "Choose country..."
        },
        "currency": {
          "label": "Your currency",
          "placeholder": "Choose currency..."
        },
        "system_name": {
          "label": "Your business name",
          "placeholder": "Enter your business name..."
        },
        "time_zone": {
          "label": "Time zone",
          "placeholder": "Choose time zone..."
        },
        "your_business": "Tell us something about your business"
      },
      "show": {
        "choose_subdomain": "Choose your platform subdomain.",
        "own_address": "You will have your own dedicated address where people login to your video rooms.",
        "subdomain": {
          "label": "Your subdomain",
          "placeholder": "Enter subdomain..."
        }
      }
    }
  }
});
I18n.translations["pl"] = I18n.extend((I18n.translations["pl"] || {}), {
  "activerecord": {
    "attributes": {
      "room_member": {
        "entry_code": "Password",
        "name": "Name"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    },
    "models": {
      "user": {
        "one": "User",
        "other": "Users"
      }
    }
  },
  "common": {
    "are_you_sure": "Are you sure?",
    "cancel": "Cancel",
    "nothing_selected": "Nothing selected",
    "reset": "Reset password for",
    "submit": "Submit"
  },
  "controllers": {
    "admin": {
      "settings": {
        "create": {
          "notice": "Setting was successfully updated."
        }
      },
      "workspaces": {
        "update": {
          "saved": "Saved"
        }
      }
    },
    "workspaces": {
      "update": {
        "saved": "Settings saved."
      }
    }
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Second",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": "Not saved",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007fdecd8ecbd8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/rails-i18n-6.0.0/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    }
  },
  "javascript": {
    "controllers": {
      "subscriptions": {
        "calls_per_month": "%{count} calls",
        "subscribe": "Subscribe: %{price} / month"
      }
    }
  },
  "layouts": {
    "application": {
      "_app_header_right": {
        "change_password": "Change password",
        "profile": "Profile"
      }
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "#<Proc:0x00007fdecd8652c8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:26 (lambda)>",
      "ordinals": "#<Proc:0x00007fdecd8652f0@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:7 (lambda)>"
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "recaptcha": {
    "errors": {
      "recaptcha_unreachable": "Oops, we failed to validate your reCAPTCHA response. Please try again.",
      "verification_failed": "reCAPTCHA verification failed, please try again."
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "views": {
    "admin": {
      "settings": {
        "show": {
          "details": "Details",
          "fields": {
            "billing_section_on": {
              "label": "Billing section on"
            },
            "billings_off": {
              "label": "Billings off"
            },
            "bottom_back_button_on": "Show back button on bottom",
            "bottom_back_button_text": "Text",
            "bottom_back_button_url": "URL",
            "brand": {
              "label": "Brand name"
            },
            "brand_on": {
              "label": "Brand on"
            },
            "call_price": {
              "label": "Call price"
            },
            "calls_max_quantity": {
              "label": "Max calls quantity"
            },
            "calls_min_quantity": {
              "label": "Min calls quantity"
            },
            "free_calls_limit": {
              "label": "Free calls limit"
            },
            "intercom_app_id": {
              "label": "App ID"
            },
            "invite_button_on": {
              "label": "Invitation button on"
            },
            "login_on": {
              "label": "Login on"
            },
            "ocl_api_key": {
              "label": "API key"
            },
            "privacy_policy_url": {
              "label": "Privacy policy URL"
            },
            "registration_on": {
              "label": "Registration on"
            },
            "stripe_publishable_key": {
              "label": "Publishable key"
            },
            "stripe_secret_key": {
              "label": "Secret key"
            },
            "terms_url": {
              "label": "Terms URL"
            },
            "top_back_button_on": "Show back button on top",
            "top_back_button_text": "Text",
            "top_back_button_url": "URL",
            "trial_calls_limit": {
              "label": "Call limit"
            },
            "users_section_on": {
              "label": "Users section on"
            },
            "wiki_url": {
              "label": "Wiki URL"
            }
          },
          "pricing": "Pricing",
          "tabs": {
            "blocked_subdomains": "Blocked subdomains",
            "external_services": "External services",
            "general": "General",
            "interface": "Interface settings",
            "subscription": "Subscription"
          },
          "trial": "Trial",
          "update_settings": "Update settings"
        }
      },
      "users": {
        "_user": {
          "workspaces": "Workspaces"
        }
      },
      "workspaces": {
        "_workspace": {
          "edit": "Edit"
        }
      }
    },
    "apis": {
      "show": {
        "add_call": "Add call",
        "auth": "Authentication header: Api-Key",
        "available_at": "The API is available at %{url}",
        "introduction": "Introduction"
      }
    },
    "calls": {
      "_form": {
        "description": {
          "label": "Description (optional)",
          "placeholder": "Describe the reason of call"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name of your call"
        },
        "password": {
          "label": "Password (optional)",
          "placeholder": "If you want to protect entrance with password enter your pass here"
        },
        "submit": "Start new call"
      },
      "index": {
        "_active": {
          "created_at": "%{date} ago",
          "created_by": "by %{name}",
          "edit": "Edit",
          "end": "End",
          "ended": "Ended",
          "go_to_chat_room": "Go to chat room",
          "live": "Live"
        },
        "_inner_bar": {
          "active": "Active",
          "ended": "Ended"
        },
        "_inner_layout": {
          "_no_active_calls": {
            "no_active_calls": "No active calls",
            "schedule": "Schedule",
            "start_now": "Start now"
          },
          "_tab_active_calls": {
            "actions": "Actions",
            "created_at": "Created date",
            "members": "Members",
            "name": "Name",
            "status": "Status"
          },
          "_tab_ended_calls": {
            "members": "Members",
            "name": "Name",
            "no_ended_calls": "No ended calls"
          }
        },
        "_page_title": {
          "current_plan": "Your current plan: %{count}/%{limit} free calls.",
          "schedule": "Schedule",
          "start_now": "Start now",
          "start_video_chat": "Start video chat"
        }
      },
      "new": {
        "start_new_call": "Start a new call"
      },
      "schedule": {
        "schedule_new_call": "Schedule new call"
      }
    },
    "devise": {
      "passwords": {
        "new": {
          "reset_password": "Reset your password",
          "send_instructions": "Send reset password instructions",
          "sign_in": "Cancel"
        }
      },
      "registrations": {
        "new": {
          "accept_terms": "Accept terms and conditions",
          "already_have_account": "Already have an account?",
          "create": "Create account",
          "create_account": "Create new account",
          "create_schedule_talk": "Create, Schedule, Talk",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "few_seconds": "It only takes a few seconds to create your account",
          "login_off": "Signing in is temporarily unavailable",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "private_video": "We offer a full private video-calls with use of Peer-to-peer technology",
          "registration_off": "Registration is temporarily unavailable",
          "sign_in": "Sign in"
        }
      },
      "sessions": {
        "new": {
          "build_room": "Build your video room",
          "create_account": "Create account",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "login_to_dashboard": "Login to dashboard",
          "no_account": "No account?",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "please_sign_in": "Please sign in to your account.",
          "recover_password": "Recover password",
          "send_instructions": "Send instructions",
          "sign_in": "Sign in",
          "sign_up": "Sign up now",
          "up_to": "Talk up to 10 people in a private Peer-to-peer connection"
        }
      }
    },
    "layouts": {
      "_app_header_right": {
        "logout": "Logout"
      },
      "admin": {
        "application": {
          "admins": "Admins",
          "api": "API",
          "calls": "Calls",
          "dashboard": "Dashboard",
          "settings": "Settings",
          "sign_out": "Sign out",
          "users": "Users",
          "workspaces": "Workspaces"
        }
      },
      "application": {
        "_app_header_right": {
          "add_workspace": "Add workspace",
          "calls_limit": "Calls: %{count}/%{limit}",
          "change_password": "Change password",
          "logout": "Logout",
          "profile": "Profile"
        },
        "_footer": {
          "copyright": "Copyright %{year} by Getreve",
          "policy": "Privacy policy",
          "terms": "Terms & Conditions"
        },
        "_horizontal_nav_menu": {
          "api": "API",
          "billing": "Billing",
          "calls": "Calls",
          "settings": "Settings",
          "users": "Users",
          "wiki": "Wiki"
        }
      },
      "wizard": {
        "logout": "Logout"
      }
    },
    "profiles": {
      "_password_form": {
        "password": {
          "label": "New password"
        }
      }
    },
    "rooms": {
      "_message_form": {
        "write_here_and_hit_enter": "Write here and hit enter to send..."
      },
      "_messages": {
        "history_not_displayed": "Due to security the history of this conversation will not be dislayed."
      },
      "_pending_room_member": {
        "accept": "Accept",
        "reject": "Reject"
      },
      "_video_room": {
        "reconnect": "Reconnect",
        "waiting_for_acceptance": "Waiting for acceptance"
      },
      "enter": {
        "chat_blocked": "This chat has been blocked",
        "chat_unavailable": "Chat unavailable",
        "enter_room": "Enter room",
        "welcome_to_room": "Welcome to room"
      },
      "show": {
        "powered_by": "Powered by Vli"
      }
    },
    "subscriptions": {
      "_form": {
        "calls_per_month": "%{count} calls"
      },
      "_incomplete": {
        "status": {
          "incomplete": "Incomplete"
        }
      },
      "show": {
        "_active": {
          "active_until": "<h5 class=\"mb-0\">Subscription will remain active until <strong>%{date}</strong>. After that date your limits will be downgraded to <strong>%{free_limit}</strong> calls per month. Are you sure?</h5>",
          "cancel": "Cancel subscription",
          "status": "Active"
        },
        "_canceled": {
          "status": "Canceled "
        },
        "_free": {
          "limit": "%{limit} calls <br> <small class='text-secondary'>per month</small>",
          "status": {
            "free": "Free"
          },
          "upgrade": "Upgrade limit"
        },
        "_incomplete": {
          "accept": "Click to accept payment",
          "complete_payment": "Complete your payment to activate subscription",
          "status": "Payment incomplete"
        },
        "status": {
          "cancel": "Cancel",
          "reactivate": "Reactivate",
          "upgrade": "Upgrade"
        }
      }
    },
    "users": {
      "_user": {
        "start_chat": "Start chat"
      },
      "index": {
        "_add": {
          "add": "Add"
        }
      }
    },
    "wizard": {
      "new": {
        "country": {
          "label": "Country",
          "placeholder": "Choose country..."
        },
        "currency": {
          "label": "Your currency",
          "placeholder": "Choose currency..."
        },
        "system_name": {
          "label": "Your business name",
          "placeholder": "Enter your business name..."
        },
        "time_zone": {
          "label": "Time zone",
          "placeholder": "Choose time zone..."
        },
        "your_business": "Tell us something about your business"
      },
      "show": {
        "choose_subdomain": "Choose your platform subdomain.",
        "own_address": "You will have your own dedicated address where people login to your video rooms.",
        "subdomain": {
          "label": "Your subdomain",
          "placeholder": "Enter subdomain..."
        }
      }
    }
  }
});
I18n.translations["pt"] = I18n.extend((I18n.translations["pt"] || {}), {
  "activerecord": {
    "attributes": {
      "room_member": {
        "entry_code": "Password",
        "name": "Name"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    },
    "models": {
      "user": {
        "one": "User",
        "other": "Users"
      }
    }
  },
  "common": {
    "are_you_sure": "Are you sure?",
    "cancel": "Cancel",
    "nothing_selected": "Nothing selected",
    "reset": "Reset password for",
    "submit": "Submit"
  },
  "controllers": {
    "admin": {
      "settings": {
        "create": {
          "notice": "Setting was successfully updated."
        }
      },
      "workspaces": {
        "update": {
          "saved": "Saved"
        }
      }
    },
    "workspaces": {
      "update": {
        "saved": "Settings saved."
      }
    }
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Second",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": "Not saved",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007fdecd8ecbd8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/rails-i18n-6.0.0/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    }
  },
  "javascript": {
    "controllers": {
      "subscriptions": {
        "calls_per_month": "%{count} calls",
        "subscribe": "Subscribe: %{price} / month"
      }
    }
  },
  "layouts": {
    "application": {
      "_app_header_right": {
        "change_password": "Change password",
        "profile": "Profile"
      }
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "#<Proc:0x00007fdecd8652c8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:26 (lambda)>",
      "ordinals": "#<Proc:0x00007fdecd8652f0@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:7 (lambda)>"
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "recaptcha": {
    "errors": {
      "recaptcha_unreachable": "Oops, we failed to validate your reCAPTCHA response. Please try again.",
      "verification_failed": "reCAPTCHA verification failed, please try again."
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "views": {
    "admin": {
      "settings": {
        "show": {
          "details": "Details",
          "fields": {
            "billing_section_on": {
              "label": "Billing section on"
            },
            "billings_off": {
              "label": "Billings off"
            },
            "bottom_back_button_on": "Show back button on bottom",
            "bottom_back_button_text": "Text",
            "bottom_back_button_url": "URL",
            "brand": {
              "label": "Brand name"
            },
            "brand_on": {
              "label": "Brand on"
            },
            "call_price": {
              "label": "Call price"
            },
            "calls_max_quantity": {
              "label": "Max calls quantity"
            },
            "calls_min_quantity": {
              "label": "Min calls quantity"
            },
            "free_calls_limit": {
              "label": "Free calls limit"
            },
            "intercom_app_id": {
              "label": "App ID"
            },
            "invite_button_on": {
              "label": "Invitation button on"
            },
            "login_on": {
              "label": "Login on"
            },
            "ocl_api_key": {
              "label": "API key"
            },
            "privacy_policy_url": {
              "label": "Privacy policy URL"
            },
            "registration_on": {
              "label": "Registration on"
            },
            "stripe_publishable_key": {
              "label": "Publishable key"
            },
            "stripe_secret_key": {
              "label": "Secret key"
            },
            "terms_url": {
              "label": "Terms URL"
            },
            "top_back_button_on": "Show back button on top",
            "top_back_button_text": "Text",
            "top_back_button_url": "URL",
            "trial_calls_limit": {
              "label": "Call limit"
            },
            "users_section_on": {
              "label": "Users section on"
            },
            "wiki_url": {
              "label": "Wiki URL"
            }
          },
          "pricing": "Pricing",
          "tabs": {
            "blocked_subdomains": "Blocked subdomains",
            "external_services": "External services",
            "general": "General",
            "interface": "Interface settings",
            "subscription": "Subscription"
          },
          "trial": "Trial",
          "update_settings": "Update settings"
        }
      },
      "users": {
        "_user": {
          "workspaces": "Workspaces"
        }
      },
      "workspaces": {
        "_workspace": {
          "edit": "Edit"
        }
      }
    },
    "apis": {
      "show": {
        "add_call": "Add call",
        "auth": "Authentication header: Api-Key",
        "available_at": "The API is available at %{url}",
        "introduction": "Introduction"
      }
    },
    "calls": {
      "_form": {
        "description": {
          "label": "Description (optional)",
          "placeholder": "Describe the reason of call"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name of your call"
        },
        "password": {
          "label": "Password (optional)",
          "placeholder": "If you want to protect entrance with password enter your pass here"
        },
        "submit": "Start new call"
      },
      "index": {
        "_active": {
          "created_at": "%{date} ago",
          "created_by": "by %{name}",
          "edit": "Edit",
          "end": "End",
          "ended": "Ended",
          "go_to_chat_room": "Go to chat room",
          "live": "Live"
        },
        "_inner_bar": {
          "active": "Active",
          "ended": "Ended"
        },
        "_inner_layout": {
          "_no_active_calls": {
            "no_active_calls": "No active calls",
            "schedule": "Schedule",
            "start_now": "Start now"
          },
          "_tab_active_calls": {
            "actions": "Actions",
            "created_at": "Created date",
            "members": "Members",
            "name": "Name",
            "status": "Status"
          },
          "_tab_ended_calls": {
            "members": "Members",
            "name": "Name",
            "no_ended_calls": "No ended calls"
          }
        },
        "_page_title": {
          "current_plan": "Your current plan: %{count}/%{limit} free calls.",
          "schedule": "Schedule",
          "start_now": "Start now",
          "start_video_chat": "Start video chat"
        }
      },
      "new": {
        "start_new_call": "Start a new call"
      },
      "schedule": {
        "schedule_new_call": "Schedule new call"
      }
    },
    "devise": {
      "passwords": {
        "new": {
          "reset_password": "Reset your password",
          "send_instructions": "Send reset password instructions",
          "sign_in": "Cancel"
        }
      },
      "registrations": {
        "new": {
          "accept_terms": "Accept terms and conditions",
          "already_have_account": "Already have an account?",
          "create": "Create account",
          "create_account": "Create new account",
          "create_schedule_talk": "Create, Schedule, Talk",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "few_seconds": "It only takes a few seconds to create your account",
          "login_off": "Signing in is temporarily unavailable",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "private_video": "We offer a full private video-calls with use of Peer-to-peer technology",
          "registration_off": "Registration is temporarily unavailable",
          "sign_in": "Sign in"
        }
      },
      "sessions": {
        "new": {
          "build_room": "Build your video room",
          "create_account": "Create account",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "login_to_dashboard": "Login to dashboard",
          "no_account": "No account?",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "please_sign_in": "Please sign in to your account.",
          "recover_password": "Recover password",
          "send_instructions": "Send instructions",
          "sign_in": "Sign in",
          "sign_up": "Sign up now",
          "up_to": "Talk up to 10 people in a private Peer-to-peer connection"
        }
      }
    },
    "layouts": {
      "_app_header_right": {
        "logout": "Logout"
      },
      "admin": {
        "application": {
          "admins": "Admins",
          "api": "API",
          "calls": "Calls",
          "dashboard": "Dashboard",
          "settings": "Settings",
          "sign_out": "Sign out",
          "users": "Users",
          "workspaces": "Workspaces"
        }
      },
      "application": {
        "_app_header_right": {
          "add_workspace": "Add workspace",
          "calls_limit": "Calls: %{count}/%{limit}",
          "change_password": "Change password",
          "logout": "Logout",
          "profile": "Profile"
        },
        "_footer": {
          "copyright": "Copyright %{year} by Getreve",
          "policy": "Privacy policy",
          "terms": "Terms & Conditions"
        },
        "_horizontal_nav_menu": {
          "api": "API",
          "billing": "Billing",
          "calls": "Calls",
          "settings": "Settings",
          "users": "Users",
          "wiki": "Wiki"
        }
      },
      "wizard": {
        "logout": "Logout"
      }
    },
    "profiles": {
      "_password_form": {
        "password": {
          "label": "New password"
        }
      }
    },
    "rooms": {
      "_message_form": {
        "write_here_and_hit_enter": "Write here and hit enter to send..."
      },
      "_messages": {
        "history_not_displayed": "Due to security the history of this conversation will not be dislayed."
      },
      "_pending_room_member": {
        "accept": "Accept",
        "reject": "Reject"
      },
      "_video_room": {
        "reconnect": "Reconnect",
        "waiting_for_acceptance": "Waiting for acceptance"
      },
      "enter": {
        "chat_blocked": "This chat has been blocked",
        "chat_unavailable": "Chat unavailable",
        "enter_room": "Enter room",
        "welcome_to_room": "Welcome to room"
      },
      "show": {
        "powered_by": "Powered by Vli"
      }
    },
    "subscriptions": {
      "_form": {
        "calls_per_month": "%{count} calls"
      },
      "_incomplete": {
        "status": {
          "incomplete": "Incomplete"
        }
      },
      "show": {
        "_active": {
          "active_until": "<h5 class=\"mb-0\">Subscription will remain active until <strong>%{date}</strong>. After that date your limits will be downgraded to <strong>%{free_limit}</strong> calls per month. Are you sure?</h5>",
          "cancel": "Cancel subscription",
          "status": "Active"
        },
        "_canceled": {
          "status": "Canceled "
        },
        "_free": {
          "limit": "%{limit} calls <br> <small class='text-secondary'>per month</small>",
          "status": {
            "free": "Free"
          },
          "upgrade": "Upgrade limit"
        },
        "_incomplete": {
          "accept": "Click to accept payment",
          "complete_payment": "Complete your payment to activate subscription",
          "status": "Payment incomplete"
        },
        "status": {
          "cancel": "Cancel",
          "reactivate": "Reactivate",
          "upgrade": "Upgrade"
        }
      }
    },
    "users": {
      "_user": {
        "start_chat": "Start chat"
      },
      "index": {
        "_add": {
          "add": "Add"
        }
      }
    },
    "wizard": {
      "new": {
        "country": {
          "label": "Country",
          "placeholder": "Choose country..."
        },
        "currency": {
          "label": "Your currency",
          "placeholder": "Choose currency..."
        },
        "system_name": {
          "label": "Your business name",
          "placeholder": "Enter your business name..."
        },
        "time_zone": {
          "label": "Time zone",
          "placeholder": "Choose time zone..."
        },
        "your_business": "Tell us something about your business"
      },
      "show": {
        "choose_subdomain": "Choose your platform subdomain.",
        "own_address": "You will have your own dedicated address where people login to your video rooms.",
        "subdomain": {
          "label": "Your subdomain",
          "placeholder": "Enter subdomain..."
        }
      }
    }
  }
});
I18n.translations["ro"] = I18n.extend((I18n.translations["ro"] || {}), {
  "activerecord": {
    "attributes": {
      "room_member": {
        "entry_code": "Password",
        "name": "Name"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    },
    "models": {
      "user": {
        "one": "User",
        "other": "Users"
      }
    }
  },
  "common": {
    "are_you_sure": "Are you sure?",
    "cancel": "Cancel",
    "nothing_selected": "Nothing selected",
    "reset": "Reset password for",
    "submit": "Submit"
  },
  "controllers": {
    "admin": {
      "settings": {
        "create": {
          "notice": "Setting was successfully updated."
        }
      },
      "workspaces": {
        "update": {
          "saved": "Saved"
        }
      }
    },
    "workspaces": {
      "update": {
        "saved": "Settings saved."
      }
    }
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Second",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": "Not saved",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007fdecd8ecbd8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/rails-i18n-6.0.0/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    }
  },
  "javascript": {
    "controllers": {
      "subscriptions": {
        "calls_per_month": "%{count} calls",
        "subscribe": "Subscribe: %{price} / month"
      }
    }
  },
  "layouts": {
    "application": {
      "_app_header_right": {
        "change_password": "Change password",
        "profile": "Profile"
      }
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "#<Proc:0x00007fdecd8652c8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:26 (lambda)>",
      "ordinals": "#<Proc:0x00007fdecd8652f0@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:7 (lambda)>"
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "recaptcha": {
    "errors": {
      "recaptcha_unreachable": "Oops, we failed to validate your reCAPTCHA response. Please try again.",
      "verification_failed": "reCAPTCHA verification failed, please try again."
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "views": {
    "admin": {
      "settings": {
        "show": {
          "details": "Details",
          "fields": {
            "billing_section_on": {
              "label": "Billing section on"
            },
            "billings_off": {
              "label": "Billings off"
            },
            "bottom_back_button_on": "Show back button on bottom",
            "bottom_back_button_text": "Text",
            "bottom_back_button_url": "URL",
            "brand": {
              "label": "Brand name"
            },
            "brand_on": {
              "label": "Brand on"
            },
            "call_price": {
              "label": "Call price"
            },
            "calls_max_quantity": {
              "label": "Max calls quantity"
            },
            "calls_min_quantity": {
              "label": "Min calls quantity"
            },
            "free_calls_limit": {
              "label": "Free calls limit"
            },
            "intercom_app_id": {
              "label": "App ID"
            },
            "invite_button_on": {
              "label": "Invitation button on"
            },
            "login_on": {
              "label": "Login on"
            },
            "ocl_api_key": {
              "label": "API key"
            },
            "privacy_policy_url": {
              "label": "Privacy policy URL"
            },
            "registration_on": {
              "label": "Registration on"
            },
            "stripe_publishable_key": {
              "label": "Publishable key"
            },
            "stripe_secret_key": {
              "label": "Secret key"
            },
            "terms_url": {
              "label": "Terms URL"
            },
            "top_back_button_on": "Show back button on top",
            "top_back_button_text": "Text",
            "top_back_button_url": "URL",
            "trial_calls_limit": {
              "label": "Call limit"
            },
            "users_section_on": {
              "label": "Users section on"
            },
            "wiki_url": {
              "label": "Wiki URL"
            }
          },
          "pricing": "Pricing",
          "tabs": {
            "blocked_subdomains": "Blocked subdomains",
            "external_services": "External services",
            "general": "General",
            "interface": "Interface settings",
            "subscription": "Subscription"
          },
          "trial": "Trial",
          "update_settings": "Update settings"
        }
      },
      "users": {
        "_user": {
          "workspaces": "Workspaces"
        }
      },
      "workspaces": {
        "_workspace": {
          "edit": "Edit"
        }
      }
    },
    "apis": {
      "show": {
        "add_call": "Add call",
        "auth": "Authentication header: Api-Key",
        "available_at": "The API is available at %{url}",
        "introduction": "Introduction"
      }
    },
    "calls": {
      "_form": {
        "description": {
          "label": "Description (optional)",
          "placeholder": "Describe the reason of call"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name of your call"
        },
        "password": {
          "label": "Password (optional)",
          "placeholder": "If you want to protect entrance with password enter your pass here"
        },
        "submit": "Start new call"
      },
      "index": {
        "_active": {
          "created_at": "%{date} ago",
          "created_by": "by %{name}",
          "edit": "Edit",
          "end": "End",
          "ended": "Ended",
          "go_to_chat_room": "Go to chat room",
          "live": "Live"
        },
        "_inner_bar": {
          "active": "Active",
          "ended": "Ended"
        },
        "_inner_layout": {
          "_no_active_calls": {
            "no_active_calls": "No active calls",
            "schedule": "Schedule",
            "start_now": "Start now"
          },
          "_tab_active_calls": {
            "actions": "Actions",
            "created_at": "Created date",
            "members": "Members",
            "name": "Name",
            "status": "Status"
          },
          "_tab_ended_calls": {
            "members": "Members",
            "name": "Name",
            "no_ended_calls": "No ended calls"
          }
        },
        "_page_title": {
          "current_plan": "Your current plan: %{count}/%{limit} free calls.",
          "schedule": "Schedule",
          "start_now": "Start now",
          "start_video_chat": "Start video chat"
        }
      },
      "new": {
        "start_new_call": "Start a new call"
      },
      "schedule": {
        "schedule_new_call": "Schedule new call"
      }
    },
    "devise": {
      "passwords": {
        "new": {
          "reset_password": "Reset your password",
          "send_instructions": "Send reset password instructions",
          "sign_in": "Cancel"
        }
      },
      "registrations": {
        "new": {
          "accept_terms": "Accept terms and conditions",
          "already_have_account": "Already have an account?",
          "create": "Create account",
          "create_account": "Create new account",
          "create_schedule_talk": "Create, Schedule, Talk",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "few_seconds": "It only takes a few seconds to create your account",
          "login_off": "Signing in is temporarily unavailable",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "private_video": "We offer a full private video-calls with use of Peer-to-peer technology",
          "registration_off": "Registration is temporarily unavailable",
          "sign_in": "Sign in"
        }
      },
      "sessions": {
        "new": {
          "build_room": "Build your video room",
          "create_account": "Create account",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "login_to_dashboard": "Login to dashboard",
          "no_account": "No account?",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "please_sign_in": "Please sign in to your account.",
          "recover_password": "Recover password",
          "send_instructions": "Send instructions",
          "sign_in": "Sign in",
          "sign_up": "Sign up now",
          "up_to": "Talk up to 10 people in a private Peer-to-peer connection"
        }
      }
    },
    "layouts": {
      "_app_header_right": {
        "logout": "Logout"
      },
      "admin": {
        "application": {
          "admins": "Admins",
          "api": "API",
          "calls": "Calls",
          "dashboard": "Dashboard",
          "settings": "Settings",
          "sign_out": "Sign out",
          "users": "Users",
          "workspaces": "Workspaces"
        }
      },
      "application": {
        "_app_header_right": {
          "add_workspace": "Add workspace",
          "calls_limit": "Calls: %{count}/%{limit}",
          "change_password": "Change password",
          "logout": "Logout",
          "profile": "Profile"
        },
        "_footer": {
          "copyright": "Copyright %{year} by Getreve",
          "policy": "Privacy policy",
          "terms": "Terms & Conditions"
        },
        "_horizontal_nav_menu": {
          "api": "API",
          "billing": "Billing",
          "calls": "Calls",
          "settings": "Settings",
          "users": "Users",
          "wiki": "Wiki"
        }
      },
      "wizard": {
        "logout": "Logout"
      }
    },
    "profiles": {
      "_password_form": {
        "password": {
          "label": "New password"
        }
      }
    },
    "rooms": {
      "_message_form": {
        "write_here_and_hit_enter": "Write here and hit enter to send..."
      },
      "_messages": {
        "history_not_displayed": "Due to security the history of this conversation will not be dislayed."
      },
      "_pending_room_member": {
        "accept": "Accept",
        "reject": "Reject"
      },
      "_video_room": {
        "reconnect": "Reconnect",
        "waiting_for_acceptance": "Waiting for acceptance"
      },
      "enter": {
        "chat_blocked": "This chat has been blocked",
        "chat_unavailable": "Chat unavailable",
        "enter_room": "Enter room",
        "welcome_to_room": "Welcome to room"
      },
      "show": {
        "powered_by": "Powered by Vli"
      }
    },
    "subscriptions": {
      "_form": {
        "calls_per_month": "%{count} calls"
      },
      "_incomplete": {
        "status": {
          "incomplete": "Incomplete"
        }
      },
      "show": {
        "_active": {
          "active_until": "<h5 class=\"mb-0\">Subscription will remain active until <strong>%{date}</strong>. After that date your limits will be downgraded to <strong>%{free_limit}</strong> calls per month. Are you sure?</h5>",
          "cancel": "Cancel subscription",
          "status": "Active"
        },
        "_canceled": {
          "status": "Canceled "
        },
        "_free": {
          "limit": "%{limit} calls <br> <small class='text-secondary'>per month</small>",
          "status": {
            "free": "Free"
          },
          "upgrade": "Upgrade limit"
        },
        "_incomplete": {
          "accept": "Click to accept payment",
          "complete_payment": "Complete your payment to activate subscription",
          "status": "Payment incomplete"
        },
        "status": {
          "cancel": "Cancel",
          "reactivate": "Reactivate",
          "upgrade": "Upgrade"
        }
      }
    },
    "users": {
      "_user": {
        "start_chat": "Start chat"
      },
      "index": {
        "_add": {
          "add": "Add"
        }
      }
    },
    "wizard": {
      "new": {
        "country": {
          "label": "Country",
          "placeholder": "Choose country..."
        },
        "currency": {
          "label": "Your currency",
          "placeholder": "Choose currency..."
        },
        "system_name": {
          "label": "Your business name",
          "placeholder": "Enter your business name..."
        },
        "time_zone": {
          "label": "Time zone",
          "placeholder": "Choose time zone..."
        },
        "your_business": "Tell us something about your business"
      },
      "show": {
        "choose_subdomain": "Choose your platform subdomain.",
        "own_address": "You will have your own dedicated address where people login to your video rooms.",
        "subdomain": {
          "label": "Your subdomain",
          "placeholder": "Enter subdomain..."
        }
      }
    }
  }
});
I18n.translations["ru"] = I18n.extend((I18n.translations["ru"] || {}), {
  "activerecord": {
    "attributes": {
      "room_member": {
        "entry_code": "Password",
        "name": "Name"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    },
    "models": {
      "user": {
        "one": "User",
        "other": "Users"
      }
    }
  },
  "common": {
    "are_you_sure": "Are you sure?",
    "cancel": "Cancel",
    "nothing_selected": "Nothing selected",
    "reset": "Reset password for",
    "submit": "Submit"
  },
  "controllers": {
    "admin": {
      "settings": {
        "create": {
          "notice": "Setting was successfully updated."
        }
      },
      "workspaces": {
        "update": {
          "saved": "Saved"
        }
      }
    },
    "workspaces": {
      "update": {
        "saved": "Settings saved."
      }
    }
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Second",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": "Not saved",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007fdecd8ecbd8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/rails-i18n-6.0.0/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    }
  },
  "javascript": {
    "controllers": {
      "subscriptions": {
        "calls_per_month": "%{count} calls",
        "subscribe": "Subscribe: %{price} / month"
      }
    }
  },
  "layouts": {
    "application": {
      "_app_header_right": {
        "change_password": "Change password",
        "profile": "Profile"
      }
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "#<Proc:0x00007fdecd8652c8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:26 (lambda)>",
      "ordinals": "#<Proc:0x00007fdecd8652f0@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:7 (lambda)>"
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "recaptcha": {
    "errors": {
      "recaptcha_unreachable": "Oops, we failed to validate your reCAPTCHA response. Please try again.",
      "verification_failed": "reCAPTCHA verification failed, please try again."
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "views": {
    "admin": {
      "settings": {
        "show": {
          "details": "Details",
          "fields": {
            "billing_section_on": {
              "label": "Billing section on"
            },
            "billings_off": {
              "label": "Billings off"
            },
            "bottom_back_button_on": "Show back button on bottom",
            "bottom_back_button_text": "Text",
            "bottom_back_button_url": "URL",
            "brand": {
              "label": "Brand name"
            },
            "brand_on": {
              "label": "Brand on"
            },
            "call_price": {
              "label": "Call price"
            },
            "calls_max_quantity": {
              "label": "Max calls quantity"
            },
            "calls_min_quantity": {
              "label": "Min calls quantity"
            },
            "free_calls_limit": {
              "label": "Free calls limit"
            },
            "intercom_app_id": {
              "label": "App ID"
            },
            "invite_button_on": {
              "label": "Invitation button on"
            },
            "login_on": {
              "label": "Login on"
            },
            "ocl_api_key": {
              "label": "API key"
            },
            "privacy_policy_url": {
              "label": "Privacy policy URL"
            },
            "registration_on": {
              "label": "Registration on"
            },
            "stripe_publishable_key": {
              "label": "Publishable key"
            },
            "stripe_secret_key": {
              "label": "Secret key"
            },
            "terms_url": {
              "label": "Terms URL"
            },
            "top_back_button_on": "Show back button on top",
            "top_back_button_text": "Text",
            "top_back_button_url": "URL",
            "trial_calls_limit": {
              "label": "Call limit"
            },
            "users_section_on": {
              "label": "Users section on"
            },
            "wiki_url": {
              "label": "Wiki URL"
            }
          },
          "pricing": "Pricing",
          "tabs": {
            "blocked_subdomains": "Blocked subdomains",
            "external_services": "External services",
            "general": "General",
            "interface": "Interface settings",
            "subscription": "Subscription"
          },
          "trial": "Trial",
          "update_settings": "Update settings"
        }
      },
      "users": {
        "_user": {
          "workspaces": "Workspaces"
        }
      },
      "workspaces": {
        "_workspace": {
          "edit": "Edit"
        }
      }
    },
    "apis": {
      "show": {
        "add_call": "Add call",
        "auth": "Authentication header: Api-Key",
        "available_at": "The API is available at %{url}",
        "introduction": "Introduction"
      }
    },
    "calls": {
      "_form": {
        "description": {
          "label": "Description (optional)",
          "placeholder": "Describe the reason of call"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name of your call"
        },
        "password": {
          "label": "Password (optional)",
          "placeholder": "If you want to protect entrance with password enter your pass here"
        },
        "submit": "Start new call"
      },
      "index": {
        "_active": {
          "created_at": "%{date} ago",
          "created_by": "by %{name}",
          "edit": "Edit",
          "end": "End",
          "ended": "Ended",
          "go_to_chat_room": "Go to chat room",
          "live": "Live"
        },
        "_inner_bar": {
          "active": "Active",
          "ended": "Ended"
        },
        "_inner_layout": {
          "_no_active_calls": {
            "no_active_calls": "No active calls",
            "schedule": "Schedule",
            "start_now": "Start now"
          },
          "_tab_active_calls": {
            "actions": "Actions",
            "created_at": "Created date",
            "members": "Members",
            "name": "Name",
            "status": "Status"
          },
          "_tab_ended_calls": {
            "members": "Members",
            "name": "Name",
            "no_ended_calls": "No ended calls"
          }
        },
        "_page_title": {
          "current_plan": "Your current plan: %{count}/%{limit} free calls.",
          "schedule": "Schedule",
          "start_now": "Start now",
          "start_video_chat": "Start video chat"
        }
      },
      "new": {
        "start_new_call": "Start a new call"
      },
      "schedule": {
        "schedule_new_call": "Schedule new call"
      }
    },
    "devise": {
      "passwords": {
        "new": {
          "reset_password": "Reset your password",
          "send_instructions": "Send reset password instructions",
          "sign_in": "Cancel"
        }
      },
      "registrations": {
        "new": {
          "accept_terms": "Accept terms and conditions",
          "already_have_account": "Already have an account?",
          "create": "Create account",
          "create_account": "Create new account",
          "create_schedule_talk": "Create, Schedule, Talk",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "few_seconds": "It only takes a few seconds to create your account",
          "login_off": "Signing in is temporarily unavailable",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "private_video": "We offer a full private video-calls with use of Peer-to-peer technology",
          "registration_off": "Registration is temporarily unavailable",
          "sign_in": "Sign in"
        }
      },
      "sessions": {
        "new": {
          "build_room": "Build your video room",
          "create_account": "Create account",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "login_to_dashboard": "Login to dashboard",
          "no_account": "No account?",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "please_sign_in": "Please sign in to your account.",
          "recover_password": "Recover password",
          "send_instructions": "Send instructions",
          "sign_in": "Sign in",
          "sign_up": "Sign up now",
          "up_to": "Talk up to 10 people in a private Peer-to-peer connection"
        }
      }
    },
    "layouts": {
      "_app_header_right": {
        "logout": "Logout"
      },
      "admin": {
        "application": {
          "admins": "Admins",
          "api": "API",
          "calls": "Calls",
          "dashboard": "Dashboard",
          "settings": "Settings",
          "sign_out": "Sign out",
          "users": "Users",
          "workspaces": "Workspaces"
        }
      },
      "application": {
        "_app_header_right": {
          "add_workspace": "Add workspace",
          "calls_limit": "Calls: %{count}/%{limit}",
          "change_password": "Change password",
          "logout": "Logout",
          "profile": "Profile"
        },
        "_footer": {
          "copyright": "Copyright %{year} by Getreve",
          "policy": "Privacy policy",
          "terms": "Terms & Conditions"
        },
        "_horizontal_nav_menu": {
          "api": "API",
          "billing": "Billing",
          "calls": "Calls",
          "settings": "Settings",
          "users": "Users",
          "wiki": "Wiki"
        }
      },
      "wizard": {
        "logout": "Logout"
      }
    },
    "profiles": {
      "_password_form": {
        "password": {
          "label": "New password"
        }
      }
    },
    "rooms": {
      "_message_form": {
        "write_here_and_hit_enter": "Write here and hit enter to send..."
      },
      "_messages": {
        "history_not_displayed": "Due to security the history of this conversation will not be dislayed."
      },
      "_pending_room_member": {
        "accept": "Accept",
        "reject": "Reject"
      },
      "_video_room": {
        "reconnect": "Reconnect",
        "waiting_for_acceptance": "Waiting for acceptance"
      },
      "enter": {
        "chat_blocked": "This chat has been blocked",
        "chat_unavailable": "Chat unavailable",
        "enter_room": "Enter room",
        "welcome_to_room": "Welcome to room"
      },
      "show": {
        "powered_by": "Powered by Vli"
      }
    },
    "subscriptions": {
      "_form": {
        "calls_per_month": "%{count} calls"
      },
      "_incomplete": {
        "status": {
          "incomplete": "Incomplete"
        }
      },
      "show": {
        "_active": {
          "active_until": "<h5 class=\"mb-0\">Subscription will remain active until <strong>%{date}</strong>. After that date your limits will be downgraded to <strong>%{free_limit}</strong> calls per month. Are you sure?</h5>",
          "cancel": "Cancel subscription",
          "status": "Active"
        },
        "_canceled": {
          "status": "Canceled "
        },
        "_free": {
          "limit": "%{limit} calls <br> <small class='text-secondary'>per month</small>",
          "status": {
            "free": "Free"
          },
          "upgrade": "Upgrade limit"
        },
        "_incomplete": {
          "accept": "Click to accept payment",
          "complete_payment": "Complete your payment to activate subscription",
          "status": "Payment incomplete"
        },
        "status": {
          "cancel": "Cancel",
          "reactivate": "Reactivate",
          "upgrade": "Upgrade"
        }
      }
    },
    "users": {
      "_user": {
        "start_chat": "Start chat"
      },
      "index": {
        "_add": {
          "add": "Add"
        }
      }
    },
    "wizard": {
      "new": {
        "country": {
          "label": "Country",
          "placeholder": "Choose country..."
        },
        "currency": {
          "label": "Your currency",
          "placeholder": "Choose currency..."
        },
        "system_name": {
          "label": "Your business name",
          "placeholder": "Enter your business name..."
        },
        "time_zone": {
          "label": "Time zone",
          "placeholder": "Choose time zone..."
        },
        "your_business": "Tell us something about your business"
      },
      "show": {
        "choose_subdomain": "Choose your platform subdomain.",
        "own_address": "You will have your own dedicated address where people login to your video rooms.",
        "subdomain": {
          "label": "Your subdomain",
          "placeholder": "Enter subdomain..."
        }
      }
    }
  }
});
I18n.translations["sk"] = I18n.extend((I18n.translations["sk"] || {}), {
  "activerecord": {
    "attributes": {
      "room_member": {
        "entry_code": "Password",
        "name": "Name"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    },
    "models": {
      "user": {
        "one": "User",
        "other": "Users"
      }
    }
  },
  "common": {
    "are_you_sure": "Are you sure?",
    "cancel": "Cancel",
    "nothing_selected": "Nothing selected",
    "reset": "Reset password for",
    "submit": "Submit"
  },
  "controllers": {
    "admin": {
      "settings": {
        "create": {
          "notice": "Setting was successfully updated."
        }
      },
      "workspaces": {
        "update": {
          "saved": "Saved"
        }
      }
    },
    "workspaces": {
      "update": {
        "saved": "Settings saved."
      }
    }
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Second",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": "Not saved",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007fdecd8ecbd8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/rails-i18n-6.0.0/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    }
  },
  "javascript": {
    "controllers": {
      "subscriptions": {
        "calls_per_month": "%{count} calls",
        "subscribe": "Subscribe: %{price} / month"
      }
    }
  },
  "layouts": {
    "application": {
      "_app_header_right": {
        "change_password": "Change password",
        "profile": "Profile"
      }
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "#<Proc:0x00007fdecd8652c8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:26 (lambda)>",
      "ordinals": "#<Proc:0x00007fdecd8652f0@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:7 (lambda)>"
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "recaptcha": {
    "errors": {
      "recaptcha_unreachable": "Oops, we failed to validate your reCAPTCHA response. Please try again.",
      "verification_failed": "reCAPTCHA verification failed, please try again."
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "views": {
    "admin": {
      "settings": {
        "show": {
          "details": "Details",
          "fields": {
            "billing_section_on": {
              "label": "Billing section on"
            },
            "billings_off": {
              "label": "Billings off"
            },
            "bottom_back_button_on": "Show back button on bottom",
            "bottom_back_button_text": "Text",
            "bottom_back_button_url": "URL",
            "brand": {
              "label": "Brand name"
            },
            "brand_on": {
              "label": "Brand on"
            },
            "call_price": {
              "label": "Call price"
            },
            "calls_max_quantity": {
              "label": "Max calls quantity"
            },
            "calls_min_quantity": {
              "label": "Min calls quantity"
            },
            "free_calls_limit": {
              "label": "Free calls limit"
            },
            "intercom_app_id": {
              "label": "App ID"
            },
            "invite_button_on": {
              "label": "Invitation button on"
            },
            "login_on": {
              "label": "Login on"
            },
            "ocl_api_key": {
              "label": "API key"
            },
            "privacy_policy_url": {
              "label": "Privacy policy URL"
            },
            "registration_on": {
              "label": "Registration on"
            },
            "stripe_publishable_key": {
              "label": "Publishable key"
            },
            "stripe_secret_key": {
              "label": "Secret key"
            },
            "terms_url": {
              "label": "Terms URL"
            },
            "top_back_button_on": "Show back button on top",
            "top_back_button_text": "Text",
            "top_back_button_url": "URL",
            "trial_calls_limit": {
              "label": "Call limit"
            },
            "users_section_on": {
              "label": "Users section on"
            },
            "wiki_url": {
              "label": "Wiki URL"
            }
          },
          "pricing": "Pricing",
          "tabs": {
            "blocked_subdomains": "Blocked subdomains",
            "external_services": "External services",
            "general": "General",
            "interface": "Interface settings",
            "subscription": "Subscription"
          },
          "trial": "Trial",
          "update_settings": "Update settings"
        }
      },
      "users": {
        "_user": {
          "workspaces": "Workspaces"
        }
      },
      "workspaces": {
        "_workspace": {
          "edit": "Edit"
        }
      }
    },
    "apis": {
      "show": {
        "add_call": "Add call",
        "auth": "Authentication header: Api-Key",
        "available_at": "The API is available at %{url}",
        "introduction": "Introduction"
      }
    },
    "calls": {
      "_form": {
        "description": {
          "label": "Description (optional)",
          "placeholder": "Describe the reason of call"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name of your call"
        },
        "password": {
          "label": "Password (optional)",
          "placeholder": "If you want to protect entrance with password enter your pass here"
        },
        "submit": "Start new call"
      },
      "index": {
        "_active": {
          "created_at": "%{date} ago",
          "created_by": "by %{name}",
          "edit": "Edit",
          "end": "End",
          "ended": "Ended",
          "go_to_chat_room": "Go to chat room",
          "live": "Live"
        },
        "_inner_bar": {
          "active": "Active",
          "ended": "Ended"
        },
        "_inner_layout": {
          "_no_active_calls": {
            "no_active_calls": "No active calls",
            "schedule": "Schedule",
            "start_now": "Start now"
          },
          "_tab_active_calls": {
            "actions": "Actions",
            "created_at": "Created date",
            "members": "Members",
            "name": "Name",
            "status": "Status"
          },
          "_tab_ended_calls": {
            "members": "Members",
            "name": "Name",
            "no_ended_calls": "No ended calls"
          }
        },
        "_page_title": {
          "current_plan": "Your current plan: %{count}/%{limit} free calls.",
          "schedule": "Schedule",
          "start_now": "Start now",
          "start_video_chat": "Start video chat"
        }
      },
      "new": {
        "start_new_call": "Start a new call"
      },
      "schedule": {
        "schedule_new_call": "Schedule new call"
      }
    },
    "devise": {
      "passwords": {
        "new": {
          "reset_password": "Reset your password",
          "send_instructions": "Send reset password instructions",
          "sign_in": "Cancel"
        }
      },
      "registrations": {
        "new": {
          "accept_terms": "Accept terms and conditions",
          "already_have_account": "Already have an account?",
          "create": "Create account",
          "create_account": "Create new account",
          "create_schedule_talk": "Create, Schedule, Talk",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "few_seconds": "It only takes a few seconds to create your account",
          "login_off": "Signing in is temporarily unavailable",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "private_video": "We offer a full private video-calls with use of Peer-to-peer technology",
          "registration_off": "Registration is temporarily unavailable",
          "sign_in": "Sign in"
        }
      },
      "sessions": {
        "new": {
          "build_room": "Build your video room",
          "create_account": "Create account",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "login_to_dashboard": "Login to dashboard",
          "no_account": "No account?",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "please_sign_in": "Please sign in to your account.",
          "recover_password": "Recover password",
          "send_instructions": "Send instructions",
          "sign_in": "Sign in",
          "sign_up": "Sign up now",
          "up_to": "Talk up to 10 people in a private Peer-to-peer connection"
        }
      }
    },
    "layouts": {
      "_app_header_right": {
        "logout": "Logout"
      },
      "admin": {
        "application": {
          "admins": "Admins",
          "api": "API",
          "calls": "Calls",
          "dashboard": "Dashboard",
          "settings": "Settings",
          "sign_out": "Sign out",
          "users": "Users",
          "workspaces": "Workspaces"
        }
      },
      "application": {
        "_app_header_right": {
          "add_workspace": "Add workspace",
          "calls_limit": "Calls: %{count}/%{limit}",
          "change_password": "Change password",
          "logout": "Logout",
          "profile": "Profile"
        },
        "_footer": {
          "copyright": "Copyright %{year} by Getreve",
          "policy": "Privacy policy",
          "terms": "Terms & Conditions"
        },
        "_horizontal_nav_menu": {
          "api": "API",
          "billing": "Billing",
          "calls": "Calls",
          "settings": "Settings",
          "users": "Users",
          "wiki": "Wiki"
        }
      },
      "wizard": {
        "logout": "Logout"
      }
    },
    "profiles": {
      "_password_form": {
        "password": {
          "label": "New password"
        }
      }
    },
    "rooms": {
      "_message_form": {
        "write_here_and_hit_enter": "Write here and hit enter to send..."
      },
      "_messages": {
        "history_not_displayed": "Due to security the history of this conversation will not be dislayed."
      },
      "_pending_room_member": {
        "accept": "Accept",
        "reject": "Reject"
      },
      "_video_room": {
        "reconnect": "Reconnect",
        "waiting_for_acceptance": "Waiting for acceptance"
      },
      "enter": {
        "chat_blocked": "This chat has been blocked",
        "chat_unavailable": "Chat unavailable",
        "enter_room": "Enter room",
        "welcome_to_room": "Welcome to room"
      },
      "show": {
        "powered_by": "Powered by Vli"
      }
    },
    "subscriptions": {
      "_form": {
        "calls_per_month": "%{count} calls"
      },
      "_incomplete": {
        "status": {
          "incomplete": "Incomplete"
        }
      },
      "show": {
        "_active": {
          "active_until": "<h5 class=\"mb-0\">Subscription will remain active until <strong>%{date}</strong>. After that date your limits will be downgraded to <strong>%{free_limit}</strong> calls per month. Are you sure?</h5>",
          "cancel": "Cancel subscription",
          "status": "Active"
        },
        "_canceled": {
          "status": "Canceled "
        },
        "_free": {
          "limit": "%{limit} calls <br> <small class='text-secondary'>per month</small>",
          "status": {
            "free": "Free"
          },
          "upgrade": "Upgrade limit"
        },
        "_incomplete": {
          "accept": "Click to accept payment",
          "complete_payment": "Complete your payment to activate subscription",
          "status": "Payment incomplete"
        },
        "status": {
          "cancel": "Cancel",
          "reactivate": "Reactivate",
          "upgrade": "Upgrade"
        }
      }
    },
    "users": {
      "_user": {
        "start_chat": "Start chat"
      },
      "index": {
        "_add": {
          "add": "Add"
        }
      }
    },
    "wizard": {
      "new": {
        "country": {
          "label": "Country",
          "placeholder": "Choose country..."
        },
        "currency": {
          "label": "Your currency",
          "placeholder": "Choose currency..."
        },
        "system_name": {
          "label": "Your business name",
          "placeholder": "Enter your business name..."
        },
        "time_zone": {
          "label": "Time zone",
          "placeholder": "Choose time zone..."
        },
        "your_business": "Tell us something about your business"
      },
      "show": {
        "choose_subdomain": "Choose your platform subdomain.",
        "own_address": "You will have your own dedicated address where people login to your video rooms.",
        "subdomain": {
          "label": "Your subdomain",
          "placeholder": "Enter subdomain..."
        }
      }
    }
  }
});
I18n.translations["sv"] = I18n.extend((I18n.translations["sv"] || {}), {
  "activerecord": {
    "attributes": {
      "room_member": {
        "entry_code": "Password",
        "name": "Name"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    },
    "models": {
      "user": {
        "one": "User",
        "other": "Users"
      }
    }
  },
  "common": {
    "are_you_sure": "Are you sure?",
    "cancel": "Cancel",
    "nothing_selected": "Nothing selected",
    "reset": "Reset password for",
    "submit": "Submit"
  },
  "controllers": {
    "admin": {
      "settings": {
        "create": {
          "notice": "Setting was successfully updated."
        }
      },
      "workspaces": {
        "update": {
          "saved": "Saved"
        }
      }
    },
    "workspaces": {
      "update": {
        "saved": "Settings saved."
      }
    }
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Second",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": "Not saved",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007fdecd8ecbd8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/rails-i18n-6.0.0/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    }
  },
  "javascript": {
    "controllers": {
      "subscriptions": {
        "calls_per_month": "%{count} calls",
        "subscribe": "Subscribe: %{price} / month"
      }
    }
  },
  "layouts": {
    "application": {
      "_app_header_right": {
        "change_password": "Change password",
        "profile": "Profile"
      }
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "#<Proc:0x00007fdecd8652c8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:26 (lambda)>",
      "ordinals": "#<Proc:0x00007fdecd8652f0@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:7 (lambda)>"
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "recaptcha": {
    "errors": {
      "recaptcha_unreachable": "Oops, we failed to validate your reCAPTCHA response. Please try again.",
      "verification_failed": "reCAPTCHA verification failed, please try again."
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "views": {
    "admin": {
      "settings": {
        "show": {
          "details": "Details",
          "fields": {
            "billing_section_on": {
              "label": "Billing section on"
            },
            "billings_off": {
              "label": "Billings off"
            },
            "bottom_back_button_on": "Show back button on bottom",
            "bottom_back_button_text": "Text",
            "bottom_back_button_url": "URL",
            "brand": {
              "label": "Brand name"
            },
            "brand_on": {
              "label": "Brand on"
            },
            "call_price": {
              "label": "Call price"
            },
            "calls_max_quantity": {
              "label": "Max calls quantity"
            },
            "calls_min_quantity": {
              "label": "Min calls quantity"
            },
            "free_calls_limit": {
              "label": "Free calls limit"
            },
            "intercom_app_id": {
              "label": "App ID"
            },
            "invite_button_on": {
              "label": "Invitation button on"
            },
            "login_on": {
              "label": "Login on"
            },
            "ocl_api_key": {
              "label": "API key"
            },
            "privacy_policy_url": {
              "label": "Privacy policy URL"
            },
            "registration_on": {
              "label": "Registration on"
            },
            "stripe_publishable_key": {
              "label": "Publishable key"
            },
            "stripe_secret_key": {
              "label": "Secret key"
            },
            "terms_url": {
              "label": "Terms URL"
            },
            "top_back_button_on": "Show back button on top",
            "top_back_button_text": "Text",
            "top_back_button_url": "URL",
            "trial_calls_limit": {
              "label": "Call limit"
            },
            "users_section_on": {
              "label": "Users section on"
            },
            "wiki_url": {
              "label": "Wiki URL"
            }
          },
          "pricing": "Pricing",
          "tabs": {
            "blocked_subdomains": "Blocked subdomains",
            "external_services": "External services",
            "general": "General",
            "interface": "Interface settings",
            "subscription": "Subscription"
          },
          "trial": "Trial",
          "update_settings": "Update settings"
        }
      },
      "users": {
        "_user": {
          "workspaces": "Workspaces"
        }
      },
      "workspaces": {
        "_workspace": {
          "edit": "Edit"
        }
      }
    },
    "apis": {
      "show": {
        "add_call": "Add call",
        "auth": "Authentication header: Api-Key",
        "available_at": "The API is available at %{url}",
        "introduction": "Introduction"
      }
    },
    "calls": {
      "_form": {
        "description": {
          "label": "Description (optional)",
          "placeholder": "Describe the reason of call"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name of your call"
        },
        "password": {
          "label": "Password (optional)",
          "placeholder": "If you want to protect entrance with password enter your pass here"
        },
        "submit": "Start new call"
      },
      "index": {
        "_active": {
          "created_at": "%{date} ago",
          "created_by": "by %{name}",
          "edit": "Edit",
          "end": "End",
          "ended": "Ended",
          "go_to_chat_room": "Go to chat room",
          "live": "Live"
        },
        "_inner_bar": {
          "active": "Active",
          "ended": "Ended"
        },
        "_inner_layout": {
          "_no_active_calls": {
            "no_active_calls": "No active calls",
            "schedule": "Schedule",
            "start_now": "Start now"
          },
          "_tab_active_calls": {
            "actions": "Actions",
            "created_at": "Created date",
            "members": "Members",
            "name": "Name",
            "status": "Status"
          },
          "_tab_ended_calls": {
            "members": "Members",
            "name": "Name",
            "no_ended_calls": "No ended calls"
          }
        },
        "_page_title": {
          "current_plan": "Your current plan: %{count}/%{limit} free calls.",
          "schedule": "Schedule",
          "start_now": "Start now",
          "start_video_chat": "Start video chat"
        }
      },
      "new": {
        "start_new_call": "Start a new call"
      },
      "schedule": {
        "schedule_new_call": "Schedule new call"
      }
    },
    "devise": {
      "passwords": {
        "new": {
          "reset_password": "Reset your password",
          "send_instructions": "Send reset password instructions",
          "sign_in": "Cancel"
        }
      },
      "registrations": {
        "new": {
          "accept_terms": "Accept terms and conditions",
          "already_have_account": "Already have an account?",
          "create": "Create account",
          "create_account": "Create new account",
          "create_schedule_talk": "Create, Schedule, Talk",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "few_seconds": "It only takes a few seconds to create your account",
          "login_off": "Signing in is temporarily unavailable",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "private_video": "We offer a full private video-calls with use of Peer-to-peer technology",
          "registration_off": "Registration is temporarily unavailable",
          "sign_in": "Sign in"
        }
      },
      "sessions": {
        "new": {
          "build_room": "Build your video room",
          "create_account": "Create account",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "login_to_dashboard": "Login to dashboard",
          "no_account": "No account?",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "please_sign_in": "Please sign in to your account.",
          "recover_password": "Recover password",
          "send_instructions": "Send instructions",
          "sign_in": "Sign in",
          "sign_up": "Sign up now",
          "up_to": "Talk up to 10 people in a private Peer-to-peer connection"
        }
      }
    },
    "layouts": {
      "_app_header_right": {
        "logout": "Logout"
      },
      "admin": {
        "application": {
          "admins": "Admins",
          "api": "API",
          "calls": "Calls",
          "dashboard": "Dashboard",
          "settings": "Settings",
          "sign_out": "Sign out",
          "users": "Users",
          "workspaces": "Workspaces"
        }
      },
      "application": {
        "_app_header_right": {
          "add_workspace": "Add workspace",
          "calls_limit": "Calls: %{count}/%{limit}",
          "change_password": "Change password",
          "logout": "Logout",
          "profile": "Profile"
        },
        "_footer": {
          "copyright": "Copyright %{year} by Getreve",
          "policy": "Privacy policy",
          "terms": "Terms & Conditions"
        },
        "_horizontal_nav_menu": {
          "api": "API",
          "billing": "Billing",
          "calls": "Calls",
          "settings": "Settings",
          "users": "Users",
          "wiki": "Wiki"
        }
      },
      "wizard": {
        "logout": "Logout"
      }
    },
    "profiles": {
      "_password_form": {
        "password": {
          "label": "New password"
        }
      }
    },
    "rooms": {
      "_message_form": {
        "write_here_and_hit_enter": "Write here and hit enter to send..."
      },
      "_messages": {
        "history_not_displayed": "Due to security the history of this conversation will not be dislayed."
      },
      "_pending_room_member": {
        "accept": "Accept",
        "reject": "Reject"
      },
      "_video_room": {
        "reconnect": "Reconnect",
        "waiting_for_acceptance": "Waiting for acceptance"
      },
      "enter": {
        "chat_blocked": "This chat has been blocked",
        "chat_unavailable": "Chat unavailable",
        "enter_room": "Enter room",
        "welcome_to_room": "Welcome to room"
      },
      "show": {
        "powered_by": "Powered by Vli"
      }
    },
    "subscriptions": {
      "_form": {
        "calls_per_month": "%{count} calls"
      },
      "_incomplete": {
        "status": {
          "incomplete": "Incomplete"
        }
      },
      "show": {
        "_active": {
          "active_until": "<h5 class=\"mb-0\">Subscription will remain active until <strong>%{date}</strong>. After that date your limits will be downgraded to <strong>%{free_limit}</strong> calls per month. Are you sure?</h5>",
          "cancel": "Cancel subscription",
          "status": "Active"
        },
        "_canceled": {
          "status": "Canceled "
        },
        "_free": {
          "limit": "%{limit} calls <br> <small class='text-secondary'>per month</small>",
          "status": {
            "free": "Free"
          },
          "upgrade": "Upgrade limit"
        },
        "_incomplete": {
          "accept": "Click to accept payment",
          "complete_payment": "Complete your payment to activate subscription",
          "status": "Payment incomplete"
        },
        "status": {
          "cancel": "Cancel",
          "reactivate": "Reactivate",
          "upgrade": "Upgrade"
        }
      }
    },
    "users": {
      "_user": {
        "start_chat": "Start chat"
      },
      "index": {
        "_add": {
          "add": "Add"
        }
      }
    },
    "wizard": {
      "new": {
        "country": {
          "label": "Country",
          "placeholder": "Choose country..."
        },
        "currency": {
          "label": "Your currency",
          "placeholder": "Choose currency..."
        },
        "system_name": {
          "label": "Your business name",
          "placeholder": "Enter your business name..."
        },
        "time_zone": {
          "label": "Time zone",
          "placeholder": "Choose time zone..."
        },
        "your_business": "Tell us something about your business"
      },
      "show": {
        "choose_subdomain": "Choose your platform subdomain.",
        "own_address": "You will have your own dedicated address where people login to your video rooms.",
        "subdomain": {
          "label": "Your subdomain",
          "placeholder": "Enter subdomain..."
        }
      }
    }
  }
});
I18n.translations["uk"] = I18n.extend((I18n.translations["uk"] || {}), {
  "activerecord": {
    "attributes": {
      "room_member": {
        "entry_code": "Password",
        "name": "Name"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    },
    "models": {
      "user": {
        "one": "User",
        "other": "Users"
      }
    }
  },
  "common": {
    "are_you_sure": "Are you sure?",
    "cancel": "Cancel",
    "nothing_selected": "Nothing selected",
    "reset": "Reset password for",
    "submit": "Submit"
  },
  "controllers": {
    "admin": {
      "settings": {
        "create": {
          "notice": "Setting was successfully updated."
        }
      },
      "workspaces": {
        "update": {
          "saved": "Saved"
        }
      }
    },
    "workspaces": {
      "update": {
        "saved": "Settings saved."
      }
    }
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Second",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": "Not saved",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007fdecd8ecbd8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/rails-i18n-6.0.0/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    }
  },
  "javascript": {
    "controllers": {
      "subscriptions": {
        "calls_per_month": "%{count} calls",
        "subscribe": "Subscribe: %{price} / month"
      }
    }
  },
  "layouts": {
    "application": {
      "_app_header_right": {
        "change_password": "Change password",
        "profile": "Profile"
      }
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "#<Proc:0x00007fdecd8652c8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:26 (lambda)>",
      "ordinals": "#<Proc:0x00007fdecd8652f0@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:7 (lambda)>"
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "recaptcha": {
    "errors": {
      "recaptcha_unreachable": "Oops, we failed to validate your reCAPTCHA response. Please try again.",
      "verification_failed": "reCAPTCHA verification failed, please try again."
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "views": {
    "admin": {
      "settings": {
        "show": {
          "details": "Details",
          "fields": {
            "billing_section_on": {
              "label": "Billing section on"
            },
            "billings_off": {
              "label": "Billings off"
            },
            "bottom_back_button_on": "Show back button on bottom",
            "bottom_back_button_text": "Text",
            "bottom_back_button_url": "URL",
            "brand": {
              "label": "Brand name"
            },
            "brand_on": {
              "label": "Brand on"
            },
            "call_price": {
              "label": "Call price"
            },
            "calls_max_quantity": {
              "label": "Max calls quantity"
            },
            "calls_min_quantity": {
              "label": "Min calls quantity"
            },
            "free_calls_limit": {
              "label": "Free calls limit"
            },
            "intercom_app_id": {
              "label": "App ID"
            },
            "invite_button_on": {
              "label": "Invitation button on"
            },
            "login_on": {
              "label": "Login on"
            },
            "ocl_api_key": {
              "label": "API key"
            },
            "privacy_policy_url": {
              "label": "Privacy policy URL"
            },
            "registration_on": {
              "label": "Registration on"
            },
            "stripe_publishable_key": {
              "label": "Publishable key"
            },
            "stripe_secret_key": {
              "label": "Secret key"
            },
            "terms_url": {
              "label": "Terms URL"
            },
            "top_back_button_on": "Show back button on top",
            "top_back_button_text": "Text",
            "top_back_button_url": "URL",
            "trial_calls_limit": {
              "label": "Call limit"
            },
            "users_section_on": {
              "label": "Users section on"
            },
            "wiki_url": {
              "label": "Wiki URL"
            }
          },
          "pricing": "Pricing",
          "tabs": {
            "blocked_subdomains": "Blocked subdomains",
            "external_services": "External services",
            "general": "General",
            "interface": "Interface settings",
            "subscription": "Subscription"
          },
          "trial": "Trial",
          "update_settings": "Update settings"
        }
      },
      "users": {
        "_user": {
          "workspaces": "Workspaces"
        }
      },
      "workspaces": {
        "_workspace": {
          "edit": "Edit"
        }
      }
    },
    "apis": {
      "show": {
        "add_call": "Add call",
        "auth": "Authentication header: Api-Key",
        "available_at": "The API is available at %{url}",
        "introduction": "Introduction"
      }
    },
    "calls": {
      "_form": {
        "description": {
          "label": "Description (optional)",
          "placeholder": "Describe the reason of call"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name of your call"
        },
        "password": {
          "label": "Password (optional)",
          "placeholder": "If you want to protect entrance with password enter your pass here"
        },
        "submit": "Start new call"
      },
      "index": {
        "_active": {
          "created_at": "%{date} ago",
          "created_by": "by %{name}",
          "edit": "Edit",
          "end": "End",
          "ended": "Ended",
          "go_to_chat_room": "Go to chat room",
          "live": "Live"
        },
        "_inner_bar": {
          "active": "Active",
          "ended": "Ended"
        },
        "_inner_layout": {
          "_no_active_calls": {
            "no_active_calls": "No active calls",
            "schedule": "Schedule",
            "start_now": "Start now"
          },
          "_tab_active_calls": {
            "actions": "Actions",
            "created_at": "Created date",
            "members": "Members",
            "name": "Name",
            "status": "Status"
          },
          "_tab_ended_calls": {
            "members": "Members",
            "name": "Name",
            "no_ended_calls": "No ended calls"
          }
        },
        "_page_title": {
          "current_plan": "Your current plan: %{count}/%{limit} free calls.",
          "schedule": "Schedule",
          "start_now": "Start now",
          "start_video_chat": "Start video chat"
        }
      },
      "new": {
        "start_new_call": "Start a new call"
      },
      "schedule": {
        "schedule_new_call": "Schedule new call"
      }
    },
    "devise": {
      "passwords": {
        "new": {
          "reset_password": "Reset your password",
          "send_instructions": "Send reset password instructions",
          "sign_in": "Cancel"
        }
      },
      "registrations": {
        "new": {
          "accept_terms": "Accept terms and conditions",
          "already_have_account": "Already have an account?",
          "create": "Create account",
          "create_account": "Create new account",
          "create_schedule_talk": "Create, Schedule, Talk",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "few_seconds": "It only takes a few seconds to create your account",
          "login_off": "Signing in is temporarily unavailable",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "private_video": "We offer a full private video-calls with use of Peer-to-peer technology",
          "registration_off": "Registration is temporarily unavailable",
          "sign_in": "Sign in"
        }
      },
      "sessions": {
        "new": {
          "build_room": "Build your video room",
          "create_account": "Create account",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "login_to_dashboard": "Login to dashboard",
          "no_account": "No account?",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "please_sign_in": "Please sign in to your account.",
          "recover_password": "Recover password",
          "send_instructions": "Send instructions",
          "sign_in": "Sign in",
          "sign_up": "Sign up now",
          "up_to": "Talk up to 10 people in a private Peer-to-peer connection"
        }
      }
    },
    "layouts": {
      "_app_header_right": {
        "logout": "Logout"
      },
      "admin": {
        "application": {
          "admins": "Admins",
          "api": "API",
          "calls": "Calls",
          "dashboard": "Dashboard",
          "settings": "Settings",
          "sign_out": "Sign out",
          "users": "Users",
          "workspaces": "Workspaces"
        }
      },
      "application": {
        "_app_header_right": {
          "add_workspace": "Add workspace",
          "calls_limit": "Calls: %{count}/%{limit}",
          "change_password": "Change password",
          "logout": "Logout",
          "profile": "Profile"
        },
        "_footer": {
          "copyright": "Copyright %{year} by Getreve",
          "policy": "Privacy policy",
          "terms": "Terms & Conditions"
        },
        "_horizontal_nav_menu": {
          "api": "API",
          "billing": "Billing",
          "calls": "Calls",
          "settings": "Settings",
          "users": "Users",
          "wiki": "Wiki"
        }
      },
      "wizard": {
        "logout": "Logout"
      }
    },
    "profiles": {
      "_password_form": {
        "password": {
          "label": "New password"
        }
      }
    },
    "rooms": {
      "_message_form": {
        "write_here_and_hit_enter": "Write here and hit enter to send..."
      },
      "_messages": {
        "history_not_displayed": "Due to security the history of this conversation will not be dislayed."
      },
      "_pending_room_member": {
        "accept": "Accept",
        "reject": "Reject"
      },
      "_video_room": {
        "reconnect": "Reconnect",
        "waiting_for_acceptance": "Waiting for acceptance"
      },
      "enter": {
        "chat_blocked": "This chat has been blocked",
        "chat_unavailable": "Chat unavailable",
        "enter_room": "Enter room",
        "welcome_to_room": "Welcome to room"
      },
      "show": {
        "powered_by": "Powered by Vli"
      }
    },
    "subscriptions": {
      "_form": {
        "calls_per_month": "%{count} calls"
      },
      "_incomplete": {
        "status": {
          "incomplete": "Incomplete"
        }
      },
      "show": {
        "_active": {
          "active_until": "<h5 class=\"mb-0\">Subscription will remain active until <strong>%{date}</strong>. After that date your limits will be downgraded to <strong>%{free_limit}</strong> calls per month. Are you sure?</h5>",
          "cancel": "Cancel subscription",
          "status": "Active"
        },
        "_canceled": {
          "status": "Canceled "
        },
        "_free": {
          "limit": "%{limit} calls <br> <small class='text-secondary'>per month</small>",
          "status": {
            "free": "Free"
          },
          "upgrade": "Upgrade limit"
        },
        "_incomplete": {
          "accept": "Click to accept payment",
          "complete_payment": "Complete your payment to activate subscription",
          "status": "Payment incomplete"
        },
        "status": {
          "cancel": "Cancel",
          "reactivate": "Reactivate",
          "upgrade": "Upgrade"
        }
      }
    },
    "users": {
      "_user": {
        "start_chat": "Start chat"
      },
      "index": {
        "_add": {
          "add": "Add"
        }
      }
    },
    "wizard": {
      "new": {
        "country": {
          "label": "Country",
          "placeholder": "Choose country..."
        },
        "currency": {
          "label": "Your currency",
          "placeholder": "Choose currency..."
        },
        "system_name": {
          "label": "Your business name",
          "placeholder": "Enter your business name..."
        },
        "time_zone": {
          "label": "Time zone",
          "placeholder": "Choose time zone..."
        },
        "your_business": "Tell us something about your business"
      },
      "show": {
        "choose_subdomain": "Choose your platform subdomain.",
        "own_address": "You will have your own dedicated address where people login to your video rooms.",
        "subdomain": {
          "label": "Your subdomain",
          "placeholder": "Enter subdomain..."
        }
      }
    }
  }
});
I18n.translations["zh"] = I18n.extend((I18n.translations["zh"] || {}), {
  "activerecord": {
    "attributes": {
      "room_member": {
        "entry_code": "Password",
        "name": "Name"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      }
    },
    "models": {
      "user": {
        "one": "User",
        "other": "Users"
      }
    }
  },
  "common": {
    "are_you_sure": "Are you sure?",
    "cancel": "Cancel",
    "nothing_selected": "Nothing selected",
    "reset": "Reset password for",
    "submit": "Submit"
  },
  "controllers": {
    "admin": {
      "settings": {
        "create": {
          "notice": "Setting was successfully updated."
        }
      },
      "workspaces": {
        "update": {
          "saved": "Saved"
        }
      }
    },
    "workspaces": {
      "update": {
        "saved": "Settings saved."
      }
    }
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_years": {
        "one": "1 year",
        "other": "%{count} years"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Second",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "invalid_currency": "must be a valid currency (eg. '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": "Not saved",
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "template": {
      "body": "There were problems with the following fields:",
      "header": {
        "one": "1 error prohibited this %{model} from being saved",
        "other": "%{count} errors prohibited this %{model} from being saved"
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "i18n": {
    "plural": {
      "keys": [
        "one",
        "other"
      ],
      "rule": "#<Proc:0x00007fdecd8ecbd8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/rails-i18n-6.0.0/lib/rails_i18n/common_pluralizations/one_other.rb:7 (lambda)>"
    }
  },
  "javascript": {
    "controllers": {
      "subscriptions": {
        "calls_per_month": "%{count} calls",
        "subscribe": "Subscribe: %{price} / month"
      }
    }
  },
  "layouts": {
    "application": {
      "_app_header_right": {
        "change_password": "Change password",
        "profile": "Profile"
      }
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
      "ordinalized": "#<Proc:0x00007fdecd8652c8@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:26 (lambda)>",
      "ordinals": "#<Proc:0x00007fdecd8652f0@/Users/jarek/.rvm/gems/ruby-2.5.7/gems/activesupport-6.1.2/lib/active_support/locale/en.rb:7 (lambda)>"
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "recaptcha": {
    "errors": {
      "recaptcha_unreachable": "Oops, we failed to validate your reCAPTCHA response. Please try again.",
      "verification_failed": "reCAPTCHA verification failed, please try again."
    }
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "views": {
    "admin": {
      "settings": {
        "show": {
          "details": "Details",
          "fields": {
            "billing_section_on": {
              "label": "Billing section on"
            },
            "billings_off": {
              "label": "Billings off"
            },
            "bottom_back_button_on": "Show back button on bottom",
            "bottom_back_button_text": "Text",
            "bottom_back_button_url": "URL",
            "brand": {
              "label": "Brand name"
            },
            "brand_on": {
              "label": "Brand on"
            },
            "call_price": {
              "label": "Call price"
            },
            "calls_max_quantity": {
              "label": "Max calls quantity"
            },
            "calls_min_quantity": {
              "label": "Min calls quantity"
            },
            "free_calls_limit": {
              "label": "Free calls limit"
            },
            "intercom_app_id": {
              "label": "App ID"
            },
            "invite_button_on": {
              "label": "Invitation button on"
            },
            "login_on": {
              "label": "Login on"
            },
            "ocl_api_key": {
              "label": "API key"
            },
            "privacy_policy_url": {
              "label": "Privacy policy URL"
            },
            "registration_on": {
              "label": "Registration on"
            },
            "stripe_publishable_key": {
              "label": "Publishable key"
            },
            "stripe_secret_key": {
              "label": "Secret key"
            },
            "terms_url": {
              "label": "Terms URL"
            },
            "top_back_button_on": "Show back button on top",
            "top_back_button_text": "Text",
            "top_back_button_url": "URL",
            "trial_calls_limit": {
              "label": "Call limit"
            },
            "users_section_on": {
              "label": "Users section on"
            },
            "wiki_url": {
              "label": "Wiki URL"
            }
          },
          "pricing": "Pricing",
          "tabs": {
            "blocked_subdomains": "Blocked subdomains",
            "external_services": "External services",
            "general": "General",
            "interface": "Interface settings",
            "subscription": "Subscription"
          },
          "trial": "Trial",
          "update_settings": "Update settings"
        }
      },
      "users": {
        "_user": {
          "workspaces": "Workspaces"
        }
      },
      "workspaces": {
        "_workspace": {
          "edit": "Edit"
        }
      }
    },
    "apis": {
      "show": {
        "add_call": "Add call",
        "auth": "Authentication header: Api-Key",
        "available_at": "The API is available at %{url}",
        "introduction": "Introduction"
      }
    },
    "calls": {
      "_form": {
        "description": {
          "label": "Description (optional)",
          "placeholder": "Describe the reason of call"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name of your call"
        },
        "password": {
          "label": "Password (optional)",
          "placeholder": "If you want to protect entrance with password enter your pass here"
        },
        "submit": "Start new call"
      },
      "index": {
        "_active": {
          "created_at": "%{date} ago",
          "created_by": "by %{name}",
          "edit": "Edit",
          "end": "End",
          "ended": "Ended",
          "go_to_chat_room": "Go to chat room",
          "live": "Live"
        },
        "_inner_bar": {
          "active": "Active",
          "ended": "Ended"
        },
        "_inner_layout": {
          "_no_active_calls": {
            "no_active_calls": "No active calls",
            "schedule": "Schedule",
            "start_now": "Start now"
          },
          "_tab_active_calls": {
            "actions": "Actions",
            "created_at": "Created date",
            "members": "Members",
            "name": "Name",
            "status": "Status"
          },
          "_tab_ended_calls": {
            "members": "Members",
            "name": "Name",
            "no_ended_calls": "No ended calls"
          }
        },
        "_page_title": {
          "current_plan": "Your current plan: %{count}/%{limit} free calls.",
          "schedule": "Schedule",
          "start_now": "Start now",
          "start_video_chat": "Start video chat"
        }
      },
      "new": {
        "start_new_call": "Start a new call"
      },
      "schedule": {
        "schedule_new_call": "Schedule new call"
      }
    },
    "devise": {
      "passwords": {
        "new": {
          "reset_password": "Reset your password",
          "send_instructions": "Send reset password instructions",
          "sign_in": "Cancel"
        }
      },
      "registrations": {
        "new": {
          "accept_terms": "Accept terms and conditions",
          "already_have_account": "Already have an account?",
          "create": "Create account",
          "create_account": "Create new account",
          "create_schedule_talk": "Create, Schedule, Talk",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "few_seconds": "It only takes a few seconds to create your account",
          "login_off": "Signing in is temporarily unavailable",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "private_video": "We offer a full private video-calls with use of Peer-to-peer technology",
          "registration_off": "Registration is temporarily unavailable",
          "sign_in": "Sign in"
        }
      },
      "sessions": {
        "new": {
          "build_room": "Build your video room",
          "create_account": "Create account",
          "email": {
            "label": "Email",
            "placeholder": "Email here..."
          },
          "login_to_dashboard": "Login to dashboard",
          "no_account": "No account?",
          "password": {
            "label": "Password",
            "placeholder": "Password here..."
          },
          "please_sign_in": "Please sign in to your account.",
          "recover_password": "Recover password",
          "send_instructions": "Send instructions",
          "sign_in": "Sign in",
          "sign_up": "Sign up now",
          "up_to": "Talk up to 10 people in a private Peer-to-peer connection"
        }
      }
    },
    "layouts": {
      "_app_header_right": {
        "logout": "Logout"
      },
      "admin": {
        "application": {
          "admins": "Admins",
          "api": "API",
          "calls": "Calls",
          "dashboard": "Dashboard",
          "settings": "Settings",
          "sign_out": "Sign out",
          "users": "Users",
          "workspaces": "Workspaces"
        }
      },
      "application": {
        "_app_header_right": {
          "add_workspace": "Add workspace",
          "calls_limit": "Calls: %{count}/%{limit}",
          "change_password": "Change password",
          "logout": "Logout",
          "profile": "Profile"
        },
        "_footer": {
          "copyright": "Copyright %{year} by Getreve",
          "policy": "Privacy policy",
          "terms": "Terms & Conditions"
        },
        "_horizontal_nav_menu": {
          "api": "API",
          "billing": "Billing",
          "calls": "Calls",
          "settings": "Settings",
          "users": "Users",
          "wiki": "Wiki"
        }
      },
      "wizard": {
        "logout": "Logout"
      }
    },
    "profiles": {
      "_password_form": {
        "password": {
          "label": "New password"
        }
      }
    },
    "rooms": {
      "_message_form": {
        "write_here_and_hit_enter": "Write here and hit enter to send..."
      },
      "_messages": {
        "history_not_displayed": "Due to security the history of this conversation will not be dislayed."
      },
      "_pending_room_member": {
        "accept": "Accept",
        "reject": "Reject"
      },
      "_video_room": {
        "reconnect": "Reconnect",
        "waiting_for_acceptance": "Waiting for acceptance"
      },
      "enter": {
        "chat_blocked": "This chat has been blocked",
        "chat_unavailable": "Chat unavailable",
        "enter_room": "Enter room",
        "welcome_to_room": "Welcome to room"
      },
      "show": {
        "powered_by": "Powered by Vli"
      }
    },
    "subscriptions": {
      "_form": {
        "calls_per_month": "%{count} calls"
      },
      "_incomplete": {
        "status": {
          "incomplete": "Incomplete"
        }
      },
      "show": {
        "_active": {
          "active_until": "<h5 class=\"mb-0\">Subscription will remain active until <strong>%{date}</strong>. After that date your limits will be downgraded to <strong>%{free_limit}</strong> calls per month. Are you sure?</h5>",
          "cancel": "Cancel subscription",
          "status": "Active"
        },
        "_canceled": {
          "status": "Canceled "
        },
        "_free": {
          "limit": "%{limit} calls <br> <small class='text-secondary'>per month</small>",
          "status": {
            "free": "Free"
          },
          "upgrade": "Upgrade limit"
        },
        "_incomplete": {
          "accept": "Click to accept payment",
          "complete_payment": "Complete your payment to activate subscription",
          "status": "Payment incomplete"
        },
        "status": {
          "cancel": "Cancel",
          "reactivate": "Reactivate",
          "upgrade": "Upgrade"
        }
      }
    },
    "users": {
      "_user": {
        "start_chat": "Start chat"
      },
      "index": {
        "_add": {
          "add": "Add"
        }
      }
    },
    "wizard": {
      "new": {
        "country": {
          "label": "Country",
          "placeholder": "Choose country..."
        },
        "currency": {
          "label": "Your currency",
          "placeholder": "Choose currency..."
        },
        "system_name": {
          "label": "Your business name",
          "placeholder": "Enter your business name..."
        },
        "time_zone": {
          "label": "Time zone",
          "placeholder": "Choose time zone..."
        },
        "your_business": "Tell us something about your business"
      },
      "show": {
        "choose_subdomain": "Choose your platform subdomain.",
        "own_address": "You will have your own dedicated address where people login to your video rooms.",
        "subdomain": {
          "label": "Your subdomain",
          "placeholder": "Enter subdomain..."
        }
      }
    }
  }
});
