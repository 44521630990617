import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect () {
    super.connect()
  }

  apiKeyFormSuccess() {
    this.stimulate('AdminApis#api_keys')
    this.hideModal('#api-key-modal')
  }
}
