import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
import Rails from '@rails/ujs';
import $ from 'jquery';

export default class extends Controller {
  connect () {
    StimulusReflex.register(this)
    $('[data-toggle="dropdown"]').dropdown();
    this.initConfirmModal()
  }

  initConfirmModal() {
  
  }

  /* Application-wide lifecycle methods
   *
   * Use these methods to handle lifecycle concerns for the entire application.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Example#demo"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  beforeReflex (element, reflex, noop, reflexId) {
    // document.body.classList.add('wait')
  }

  reflexSuccess (element, reflex, noop, reflexId) {
    // show success message etc...
  }

  reflexError (element, reflex, error, reflexId) {
    // show error message etc...
  }

  afterReflex (element, reflex, noop, reflexId) {
    // document.body.classList.remove('wait')
  }

  showModal(event) {
    var modal = $(event.detail[2].response)
    $('body').append(modal)
    modal.modal('show').on('hidden.bs.modal', function() {
      modal.remove()
    })
  }

  hideModal(selector) {
    var modal_element = $(selector)
    modal_element.on('hidden.bs.modal', ()=> {
      modal_element.remove()
    })
    modal_element.modal('hide')
  }
}
