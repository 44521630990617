import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Invitations Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = [ "invitationModal" ]

  connect () {
    super.connect()
    // add your code here, if applicable
  }

  /* Reflex specific lifecycle methods.
   *
   * For every method defined in your Reflex class, a matching set of lifecycle methods become available
   * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
   * need them.
   *
   * Important:
   * Make sure to add data-controller="invitations" to your markup alongside
   * data-reflex="Invitations#dance" for the lifecycle methods to fire properly.
   *
   * Example:
   *
   *   <a href="#" data-reflex="click->Invitations#dance" data-controller="invitations">Dance!</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Invitations#dance"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  // Assuming you create a "Invitations#dance" action in your Reflex class
  // you'll be able to use the following lifecycle methods:

  // beforeDance(element, reflex, noop, reflexId) {
  //  element.innerText = 'Putting dance shoes on...'
  // }

  refreshSuccess(element, reflex, noop, reflexId) {
    $('#invitation-modal').modal('hide')
  }

  showModal(event) {
    $('body').append(event.detail[2].response)
    const modal = $('#invitation-modal')
    modal.modal('show').on('hidden.bs.modal', function() {
      modal.remove()
    })
  }

  showErrors(event) {
    $('#new_invitation').replaceWith(event.detail[2].response)
  }
}
