import ApplicationController from './application_controller'
import consumer from "../channels/consumer";

export default class extends ApplicationController {
  static targets = [ 'join' ]

  connect () {
    super.connect()
    this.currentUser = JSON.parse(this.data.get('member'));
    console.log(this.currentUser)
    this.token = this.data.get('id')
    if (this.currentUser.accepted) {
      this.initRoom()
    } else {
      this.initializePendingConnection()
    }
  }

  initializePendingConnection() {
    this.channel = consumer.subscriptions.create(
      { channel: "RoomChannel", token: this.token, uuid: this.currentUser.uuid },
      {
        connected: () => {
          this.sendPending()
        },
        received: (data) => {
          if (data.from.uuid !== this.currentUser.uuid) {
            if (data.type == 'speak' ) return;
          }
          switch(data.type) {
            case 'join_room':
              this.channel.send({ from: this.currentUser, type: 'pending' });
              break;
            case 'accepted':
              this.refresh()
              break;
            case 'rejected':
              this.refresh()
              break;
            case 'room_blocked':
              this.refresh()
              break;
            case 'disconnected':
              this.removeUser(data.from)
              break;
          }
        },
      }
    )
  }

  sendPending() {
    this.channel.send({ from: this.currentUser, type: 'pending' });
  }

  initRoom() {
    this.pcPeers = {};
    this.speechTimeouts = {};
    this.localstream;
    this.localAudio = document.getElementById("local-audio");
    this.soundPath = this.data.get('sound-path')
    this.ice = {
      iceServers: [
        {
          urls: 'turn:turn.vli.to:3478?transport=tcp',
          username: 'test',
          credential: 'test'
        },
        {
          urls: 'turn:turn.vli.to:3478?transport=udp',
          username: 'test',
          credential: 'test'
        },
        {
          urls: 'turn:turn.vli.to:3479?transport=tcp',
          username: 'test',
          credential: 'test'
        },
        {
          urls: 'turn:turn.vli.to:3479?transport=udp',
          username: 'test',
          credential: 'test'
        }
      ]
    }
    this.audioContainer = document.getElementById("audio-container");
    this.initializeLocalAudio()
  }

  refresh() {
    window.location.reload();
  }
  softRefresh() {
    this.stimulate('Room')
  }

  initializeLocalAudio () {
    if (document.readyState === "interactive") {
      navigator.mediaDevices
        .getUserMedia({
          audio: {
            autoGainControl: false,
            channelCount: 2,
            echoCancellation: false,
            latency: 0,
            noiseSuppression: false,
            sampleRate: 48000,
            sampleSize: 16,
            volume: 1.0
          },
          video: false
        })
        .then((stream) => {
          this.localstream = stream;
          this.localAudio.srcObject = stream;
          this.localAudio.setAttribute('playsinline', '');
          this.localAudio.setAttribute('autoplay', '');
          this.localAudio.setAttribute('muted', '');
          this.localstream.getAudioTracks()[0].enabled = this.currentUser.audio_on
          this.initializeConnection()
          window.AudioContext = window.AudioContext || window.webkitAudioContext;
          window.audioContext = new AudioContext();
          this.enableSoundMeter(stream);
        }).catch((error) => {
          console.error(error);
        });
    }
  }

  removeTracks() {
    Object.keys(this.pcPeers).forEach( (key) => {
      for (const track of this.localstream.getTracks()) {
        this.pcPeers[key].removeTrack(track, this.localstream);
      }
    })
  }

  broadcastReconnect() {
    this.channel.send(
      { from: this.currentUser, type: 'reconnect' }
    )
  }

  initializeAudio() {
    navigator.mediaDevices
    .getUserMedia({
      audio: true, video: false
    })
    .then((stream) => {
      if (this.localstream) {
        this.localstream.addTrack(stream.getAudioTracks()[0]);
      } else {
        this.localstream = stream
      }
      this.localstream.getAudioTracks()[0].enabled = this.currentUser.audio_on
    }).catch((error) => {
      console.error(error);
    });
  }

  mute() {
    if (this.localstream != null && this.localstream.getAudioTracks().length > 0) {
      this.localstream.getAudioTracks()[0].enabled = false
      this.currentUser.audio_on = false
      this.channel.send({ from: this.currentUser, type: 'status' });
    }
  }

  unmute() {
    if (this.localstream != null && this.localstream.getAudioTracks().length > 0) {
      this.localstream.getAudioTracks()[0].enabled = true
      this.currentUser.audio_on = true
      this.channel.send({ from: this.currentUser, type: 'status' });
    }
  }

  initializeConnection() {
    this.channel = consumer.subscriptions.create(
      { channel: "RoomChannel", token: this.token, uuid: this.currentUser.uuid },
      {
        connected: () => {
          this.joinRoom()
        },
        received: (data) => {
          switch(data.type) {
            case 'join_room':
              if (data.from.uuid === this.currentUser.uuid) return
              if (this.pcPeers[data.from.uuid]) {
                this.removeUser(data.from)
              }
              return this.createOffer(data)
            case 'offer':
              if (this.pcPeers[data.from.uuid]) return;
              return this.createAnswer(data)
            case 'answer':
              return this.acceptAnswer(data)
            case 'candidate':
              return this.addCandidate(data)
            case 'speak':
              if (data.from.uuid === this.currentUser.uuid) return
              return this.markSpeaker(data)
            case 'status':
              return this.setStatus(data)
            case 'pending':
              return this.showPendingUser(data)
            case 'room_blocked':
              this.refresh()
              break;
            case 'disconnected':
              return this.removeUser(data.from)
            case 'reconnect':
              if (data.from.uuid === this.currentUser.uuid) return
              this.removeUser(data.from)
              return this.joinRoom()
          }
        },
      }
    );
  };

  setStatus(data) {
    let container = $(`#audio_container_${data.from.uuid}`)
    if (data.from.video_on) {
      container.find('.status .video').addClass('d-none')
    } else {
      container.find('.status .video').removeClass('d-none')
    }
    if (data.from.audio_on) {
      container.find('.status .audio').addClass('d-none')
    } else {
      container.find('.status .audio').removeClass('d-none')
    }
  }

  markSpeaker(data) {
    let container = document.getElementById(`audio_container_${data.from.uuid}`)
    if (container === null) {
      return
    }
    if (this.speechTimeouts[data.from.uuid]) {
      clearTimeout(this.speechTimeouts[data.from.uuid])
    }
    container.classList.add('speaking');
    this.speechTimeouts[data.from.uuid] = setTimeout(
      function() {
        container.classList.remove('speaking')
      }, 500
    );
  }

  joinRoom() {
    let channel = this.channel
    let currentUser = this.currentUser
    channel.send(
      { from: currentUser, type: 'join_room' }
    )
  }

  createOffer(data) {
    var audio = new Audio(this.soundPath);
    audio.play()
    let peerConnection = new RTCPeerConnection(this.ice);;
    this.pcPeers[data.from.uuid] = peerConnection
    for (const track of this.localstream.getTracks()) {
      peerConnection.addTrack(track, this.localstream);
    }
    this.setPeerConnectionEvents(peerConnection, data.from)
    peerConnection.createOffer()
                  .then((offer) => {
                    peerConnection.setLocalDescription(offer).then(
                      () => {
                        this.channel.send(
                          {
                            from: this.currentUser,
                            to: data.from,
                            offer: JSON.stringify(peerConnection.localDescription),
                            type: 'offer'
                          }
                        );
                      }
                    )
                  })
  }

  createAnswer(data) {
    const peerConnection = new RTCPeerConnection(this.ice);
    this.pcPeers[data.from.uuid] = peerConnection
    for (const track of this.localstream.getTracks()) {
      peerConnection.addTrack(track, this.localstream);
    }

    const sdp = JSON.parse(data.offer);
    peerConnection.setRemoteDescription(new RTCSessionDescription(sdp))
    this.setPeerConnectionEvents(peerConnection, data.from);
    peerConnection.createAnswer()
                  .then((answer) => {
                    answer.sdp = answer.sdp.replace('useinbandfec=1', 'useinbandfec=1; stereo=1; maxaveragebitrate=510000');
                    peerConnection.setLocalDescription(answer).then(
                      () => {
                        this.channel.send(
                          {
                            from: this.currentUser,
                            to: data.from,
                            answer: answer,
                            type: 'answer'
                          }
                        );
                      }
                    )
                  })
  }

  reconnectStream(data) {
    if (this.pcPeers[data.from.uuid]) {
      this.removeUser(data.from)
    }
    return this.createOffer(data)
  }

  acceptAnswer(data) {
    const remoteDesc = new RTCSessionDescription(data.answer);
    this.pcPeers[data.from.uuid].setRemoteDescription(remoteDesc);
  }

  setPeerConnectionEvents(peerConnection, user) {
    peerConnection.addEventListener('icecandidate', event => {
      if (event.candidate) {
        this.channel.send(
          { type: 'candidate', from: this.currentUser, to: user, candidate: event.candidate}
        );
      }
    });

    peerConnection.addEventListener('connectionstatechange', event => {
      if (peerConnection.connectionState === 'connected') {
        return;
      }
      if (peerConnection.connectionState === 'disconnected') {
        this.removeUser(user)
        return;
      }
    });

    peerConnection.addEventListener('track', event => {
      let id = `audio_container_${user.uuid}`
      let container = document.getElementById(id)
      if (container && container.classList.contains('pending')) {
        container.remove()
      } else if (container) {
        container.getElementsByTagName('audio').srcObject = event.streams[0];
        return;
      }
      let element = document.createElement("div");
      element.id = id;
      element.className = 'member col-4 col-xs-4 col-md-3'

      let memberInner = document.createElement("div");
      memberInner.className = 'member-inner';
      memberInner.style.backgroundImage = `url(${user.avatar_url})`;
      element.appendChild(memberInner);

      let content = document.createElement("div");
      content.className = 'content'
      memberInner.appendChild(content)

      let audio = document.createElement("audio");
      audio.autoplay = "autoplay";
      audio.srcObject = event.streams[0];
      content.appendChild(audio)
      this.audioContainer.appendChild(element);
      audio.setAttribute('playsinline', '');
      audio.setAttribute('autoplay', '');

      let name = document.createElement('div')
      name.className = 'name'
      name.innerHTML = user.name
      console.log(name)
      element.appendChild(name)
    });
  }

  showPendingUser(data) {
    let user = data.from
    let id = `audio_container_${user.uuid}`
    let container = document.getElementById(id)
    if (container) {
      container(remove)
    }
    let element = document.createElement("div")
    element.className = 'member pending'
    element.id = id
    this.audioContainer.appendChild(element);
    this.stimulate('Room#pending_room_member', user.uuid)
  }

  accepted(event) {
    this.channel.send(
      {
        from: this.currentUser,
        type: 'accepted',
        to: { uuid: event.target.dataset.uuid }
      }
    )
  }

  reject(event) {
    this.channel.send(
      {
        from: this.currentUser,
        type: 'rejected',
        to: { uuid: event.target.dataset.uuid }
      }
    )
  }

  afterPendingRoomMember() {
    this.setVideoLayout();
  }

  statusElement(user) {
    const status = document.createElement("div");
    const video_icon = document.createElement("i");
    const audio_icon = document.createElement("i");
    status.className = "status"
    if (user.video_on) {
      video_icon.className = "fas fa-video-slash video d-none"
    } else {
      video_icon.className = "fas fa-video-slash video"
    }
    if (user.audio_on) {
      audio_icon.className = "fas fa-microphone-slash audio d-none"
    } else {
      audio_icon.className = "fas fa-microphone-slash audio"
    }
    status.appendChild(audio_icon);
    status.appendChild(video_icon);
    return status;
  }

  addCandidate(data) {
    let peerConnection = this.pcPeers[data.from.uuid]
    if (!peerConnection) return;
    peerConnection.addIceCandidate(data.candidate)
                  .catch((error) => {
                    console.log(error);
                  });
  }

  createNameElement(user) {
    let element = document.createElement('div');
    element.className = 'member-name'
    element.innerHTML = user.name;
    return element;
  }

  removeUser(user) {
    let video = document.getElementById(`audio_container_${user.uuid}`);
    if (video) {
      video.remove();
    }
    if (this.pcPeers[user.uuid]) {
      this.pcPeers[user.uuid].close()
      delete this.pcPeers[user.uuid];
    }
    this.setVideoLayout();
  };

  handleLeaveSession = () => {};

  enableSoundMeter(stream) {
    window.stream = stream;
    this.soundMeter();
    this.mic = window.audioContext.createMediaStreamSource(stream);
    this.mic.connect(this.script);
    this.script.connect(window.audioContext.destination);
  }

  soundMeter() {
    this.script = window.audioContext.createScriptProcessor(2048, 1, 1);
    let instant = 0.0;
    let channel = this.channel
    let currentUser = this.currentUser
    this.script.onaudioprocess = function(event) {
      const input = event.inputBuffer.getChannelData(0);
      let i;
      let sum = 0.0;
      let clipcount = 0;
      for (i = 0; i < input.length; ++i) {
        sum += input[i] * input[i];
        if (Math.abs(input[i]) > 0.99) {
          clipcount += 1;
        }
      }
      let instant = Math.sqrt(sum / input.length).toFixed(4);
      if (instant > 0.005) {
        channel.send(
          { from: currentUser, type: 'speak', level: instant }
        )
      }
    };
  }

  setVideoLayout() {
    let members = document.getElementsByClassName('member')
    Array.from(members).forEach((el) => el.classList.remove('col-6', 'col-5', 'col-4', 'col-3'))
    switch (members.length) {
      case 1:
        Array.from(members).forEach((el) => el.classList.add('col-6'))
        break;
      case 2:
        Array.from(members).forEach((el) => el.classList.add('col-6'))
        break;
      case 3:
        Array.from(members).forEach((el) => el.classList.add('col-5'))
        break;
      case 4:
        Array.from(members).forEach((el) => el.classList.add('col-6'))
        break;
      case 5:
        Array.from(members).forEach((el) => el.classList.add('col-4'))
        break;
      case 6:
        Array.from(members).forEach((el) => el.classList.add('col-4'))
        break;
      case 7:
        Array.from(members).forEach((el) => el.classList.add('col-3'))
        break;
      case 8:
        Array.from(members).forEach((el) => el.classList.add('col-3'))
        break;
    }
  }
}
