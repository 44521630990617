import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect () {
    super.connect()
    this.initConfirmModal()
  }

  showErrors(event) {
    $('#new_banned_subdomain').replaceWith(event.detail[2].response)
  }

  bannedSubdomainFormSuccess() {
    this.stimulate('AdminSettings#api_keys')
    this.hideModal('#api-key-modal')
  }
}
