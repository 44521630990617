import ApplicationController from './application_controller'
import consumer from "../channels/consumer";
/* This is the custom StimulusReflex controller for the Room Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */

export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  static targets = [ 'join' ]


  connect () {
    super.connect()
    this.currentUser = JSON.parse(this.data.get('member'));
    this.token = this.data.get('id')
    if (this.currentUser.accepted) {
      this.initRoom()
    } else {
      this.initializePendingConnection()
    }
  }

  initializePendingConnection() {
    this.channel = consumer.subscriptions.create(
      { channel: "RoomChannel", token: this.token, uuid: this.currentUser.uuid },
      {
        connected: () => {
          this.sendPending()
        },
        received: (data) => {
          if (data.from.uuid !== this.currentUser.uuid) {
            if (data.type == 'speak' ) return;
          }
          switch(data.type) {
            case 'join_room':
              this.channel.send({ from: this.currentUser, type: 'pending' });
              break;
            case 'accepted':
              this.refresh()
              break;
            case 'rejected':
              this.refresh()
              break;
            case 'room_blocked':
              this.refresh()
              break;
            case 'disconnected':
              this.removeUser(data.from)
              break;
          }
        },
      }
    )
  }

  sendPending() {
    this.channel.send({ from: this.currentUser, type: 'pending' });
  }

  initRoom() {
    this.pcPeers = {};
    this.speechTimeouts = {};
    this.localstream;
    this.localVideo = document.getElementById("local-video");
    this.soundPath = this.data.get('sound-path')
    this.ice = {
      iceServers: [
        {
          urls: 'turn:turn.vli.to:3478?transport=tcp',
          username: 'test',
          credential: 'test'
        },
        {
          urls: 'turn:turn.vli.to:3478?transport=udp',
          username: 'test',
          credential: 'test'
        },
        {
          urls: 'turn:turn.vli.to:3479?transport=tcp',
          username: 'test',
          credential: 'test'
        },
        {
          urls: 'turn:turn.vli.to:3479?transport=udp',
          username: 'test',
          credential: 'test'
        }
      ]
    }
    this.videoContainer = document.getElementById("video-container");
    this.initializeLocalVideo()
  }

  refresh() {
    window.location.reload();
  }
  softRefresh() {
    this.stimulate('Room')
  }

  initializeLocalVideo () {
    if (document.readyState === "interactive") {
      navigator.mediaDevices
        .getUserMedia({
          audio: {
            autoGainControl: false,
            channelCount: 2,
            echoCancellation: false,
            latency: 0,
            noiseSuppression: false,
            sampleRate: 48000,
            sampleSize: 16,
            volume: 1.0
          },
          video: { width: 320, aspectRatio: 1, facingMode: 'user' }
        })
        .then((stream) => {
          this.localstream = stream;
          this.localVideo.srcObject = stream;
          this.localVideo.setAttribute('playsinline', '');
          this.localVideo.setAttribute('autoplay', '');
          this.localVideo.setAttribute('muted', '');
          this.localstream.getVideoTracks()[0].enabled = this.currentUser.video_on
          this.localstream.getAudioTracks()[0].enabled = this.currentUser.audio_on
          this.initializeConnection()
          window.AudioContext = window.AudioContext || window.webkitAudioContext;
          window.audioContext = new AudioContext();
          this.enableSoundMeter(stream);
        }).catch((error) => {
          console.error(error);
        });
      this.setVideoLayout()
    }
  }

  removeTracks() {
    console.log( this.pcPeers)
    Object.keys(this.pcPeers).forEach( (key) => {
      for (const track of this.localstream.getTracks()) {
        this.pcPeers[key].removeTrack(track, this.localstream);
      }
    })
  }

  initializeScreenSharing() {
    if (document.readyState === "complete") {
      console.log(this.pcPeers)
      navigator.mediaDevices
        .getDisplayMedia({ video: true })
        .then((stream) => {
          // this.removeTracks()
          // console.log(this.pcPeers)
          // this.pcPeers.forEach(function(peer) {
          //   console.log(peer)
          // })
          // for (const peer of this.pcPeers) {
          //   for (const track of this.localstream.getTracks()) {
          //     peer.removeTrack(track, this.localstream);
          //   }
          // }
          // this.localstream = stream;
          this.localVideo.srcObject = stream;
          this.localVideo.setAttribute('playsinline', '');
          this.localVideo.setAttribute('autoplay', '');
          this.localVideo.setAttribute('muted', '');
          stream.getVideoTracks()[0].enabled = true
          this.initializeAudio()
          this.localstream = stream
          this.broadcastReconnect()
        }).catch((error) => {
          document.getElementById('disable-sharing').click()
          console.error(error);
        });
      this.setVideoLayout()
    }
  }

  broadcastReconnect() {
    this.channel.send(
      { from: this.currentUser, type: 'reconnect' }
    )
  }

  initializeAudio() {
    navigator.mediaDevices
    .getUserMedia({
      audio: true, video: false
    })
    .then((stream) => {
      if (this.localstream) {
        this.localstream.addTrack(stream.getAudioTracks()[0]);
      } else {
        this.localstream = stream
      }
      this.localstream.getAudioTracks()[0].enabled = this.currentUser.audio_on
    }).catch((error) => {
      console.error(error);
    });
  }

  initializeVideo() {
    navigator.mediaDevices
    .getUserMedia({
      audio: true, video: true
    })
    .then((stream) => {
      this.localstream = stream;
      this.localVideo.srcObject = stream;
      this.localVideo.setAttribute('playsinline', '');
      this.localVideo.setAttribute('autoplay', '');
      this.localVideo.setAttribute('muted', '');
      this.localstream.getVideoTracks()[0].enabled = this.currentUser.video_on
      this.localstream.getAudioTracks()[0].enabled = this.currentUser.audio_on
      this.broadcastReconnect()
    }).catch((error) => {
      console.error(error);
    });
    this.setVideoLayout()
  }


  disableVideo() {
    if (this.localstream != null && this.localstream.getVideoTracks().length > 0) {
      this.localstream.getVideoTracks()[0].enabled = false
      this.currentUser.video_on = false
      this.channel.send({ from: this.currentUser, type: 'status' });
    }
  }

  enableVideo() {
    if (this.localstream != null && this.localstream.getVideoTracks().length > 0) {
      this.localstream.getVideoTracks()[0].enabled = true      
      this.currentUser.video_on = true
      this.channel.send({ from: this.currentUser, type: 'status' });
    }
  }

  mute() {
    if (this.localstream != null && this.localstream.getAudioTracks().length > 0) {
      this.localstream.getAudioTracks()[0].enabled = false
      this.currentUser.audio_on = false
      this.channel.send({ from: this.currentUser, type: 'status' });
    }
  }

  unmute() {
    if (this.localstream != null && this.localstream.getAudioTracks().length > 0) {
      this.localstream.getAudioTracks()[0].enabled = true
      this.currentUser.audio_on = true
      this.channel.send({ from: this.currentUser, type: 'status' });
    }
  }

  initializeConnection() {
    this.channel = consumer.subscriptions.create(
      { channel: "RoomChannel", token: this.token, uuid: this.currentUser.uuid },
      {
        connected: () => {
          this.joinRoom()
        },
        received: (data) => {
          switch(data.type) {
            case 'join_room':
              if (data.from.uuid === this.currentUser.uuid) return
              if (this.pcPeers[data.from.uuid]) {
                this.removeUser(data.from)
              }
              return this.createOffer(data)
            case 'offer':
              if (this.pcPeers[data.from.uuid]) return;
              return this.createAnswer(data)
            case 'answer':
              return this.acceptAnswer(data)
            case 'candidate':
              return this.addCandidate(data)
            case 'speak':
              if (data.from.uuid === this.currentUser.uuid) return
              return this.markSpeaker(data)
            case 'status':
              return this.setStatus(data)
            case 'pending':
              return this.showPendingUser(data)
            case 'room_blocked':
              this.refresh()
              break;
            case 'disconnected':
              return this.removeUser(data.from)
            case 'reconnect':
              if (data.from.uuid === this.currentUser.uuid) return
              this.removeUser(data.from)
              return this.joinRoom()
          }
        },
      }
    );
  };

  setStatus(data) {
    let container = $(`#video_container_${data.from.uuid}`)
    if (data.from.video_on) {
      container.find('.status .video').addClass('d-none')
    } else {
      container.find('.status .video').removeClass('d-none')
    }
    if (data.from.audio_on) {
      container.find('.status .audio').addClass('d-none')
    } else {
      container.find('.status .audio').removeClass('d-none')
    }
  }

  markSpeaker(data) {
    let container = document.getElementById(`video_container_${data.from.uuid}`)
    if (container === null) {
      return
    }
    if (this.speechTimeouts[data.from.uuid]) {
      clearTimeout(this.speechTimeouts[data.from.uuid])
    }
    container.classList.add('speaking');
    this.speechTimeouts[data.from.uuid] = setTimeout(
      function() {
        container.classList.remove('speaking')
      }, 500
    );
  }

  joinRoom() {
    let channel = this.channel
    let currentUser = this.currentUser
    channel.send(
      { from: currentUser, type: 'join_room' }
    )
  }

  createOffer(data) {
    var audio = new Audio(this.soundPath);
    audio.play()
    let peerConnection = new RTCPeerConnection(this.ice);;
    this.pcPeers[data.from.uuid] = peerConnection
    for (const track of this.localstream.getTracks()) {
      peerConnection.addTrack(track, this.localstream);
    }
    this.setPeerConnectionEvents(peerConnection, data.from)
    peerConnection.createOffer()
                  .then((offer) => {
                    peerConnection.setLocalDescription(offer).then(
                      () => {
                        this.channel.send(
                          {
                            from: this.currentUser,
                            to: data.from,
                            offer: JSON.stringify(peerConnection.localDescription),
                            type: 'offer'
                          }
                        );
                      }
                    )
                  })
  }

  createAnswer(data) {
    const peerConnection = new RTCPeerConnection(this.ice);
    this.pcPeers[data.from.uuid] = peerConnection
    for (const track of this.localstream.getTracks()) {
      peerConnection.addTrack(track, this.localstream);
    }

    const sdp = JSON.parse(data.offer);
    peerConnection.setRemoteDescription(new RTCSessionDescription(sdp))
    this.setPeerConnectionEvents(peerConnection, data.from);
    peerConnection.createAnswer()
                  .then((answer) => {
                    answer.sdp = answer.sdp.replace('useinbandfec=1', 'useinbandfec=1; stereo=1; maxaveragebitrate=510000');
                    peerConnection.setLocalDescription(answer).then(
                      () => {
                        this.channel.send(
                          {
                            from: this.currentUser,
                            to: data.from,
                            answer: answer,
                            type: 'answer'
                          }
                        );
                      }
                    )
                  })
  }

  reconnectStream(data) {
    if (this.pcPeers[data.from.uuid]) {
      this.removeUser(data.from)
    }
    return this.createOffer(data)
  }

  acceptAnswer(data) {
    // console.log("ANSWER SDP:")
    // console.log(data.answer.sdp)
    const remoteDesc = new RTCSessionDescription(data.answer);
    this.pcPeers[data.from.uuid].setRemoteDescription(remoteDesc);
  }

  setPeerConnectionEvents(peerConnection, user) {
    peerConnection.addEventListener('icecandidate', event => {
      if (event.candidate) {
        this.channel.send(
          { type: 'candidate', from: this.currentUser, to: user, candidate: event.candidate}
        );
      }
    });

    peerConnection.addEventListener('connectionstatechange', event => {
      if (peerConnection.connectionState === 'connected') {
        console.log('Connected to peer: ' +  user.uuid)
        return;
      }
      console.log(peerConnection.connectionState)
      if (peerConnection.connectionState === 'disconnected') {
        this.removeUser(user)
        console.log('Disconnected peer: ' + user.uuid)
        return;
      }
    });

    peerConnection.addEventListener('track', event => {
      let id = `video_container_${user.uuid}`
      let container = document.getElementById(id)
      if (container && container.classList.contains('pending')) {
        container.remove()
      } else if (container) {
        container.getElementsByTagName('video').srcObject = event.streams[0];
        return;
      }
      const element = document.createElement("div");
      element.id = id;
      element.className = 'member'
      const embed = document.createElement("div");
      embed.className = "embed-responsive embed-responsive-4by3";
      embed.appendChild(this.statusElement(user))
      element.appendChild(embed);
      const video = document.createElement("video");
      video.autoplay = "autoplay";
      video.srcObject = event.streams[0];
      embed.appendChild(video)
      embed.appendChild(this.createNameElement(user));
      this.videoContainer.appendChild(element);
      video.setAttribute('playsinline', '');
      video.setAttribute('autoplay', '');
      this.setVideoLayout();
    });
  }

  showPendingUser(data) {
    let user = data.from
    let id = `video_container_${user.uuid}`
    let container = document.getElementById(id)
    if (container) {
      container(remove)
    }
    let element = document.createElement("div")
    element.className = 'member pending'
    element.id = id
    this.videoContainer.appendChild(element);
    this.stimulate('Room#pending_room_member', user.uuid)
  }

  accepted(event) {
    this.channel.send(
      {
        from: this.currentUser,
        type: 'accepted',
        to: { uuid: event.target.dataset.uuid }
      }
    )
  }

  reject(event) {
    this.channel.send(
      {
        from: this.currentUser,
        type: 'rejected',
        to: { uuid: event.target.dataset.uuid }
      }
    )
  }

  afterPendingRoomMember() {
    this.setVideoLayout();
  }

  statusElement(user) {
    const status = document.createElement("div");
    const video_icon = document.createElement("i");
    const audio_icon = document.createElement("i");
    status.className = "status"
    if (user.video_on) {
      video_icon.className = "fas fa-video-slash video d-none"
    } else {
      video_icon.className = "fas fa-video-slash video"
    }
    if (user.audio_on) {
      audio_icon.className = "fas fa-microphone-slash audio d-none"
    } else {
      audio_icon.className = "fas fa-microphone-slash audio"
    }
    status.appendChild(audio_icon);
    status.appendChild(video_icon);
    return status;
  }

  addCandidate(data) {
    let peerConnection = this.pcPeers[data.from.uuid]
    if (!peerConnection) return;
    peerConnection.addIceCandidate(data.candidate)
                  .then(() => console.log("Ice candidate added"))
                  .catch((error) => {
                    console.log(error);
                  });
  }



  // createPC(user, isOffer) {
  //   let pc = new RTCPeerConnection(this.ice);
  //   this.pcPeers[user.uuid] = pc;

  //   console.log(this.pcPeers)

  //   for (const track of this.localstream.getTracks()) {
  //     pc.addTrack(track, this.localstream);
  //   }

  //   isOffer &&
  //     pc
  //       .createOffer()
  //       .then((offer) => {
  //         return pc.setLocalDescription(offer);
  //       })
  //       .then(() => {
  //         this.broadcastData({
  //           action_type: 'EXCHANGE',
  //           from: this.currentUser,
  //           to: user,
  //           sdp: JSON.stringify(pc.localDescription),
  //         });
  //       }).catch((error) => {
  //         console.error(error);
  //       });
  //   console.log(pc)

  //   // if (user.uuid == this.currentUser.uuid) return;

  //   pc.onicecandidate = (event) => {
  //     event.candidate &&
  //       this.broadcastData({
  //         action_type: 'EXCHANGE',
  //         from: this.currentUser,
  //         to: user,
  //         candidate: JSON.stringify(event.candidate),
  //       });
  //   };

  //   pc.ontrack = (event) => {
  //     console.log('ontrack')
  //     console.log(event)
  //     let id = `video_container_${user.uuid}`
  //     if (document.getElementById(id)) {
  //       document.getElementById(id).getElementsByTagName('video').srcObject = event.streams[0];
  //       return;
  //     }
  //     const element = document.createElement("div");
  //     element.id = id;
  //     element.className = 'member'
  //     const embed = document.createElement("div");
  //     embed.className = "embed-responsive embed-responsive-4by3";
  //     element.appendChild(embed);
  //     const video = document.createElement("video");
  //     video.autoplay = "autoplay";
  //     video.srcObject = event.streams[0];
  //     embed.appendChild(video)
  //     console.log(user)
  //     embed.appendChild(this.createNameElement(user));
  //     this.videoContainer.appendChild(element);
  //     this.setVideoLayout();
  //   };


  //   pc.oniceconnectionstatechange = () => {
  //     if (pc.iceConnectionState == "disconnected") {
  //       this.removeUser(user)
  //     }
  //   };

  //   return pc;
  // };

  createNameElement(user) {
    let element = document.createElement('div');
    element.className = 'member-name'
    element.innerHTML = user.name;
    return element;
  }

  // exchange(data){
  //   let pc;

  //   if (!this.pcPeers[data.from.uuid]) {
  //     pc = this.createPC(data.from, false);
  //   } else {
  //     pc = this.pcPeers[data.from.uuid];
  //   }

  //   if (data.candidate) {
  //     pc.addIceCandidate(new RTCIceCandidate(JSON.parse(data.candidate)))
  //       .then(() => console.log("Ice candidate added"))
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   }

  //   if (data.sdp) {
  //     const sdp = JSON.parse(data.sdp);
  //     pc.setRemoteDescription(new RTCSessionDescription(sdp))
  //       .then(() => {
  //         if (sdp.type === "offer") {
  //           pc.createAnswer()
  //             .then((answer) => {
  //               return pc.setLocalDescription(answer);
  //             })
  //             .then(() => {
  //               this.broadcastData({
  //                 action_type: 'EXCHANGE',
  //                 from: this.currentUser,
  //                 to: data.from,
  //                 sdp: JSON.stringify(pc.localDescription),
  //               });
  //             });
  //         }
  //       }).catch((error) => {
  //         console.error(error);
  //       });
  //   }
  // };

  removeUser(user) {
    console.log(user)
    let video = document.getElementById(`video_container_${user.uuid}`);
    if (video) {
      video.remove();
    }
    if (this.pcPeers[user.uuid]) {
      this.pcPeers[user.uuid].close()
      delete this.pcPeers[user.uuid];
    }
    this.setVideoLayout();
  };

  handleLeaveSession = () => {};

  // broadcastData(data) {
    // const csrfToken = document.querySelector("[name=csrf-token]").content;
    // const headers = new Headers({
    //   "content-type": "application/json",
    //   "X-CSRF-TOKEN": csrfToken,
    // });

    // fetch(this.token + "/signals", {
    //   method: "POST",
    //   body: JSON.stringify(data),
    //   headers,
    // });
    // this.channel.send(data)
  // };

  enableSoundMeter(stream) {
    window.stream = stream;
    console.log(window.audioContext)
    this.soundMeter();
    this.mic = window.audioContext.createMediaStreamSource(stream);
    this.mic.connect(this.script);
    this.script.connect(window.audioContext.destination);
  }

  soundMeter() {
    this.script = window.audioContext.createScriptProcessor(2048, 1, 1);
    let instant = 0.0;
    let channel = this.channel
    let currentUser = this.currentUser
    this.script.onaudioprocess = function(event) {
      const input = event.inputBuffer.getChannelData(0);
      let i;
      let sum = 0.0;
      let clipcount = 0;
      for (i = 0; i < input.length; ++i) {
        sum += input[i] * input[i];
        if (Math.abs(input[i]) > 0.99) {
          clipcount += 1;
        }
      }
      let instant = Math.sqrt(sum / input.length).toFixed(4);
      if (instant > 0.005) {
        channel.send(
          { from: currentUser, type: 'speak', level: instant }
        )
      }

      // console.log(instant.toFixed(2) > 0)
      // let slow = 0.95 * this.slow + 0.05 * that.instant;
      // console.log(slow.toFixed(2))
      // let clip = clipcount / input.length;
    };
  }

  setVideoLayout() {
    let members = document.getElementsByClassName('member')
    Array.from(members).forEach((el) => el.classList.remove('col-6', 'col-5', 'col-4', 'col-3'))
    switch (members.length) {
      case 1:
        Array.from(members).forEach((el) => el.classList.add('col-6'))
        break;
      case 2:
        Array.from(members).forEach((el) => el.classList.add('col-6'))
        break;
      case 3:
        Array.from(members).forEach((el) => el.classList.add('col-5'))
        break;
      case 4:
        Array.from(members).forEach((el) => el.classList.add('col-5'))
        break;
      case 5:
        Array.from(members).forEach((el) => el.classList.add('col-4'))
        break;
      case 6:
        Array.from(members).forEach((el) => el.classList.add('col-4'))
        break;
      case 7:
        Array.from(members).forEach((el) => el.classList.add('col-3'))
        break;
      case 8:
        Array.from(members).forEach((el) => el.classList.add('col-3'))
        break;
    }
  }
}
