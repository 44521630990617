import ApplicationController from './application_controller'
import noUiSlider from 'nouislider';
import I18n from 'i18n-js'
import * as Ladda from 'ladda';
import Rails from '@rails/ujs';

export default class extends ApplicationController {

  static targets = [ 'submitButton', 'sourceInput', 'form' ]

  connect () {
    super.connect()
    I18n.locale = 'en'
    this.minQuantity = parseInt(this.data.get('calls-min-quantity'))
    this.maxQuantity = parseInt(this.data.get('calls-max-quantity'))
    this.callPrice = parseFloat(this.data.get('call-price'))
    this.stripe = Stripe(this.data.get('stripe-key'));
    this.workspaceId = this.data.get('workspace-id')
    this.cardElement = null;
    this.initSlider()
    this.initStripe()
  }

  disconnect() {
  }

  initSlider() {
    var slider = document.getElementById('slider');
    const start = (this.minQuantity + this.maxQuantity) / 2;
    const callPrice = this.callPrice

    noUiSlider.create(
      slider,
      {
        start: start,
        step: 1,
        range: {
          min: this.minQuantity,
          max: this.maxQuantity
        }
      }
    )

    slider.noUiSlider.on('update', function(values) {
      const limit = Math.round(values[0])
      $('#payments_subscription_limit').val(limit)
      $('#calls-per-month').html(
        I18n.t(
          'javascript.controllers.subscriptions.calls_per_month',
          { count: limit }
        )
      )
      const price = new Intl.NumberFormat(
        'en', { style: 'currency', currency: 'USD' }
      ).format(limit * callPrice)
      $('#price-per-month').html(
        I18n.t(
          'javascript.controllers.subscriptions.subscribe', { price: price }
        )
      )
    })
  }

  initStripe() {
    this.elements = this.stripe.elements();
    this.cardElement = this.elements.create('card');
    this.cardElement.mount('#card')
    // var submitButton = this.submitButtonTarget
    this.cardElement.on('change', (event) => {
      if (event.complete) {
        this.submitButtonTarget.classList.remove('disabled')
      } else if (event.error) {
        this.submitButtonTarget.classList.add('disabled')
        this.showError(event.error.message)
      }
    });
  }


  createToken(event) {
    Ladda.create(this.submitButtonTarget).start()
    this.stripe.createSource(this.cardElement, { type: 'card' }).then(
      (response) => this.handleTokenResponse(response)
    )
  }

  handleTokenResponse(response) {
    if (response.error) {
      this.showError(response.error.message)
      Ladda.create(this.submitButtonTarget).stop()
    } else {
      console.log(this.element)
      this.sourceInputTarget.value = response.source.id
      Rails.fire(this.formTarget, 'submit');
    }
  }

  showError(error) {
    this.stimulate('NewSubscription#form_error', error)
  }

  formError(event) {
    Ladda.create(this.submitButtonTarget).stop()
    this.showError(event.detail[0].error)
  }

  formSuccess(event) {
    if (event.detail[0].status == 'incomplete') {
      this.stripe.confirmCardPayment(
        event.detail[0].payment_intent.client_secret,
        {
          payment_method: { card: this.cardElement }
        }
      ).then(
        (response)=> { this.handleConfirmPaymentResponse(response) }
      )
    } else {
      this.hideModal('#subscription-modal')
      this.stimulate('Subscription#refresh')
    }
  }

  handleConfirmPaymentResponse(response) {
    Ladda.create(this.submitButtonTarget).stop()
    if (response.error) {
      this.stimulate('Subscription#refresh')
      this.showError(response.error.message)
    } else {
      Rails.ajax({
        url: `/workspaces/${this.workspaceId}/billing/confirm`,
        type: 'put',
        success: () => {
          this.stimulate('Subscription#refresh')
        }
      })
      this.hideModal('#subscription-modal')
    }
  }
}
