import ApplicationController from './application_controller'
import consumer from "../channels/consumer";
import Rails from '@rails/ujs';
import { event } from 'jquery';

export default class extends ApplicationController {
  static targets = [
    'chat', 'chatForm', 'fileInput', 'messagesContainer', 'contentInput', 'preview', 'messageCount'
  ]

  connect () {
    this.messageCount = 0;
    super.connect()
    this.currentRoomMember = JSON.parse(this.data.get('member'));
    this.token = this.data.get('id')
    this.subscribe()
    window.addEventListener('paste', e => {
      this.fileInputTarget.files = e.clipboardData.files;
      let file = this.fileInputTarget.files[0]
      this.stimulate('Chat#preview_file', {
        name: file.name, size: file.size, type: file.type
      })
    });
  }

  subscribe() {
    this.channel = consumer.subscriptions.create(
      {
        channel: "ChatChannel",
        token: this.token,
        uuid: this.currentRoomMember.uuid
      },
      {
        connected: () => {
          this.stimulate('Chat#show_messages', this.currentRoomMember.uuid)
        },
        received: (data) => {
          this.stimulate(
            'Chat#new_message', this.currentRoomMember.uuid, data
          )
          if (!this.chatTarget.classList.contains('open')) {
            this.messageCount += 1
            this.messageCountTarget.innerHTML = this.messageCount
            this.messageCountTarget.style.display = 'block';
          }
        }
      }
    )
  }

  afterShowMessages() {
    this.messagesContainerTarget
        .scrollTop = this.messagesContainerTarget.scrollHeight;
    this.messageCount += 1
    this.messageCountTarget.innerHTML = this.messageCount
  }

  afterNewMessage() {
    this.messagesContainerTarget
        .scrollTop = this.messagesContainerTarget.scrollHeight;
  }

  openChat() {
    this.messageCountTarget.style.display = 'none';
    this.messageCount = 0
    this.messageCountTarget.innerHTML = ''
    if (this.chatTarget.classList.contains('open')) {
      this.chatTarget.classList.remove('open')
    } else {
      this.chatTarget.classList.add('open')
    }
  }

  clearInputs() {
    this.contentInputTarget.value = '';
    this.fileInputTarget.value = '';
    this.previewTarget.innerHTML = '';
  }

  chooseFile() {
    this.fileInputTarget.click()
  }

  previewFile(event) {
    let file = event.target.files[0]
    this.stimulate('Chat#preview_file', {
      name: file.name, size: file.size, type: file.type
    })
  }

  afterPreviewFile() {
    this.messagesContainerTarget
        .scrollTop = this.messagesContainerTarget.scrollHeight;
  }
}